import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import {compressData} from '../../helpers/OptimizationUtils';
import eventEmitter from '../../helpers/EventEmitter';
import {EMITTER_TYPES} from '../../helpers/Constant';
import {getInitialStateFromFullState} from '../../helpers/fabricOverrides/object/State';

export class CollaborationManager {
    constructor() {
        this.socket = null;
        this.emitterUserId = null;
        this.canvases = new Map();
        this.pendingChanges = new Map(); // Map of pageId to pending changes
        this.throttleDelay = 300; // ms
        this.throttleTimeouts = new Map(); // Map of pageId to throttle timeout
        this.initialStates = new Map(); // Map of processId to initial states
        this.processResponses = new Map(); // Map of processId to responses
        this.promisesForProcess = new Map(); // Map of processId to promises
        this.activeOperations = new Map(); // <processId, operation>
        this.abortionListeners = new Map();
        this.multipleScenarios = new Map();
        this.lastSendTime = new Map();
    }
    
    setSocket(socket) {
        this.socket = socket;
    }

    setEmitterUserId(userId) {
        this.emitterUserId = userId;
    }

    addCanvas(pageId, canvas) {
        this.canvases.set(pageId, canvas);
    }

    startEditing(shapes, pageId, ...editingMethods) {
        const editingMap = new Map();
        shapes.forEach(shape => {
            editingMap.set(shape, new Set(editingMethods))
        })
        return this.startIsolatedEditing(shapes, pageId, editingMap)
    }
    
    startIsolatedEditing(shapes, pageId, editingMap, lockingMap = new Map()) {
        const processId = uuidv4();
        const canvas = this.canvases.get(pageId);
        
        if (!canvas) {
            return {
                processId,
                aborted: true,
            };
        }

        // if any shape is locked by other user, the process should not be continued
        if (shapes.some(shape => shape.collabLocked)) {
            canvas.lockManager.showToast();
            return {
                processId,
                aborted: true
            }
        }

        const initialStates = shapes.map(shape => {
            const editingMethods = editingMap.get(shape)
            return {
                shape,
                fullState: shape.captureFullState({narrowDefinitions: true}),
                partialState: shape.captureState(...editingMethods),
                isLocked: lockingMap.has(shape) ? lockingMap.get(shape) : true,
                editingMethods
            };
        });
        this.initialStates.set(processId, initialStates);

        let resolveFn;

        const promise = new Promise((resolve) => {
            resolveFn = resolve;
        });
        this.promisesForProcess.set(processId, promise);

        // not all shapes should be locked
        // it can be set via lockingMap
        const lockingShapes = shapes.filter(shape => {
            if (lockingMap.has(shape)) {
                return lockingMap.get(shape)
            }
            return true
        })

        const {shapeUuids} = canvas.lockManager.startEditing(
            lockingShapes,
            pageId,
            (data) => {
                if (!data.failedShapes || !data.failedShapes.length) {
                    this.processResponses.set(processId, {aborted: false});
                    resolveFn(true);
                    return
                }
                this.abortEditing(processId, pageId);
                this.processResponses.set(processId, {aborted: true});
                resolveFn(false);
            },
            {
                processId,
                avoidShowingToast: true
            }
        );

        if (shapeUuids?.length !== lockingShapes.length) {
            this.abortEditing(processId, pageId, { shouldSendUnlockReq: false })
            return {
                processId,
            }
        }

        return {processId}
    }

    async commitEditing(processId, pageId, options = {}) {
        const processResponse = this.processResponses.get(processId);
        if (!processResponse) {
            const success = await this.promisesForProcess.get(processId);
            if (!success) {
                return
            }
        } else if (processResponse.aborted) {
            return
        }

        const states = this.initialStates.get(processId);
        const sendingData = []
        const prevData = []
        for (const singleState of states) {
            sendingData.push(singleState.shape.captureState(...Array.from(singleState.editingMethods)));
            prevData.push(singleState.partialState)
        }
        
        const unlockingShapes = states.filter(state => state.isLocked).map(state => state.shape)

        const canvas = this.canvases.get(pageId);
        if (!canvas) {
            return
        }
        
        const socketData = {
            data: sendingData,
            committed: true,
            processId,
            pageId,
            emitterUserId: this.emitterUserId
        }
        
        const compressed = compressData(socketData)
        this.socket.emit('shapePartialUpdate', compressed, (data) => {
            this.handleShapePartialUpdateResponse(data, pageId, states.map(state => state.shape))
        })
        canvas.lockManager.stopEditing(unlockingShapes, pageId, processId);
        
        const historyData = {
            ...socketData,
            prevData,
        } 
        if(options.addToHistory !== false){
            canvas.fire('collaboration-history-update', { modified: historyData, processId })
        }
        this.clearInstantEditing(processId, false);
        typeof options.commitSuccessCallback === 'function' && options.commitSuccessCallback();
    }

    abortEditing(processId, pageId, options = {}) {
        const canvas = this.canvases.get(pageId);
        const states = this.initialStates.get(processId);
        const shapes = states.map(state => state.shape);
        
        if (options.shouldSendUnlockReq) {
            canvas.lockManager.stopEditing(shapes, pageId, processId);
        }

        for (const state of states) {
            state.shape.onShapeChanged()
            state.shape.updateWithState(state.partialState);
        }
        canvas.renderAll();
        this.initialStates.delete(processId);
        eventEmitter.fire(EMITTER_TYPES.TOOLBAR_UPDATE)
        canvas.lockManager.showToast();

        const abortionListener = this.abortionListeners.get(processId)
        try {
            abortionListener && abortionListener()
        } catch (err) {
            console.error('error while calling abortion listener: ', err)
        }
        this.abortionListeners.delete(processId);
    }
    
    clearInstantEditing(processId, deleteAbortionListeners = true) {
        this.initialStates.delete(processId);
        this.promisesForProcess.delete(processId);
        this.processResponses.delete(processId)
        
        if (deleteAbortionListeners) {
            this.abortionListeners.delete(processId)
        }
    }

    startContinuousEditing(shapes, pageId, ...editingMethods) {
        const editingMap = new Map();
        shapes.forEach(shape => {
            editingMap.set(shape, new Set(editingMethods))
        })
        return this.startIsolatedContinuousEditing(shapes, pageId, editingMap)
    }
    
    startIsolatedContinuousEditing(shapes, pageId, editingMethodsMap) {
        const processId = uuidv4();
        const canvas = this.canvases.get(pageId);
        if (!canvas) {
            return {
                processId,
                aborted: true
            };
        }
        
        // if any shape is locked by other user, the process should not be continued
        if (shapes.some(shape => shape.collabLocked)) {
            canvas.lockManager.showToast();
            return {
                processId,
                aborted: true
            }
        }

        const initialStates = shapes.map(shape => {
            const editingMethods = editingMethodsMap.get(shape);
            return {
                shape,
                fullState: shape.captureFullState({narrowDefinitions: true}),
                partialState: shape.captureState(...editingMethods),
                editingMethods
            };
        });
        this.initialStates.set(processId, initialStates);

        this.activeOperations.set(processId, {
            shapes,
            pageId,
            editingMethodsMap,
            lastUpdate: Date.now(),
            committed: false,
            canSendChanges: false,
            aborted: false
        })

        let resolveFn;

        const promise = new Promise((resolve) => {
            resolveFn = resolve;
        });
        this.promisesForProcess.set(processId, promise);

        const {shapeUuids} = canvas.lockManager.startEditing(
            shapes,
            pageId,
            (data) => {
                const operation = this.activeOperations.get(processId);
                if (!data.failedShapes || !data.failedShapes.length) {
                    this.processResponses.set(processId, {aborted: false});
                    if (operation) {
                        operation.canSendChanges = true;
                    }
                    resolveFn(true);
                    return
                }
                if (operation) {
                    operation.aborted = true;
                }
                this.abortContinuousEditing(processId, pageId);
                this.processResponses.set(processId, {aborted: true});
                resolveFn(false);
            },
            {
                processId,
                avoidShowingToast: true
            }
        );

        if (shapeUuids.length !== shapes.length) {
            this.abortContinuousEditing(processId, pageId,  { shouldSendUnlockReq: true })
            return {
                processId,
                aborted: true
            }
        }

        return {
            processId,
        }
    }
    
    updateContinuousEditing(processId, avoidThrottle = false, triedTimes = 0) {
        const operation = this.activeOperations.get(processId);
        if (!operation) {
            console.error(`No active operation found with id: ${processId}`);
            return;
        }
        
        // we need to wait objects to be locked.
        // till that time, we can try to send updates. That's why we have tiredTimes variable.
        clearTimeout(this.autoUpdateWithSendChanges)
        if (!operation.canSendChanges) {
            if (triedTimes < 3) {
                this.autoUpdateWithSendChanges = setTimeout(() => {
                    this.updateContinuousEditing(processId, avoidThrottle, triedTimes + 1)
                }, this.throttleDelay)
            }
            return
        }

        clearTimeout(this.automaticUpdateTimeout)
        const { shapes, pageId, editingMethodsMap, lastUpdate } = operation;
        const currentTime = Date.now();

        // if the batch couldn't be sent we can try one more time to sync
        if (!avoidThrottle && currentTime - lastUpdate < this.throttleDelay) {
            this.automaticUpdateTimeout = setTimeout(() => {
                this.updateContinuousEditing(processId)
            }, currentTime - lastUpdate)
            return;
        }

        const sendingData = shapes.map(shape => 
            shape.captureState(...Array.from(editingMethodsMap.get(shape)))
        );

        // if editing is done and there are some shapes that needs to be updated in the end
        // send them as well
        if (operation.committed && operation.laterShapes) {
            sendingData.push(...operation.laterShapes.map(shape => 
                shape.captureState(...Array.from(editingMethodsMap.get(shape))))
            )
        }
        this.queueChanges(pageId, processId, {data: sendingData, committed: operation.committed}, shapes);
        operation.lastUpdate = currentTime;
    }
    
    queueChanges(pageId, processId, changes, shapes) {
        let pagePendingChanges = this.pendingChanges.get(pageId);
        if (!pagePendingChanges) {
            pagePendingChanges = new Map();
            this.pendingChanges.set(pageId, pagePendingChanges);
        }
        pagePendingChanges.set(processId, { changes, shapes });
        this.throttledSendChanges(pageId);
    }
    
    throttledSendChanges(pageId) {

        const currentTime = Date.now();
        const lastSend = this.lastSendTime.get(pageId) || 0;
        
        if (currentTime - lastSend >= this.throttleDelay) {
            // If enough time has passed, send changes immediately
            this.sendChanges(pageId);
            this.lastSendTime.set(pageId, currentTime);
        } else if (!this.throttleTimeouts.has(pageId)) {
            const timeout = setTimeout(() => {
                this.sendChanges(pageId);
                this.throttleTimeouts.delete(pageId);
                this.lastSendTime.set(pageId, Date.now());
            }, this.throttleDelay - (currentTime - lastSend));

            this.throttleTimeouts.set(pageId, timeout);
        }
    }
    
    sendChanges(pageId) {
        const pagePendingChanges = this.pendingChanges.get(pageId);
        if (!pagePendingChanges) return;

        const allChanges = Array.from(pagePendingChanges.entries()).map(([processId, value]) => ({
            processId,
            value
        }));
        
        for (const changeByPage of allChanges) {
            const socketData = {
                processId: changeByPage.processId,
                ...changeByPage.value.changes,
                pageId,
                emitterUserId: this.emitterUserId
            }
            const compressed = compressData(socketData)

            this.socket.emit('shapePartialUpdate', compressed, (data) => {
                if (socketData.committed) {
                    this.handleShapePartialUpdateResponse(data, pageId, changeByPage.value.shapes)
                }
            })
            
            if (socketData.committed) {
                // add data to history
                const initialStates = this.initialStates.get(changeByPage.processId)
                const data = initialStates.map(state => ({
                    initialState: state.partialState,
                    initialFullState: state.fullState,
                    currentState: socketData.data.find(data => data.uuid === state.partialState.uuid)
                })).map(value => getInitialStateFromFullState(value))

                const canvas = this.canvases.get(pageId)
                const historyData = {
                    ...socketData,
                    prevData: data
                }
                canvas.fire('collaboration-history-update', { modified: historyData, processId: changeByPage.processId })
                
                setTimeout(() => {
                    this.clearContinuousEditingStates(changeByPage.processId, pageId);
                }, this.throttleDelay)
            }
        }

        pagePendingChanges.clear();
    }
    
    // mark continuous editing as completed
    async completeContinuousEditing(processId) {
        const operation = this.activeOperations.get(processId);
        if (!operation) {
            console.error(`No active operation found with id: ${processId}`);
            return;
        }

        const { pageId } = operation;
        const canvas = this.canvases.get(pageId);
        const states = this.initialStates.get(processId);
        const shapes = states.filter(state => !state.isAddedLater).map(state => state.shape);
        canvas.lockManager.stopEditing(shapes, pageId, processId);
        
        const processResponse = this.processResponses.get(processId);
        if (!processResponse) {
            const success = await this.promisesForProcess.get(processId);
            if (!success) {
                return;
            }
        } else if (processResponse.aborted) {
            return;
        }
        
        operation.committed = true
        
        // just in case send the latest data
        this.updateContinuousEditing(processId, true)
    }

    abortContinuousEditing(processId, pageId, options = {}) {
        const canvas = this.canvases.get(pageId);
        if (!canvas) {
            return
        }
        const states = this.initialStates.get(processId);
        if (!states) {
            return
        }
        
        const shapes = states.map(state => state.shape);
        
        if (options.shouldSendUnlockReq) {
            canvas.lockManager.stopEditing(shapes, pageId, processId);
        }

        for (const state of states) {
            state.shape.onShapeChanged()
            state.shape.updateWithState(state.fullState);
        }
        canvas.renderAll();
        this.initialStates.delete(processId);
        eventEmitter.fire(EMITTER_TYPES.TOOLBAR_UPDATE)
        const abortionListener = this.abortionListeners.get(processId)
        try {
            abortionListener && abortionListener()
        } catch (err) {
            console.error('error while calling abortion listener: ', err)
        }
        canvas.lockManager.showToast();
        this.abortionListeners.delete(processId);
        this.clearContinuousEditingStates(processId, pageId);
    }
    
    clearContinuousEditingStates(processId, pageId) {
        this.initialStates.delete(processId)
        this.activeOperations.delete(processId)
        this.pendingChanges.delete(pageId)
        this.throttleTimeouts.delete(processId)
        this.processResponses.delete(processId)
        this.promisesForProcess.delete(processId)
    }

    /**
     * Adds given editing method to initial states of the process.
     * This is useful for continuous editing where we want to send props
     * with users actions.
     * @param {string} processId The processId of continuous process.
     * @param {string[]} methods To add new methods.
     * @returns {boolean} To check if the adding new methods is successful.
     */
    addEditingMethod(processId, ...methods) {
        const operation = this.activeOperations.get(processId)
        if (!operation) {
            return false
        }
        
        const editingMethodsMap = operation.editingMethodsMap;
        for (const shape of editingMethodsMap.keys()) {
            const editingSet = editingMethodsMap.get(shape)
            methods.forEach(method => {
                editingSet.add(method)
            })
        }
        return true
    }
    
    addIsolatedEditingMethod(processId, editingMethodMap) {
        const operation = this.activeOperations.get(processId)
        if (!operation) {
            return false;
        }
        
        const operationEditingMap = operation.editingMethodsMap;
        for (const key of editingMethodMap.keys()) {
            const newEditingMethods = editingMethodMap.get(key)
            for (const newMethod of newEditingMethods) {
                operationEditingMap.get(key).add(newMethod)
            }
        }
    }
    
    addLaterShapes(shapes, processId, ...editingMethods) {
        const editingMap = new Map();
        shapes.forEach(shape => {
            editingMap.set(shape, new Set(editingMethods))
        }) 
        
        return this.addIsolatedLaterShapes(processId, editingMap)
    }

    /**
     * Adds later shapes to ongoing continuous editing.
     * @param {string} processId Continuous editing process id.
     * @param {Map<fabric.Object, Set>} editingMap New shapes to be checked after the editing is done.
     */
    addIsolatedLaterShapes(processId, editingMap) {
        const operation = this.activeOperations.get(processId)
        if (!processId) {
            return false;
        }
        
        if (!operation.laterShapes) {
            operation.laterShapes = []
        }
        
        const newLaterShapes = Array.from(editingMap.keys())  // storing shapes as keys
        for (const shape of newLaterShapes) {
            operation.laterShapes.push(shape)
        }
        
        // add editing method as well
        const editingMapOfOperation = operation.editingMethodsMap;
        for (const key of newLaterShapes) {
            const inOperationSet = editingMapOfOperation.get(key)
            if (!inOperationSet) {
                editingMapOfOperation.set(key, new Set())
            }
            
            for (const method of editingMap.get(key).values()) {
                editingMapOfOperation.get(key).add(method)
            }
        }
        
        const originalInitialStates = this.initialStates.get(processId)
        const laterShapesInitialStates = newLaterShapes.map(shape => {
            const editingMethods = editingMapOfOperation.get(shape);
            return {
                shape,
                fullState: shape.captureFullState({narrowDefinitions: true}),
                partialState: shape.captureState(...editingMethods),
                isAddedLater: true,
                editingMethods
            };
        });

        originalInitialStates.push(...laterShapesInitialStates)
    }

    /**
     * Sets abortion callback listener in case the requester wants to do something with the abortion case.
     * @param {string} processId Id of the continuous editing process.
     * @param {Function} callback Abortion callback func.
     */
    setAbortionListener(processId, callback) {
        const operation = this.activeOperations.get(processId)
        
        // if the process is already aborted before this abortion listener is set
        if (operation && operation.aborted) {
            callback()
            return
        }
        this.abortionListeners.set(processId, callback)
    }

    /**
     * Starts an editing scenario. This is useful when multiple editing action might happen and when we don't need to
     * lock the modified objects.
     * @param {number} pageId Canvas page id.
     * @param {object} options Options for this editing scenario.
     * @param {boolean|null} options.addToHistory If false, the scenario will not be added to history..
     * @returns {string} Process id of editing scenario.
     */
    startEditingScenario(pageId, options = {}) {
        const processId = uuidv4();
        this.multipleScenarios.set(
            processId, 
            {
                pageId,
                createdShapes: [],
                restoredShapes: [],
                modifiedShapes: [],
                deletedShapes: [],
                initialStates: [],
                editingMethodsMap: new Map(), // for modifications
                options
            }
        )
        
        return processId;
    }
    
    createdInScenario(shape, processId) {
        const scenario = this.multipleScenarios.get(processId)
        if (!scenario) {
            return false
        }
        
        scenario.createdShapes.push(shape)
    }
    
    restoredInScenario(shape, processId) {
        const scenario = this.multipleScenarios.get(processId)
        if (!scenario) {
            return false
        }
        
        scenario.restoredShapes.push(shape)
    }
    
    modifiedInScenario(shape, processId, ...editingMethods) {
        const scenario = this.multipleScenarios.get(processId)
        if (!scenario) {
            return false
        }
        
        scenario.modifiedShapes.push(shape);
        scenario.initialStates.push({
            shape,
            fullState: shape.captureFullState({narrowDefinitions: true}),
            partialState: shape.captureState(...editingMethods),
            type: 'modify'
        })
        
        if (!scenario.editingMethodsMap.get(shape)) {
            scenario.editingMethodsMap.set(shape, new Set())
        }
        
        editingMethods.forEach(method => {
            scenario.editingMethodsMap.get(shape).add(method)
        })
    }
    
    deletedInScenario(shape, processId) {
        const scenario = this.multipleScenarios.get(processId)
        if (!scenario) {
            return false
        }

        scenario.deletedShapes.push(shape);
        scenario.initialStates.push({
            shape,
            fullState: shape.captureFullState({narrowDefinitions: true}),
            type: 'delete'
        })
    }
    
    commitScenario(processId, options={}) {
        const scenario = this.multipleScenarios.get(processId)
        if (!scenario) {
            return false
        }
        
        const prevData = []
        const modifiedShapesData = []
        scenario.modifiedShapes.forEach(shape =>  {
            modifiedShapesData.push(shape.captureState(...scenario.editingMethodsMap.get(shape)))
            prevData.push(scenario.initialStates.find(state => state.shape.uuid === shape.uuid)?.partialState)
        });
        
        
        const deletedShapesData = scenario.deletedShapes.map(shape => ({
            uuid: shape.uuid,
            isDeleted: true
        }))
        
        const restoredShapeData = scenario.restoredShapes.map(shape => shape.captureFullState({ narrowDefinitions: true }))
        
        // deleted and modified shape data can be sent together via shapePartialUpdate.
        const shapePartialUpdateData = [...modifiedShapesData, ...deletedShapesData, ...restoredShapeData]
        
        if (shapePartialUpdateData.length) {
            const socketData = {
                processId,
                data: shapePartialUpdateData,
                pageId: scenario.pageId,
                committed: true,
                emitterUserId: this.emitterUserId
            }

            this.socket.emit('shapePartialUpdate', compressData(socketData), (data) => {
                this.handleShapePartialUpdateResponse(data, scenario.pageId, [...scenario.modifiedShapes, ...scenario.deletedShapes])
            })
        }

        
        if (scenario.options?.addToHistory === false) {
            return
        }
        
        const canvas = this.canvases.get(scenario.pageId)
        canvas.fire('collaboration-history-update', {
            processId,
            modified: {
                data: modifiedShapesData,
                committed: true,
                prevData,
            },
            created: {
                data: [...scenario.createdShapes, ...restoredShapeData]
            },
            deleted: {
                data: scenario.deletedShapes
            }
        })
        
        this.removeScenario(processId);
        typeof options.commitSuccessCallback === 'function' && options.commitSuccessCallback();
    }
    
    removeScenario(processId) {
        this.multipleScenarios.delete(processId);
    }

    /**
     * Sends given partial data directly. This is useful when we have the partial data and 
     * no need to capture the state with the editing methods.
     * @param {Array} data Array of partial data.
     * @param {string} pageId Current page id.
     */
    sendDirectPartialUpdate(data, pageId) {
        if (!Array.isArray(data)) {
            console.error('data must be an array')
            return
        }
        
        if (!data?.length) {
            console.error('empty data')
            return
        }
        
        if (!pageId) {
            console.error('page id is required')
            return
        }

        const socketData = {
            data,
            pageId: pageId,
            committed: true,
            emitterUserId: this.emitterUserId
        } 
        
        const canvas = this.canvases.get(pageId)
        const canvasObjects = canvas.getObjects()
        const modifiedShapesInCanvas = data.map(state => canvasObjects.find(obj => obj.uuid === state.uuid))
        
        this.socket.emit('shapePartialUpdate', compressData(socketData), (data) => {
            if (modifiedShapesInCanvas?.length) {
                this.handleShapePartialUpdateResponse(data, pageId, modifiedShapesInCanvas)
            }
        })
    }

    handleShapePartialUpdateResponse(response, pageId, sentShapes) {
        if (!response || response?.status !== 'ok') {
            return
        }
        const canvas = this.canvases.get(pageId)
        if (!canvas) {
            return
        }

        const updatedStacks = sentShapes.map(shape => {
            return { zIndex: shape.zIndex, uuid: shape.uuid }
        })
        
        canvas.fire('stack-updated', {updatedStacks});
    }
}