import { fabric } from 'fabric';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
    arrangeTextInsideShape,
    cloneFabricObjectHelper,
    createFabricInstance,
    customToObject,
    isObjectInsideOfObject,
    isTargetIncludeText,
} from '../helpers/FabricMethods';
import {attachToFrame, getFrameAttachedShapes, handleFrameStackOrder} from '../helpers/frame/FrameMethods';
import onFrameDrawn from '../helpers/frame/OnFrameDrawn';
import {
    setSingleObjectStack,
    setObjectsStackWithZIndex
} from '../helpers/StackOrder';
import { useSelector } from 'react-redux';
import { LINE_POLYGON_POINTS, LINE_POLYGON_PREFIXES, SOCKET_STATUS_MODS } from '../helpers/Constant';
import {
    attachLinesInUuidList, attachPolygonsWithCanvasMap,
} from '../helpers/lines/LineMethods';
import attachedShapeMoveHandler from '../helpers/comments/AttachedShapeMoveHandler';
import { isUserHasAccessToFeature } from '../helpers/CommonFunctions';
import {removeAllExistedLasso} from './UseLasso';
import { onTableDrawn } from '../helpers/table/TableMethods';
import { getOwnerOfSticky } from './UseSticky';

const useHistory = (canvas, userAccessRef, pageId, userId) => {
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [enabledHistory, setEnabledHistory] = useState({
        undo: false,
        redo: false
    });
    const [history, setHistory] = useState([]);
    const socketConnectionStatus = useSelector(state => state.socket.status);
    const isProcessing = useRef(false);
    const redoStackRef = useRef(redoStack);

    const _fireCanvasEventToModifyAllTheCommentLocations = (
        canvas,
        movedComments
    ) => {
        const emitData = {
            objects: movedComments,
            action: 'modified-the-comment',
        };
        canvas.fire('history-emit-data', emitData);
    };

    const moveTheCommentAndFireCanvasEvent = useCallback((canvas, objReflection) => {
        let movedComments = attachedShapeMoveHandler(objReflection);
        _fireCanvasEventToModifyAllTheCommentLocations(canvas, movedComments);
    }, []);

    const _addToHistory = useCallback((historyData) => {
        setHistory(currentState => {
            if (!currentState || !currentState.length) currentState = [];
            return [...currentState, historyData];
        });
    }, []);

    const addtoUndoStack = useCallback((undoData, shouldAddHistory = true) => {
        setUndoStack(data => {
            if (!data || !data.length) data = [];
            return [...data, undoData];
        });

        if (shouldAddHistory) {
            _addToHistory(JSON.parse(JSON.stringify(undoData)));
        }

        // when we add to undo stack, we should clear the redo stack
        setRedoStack([]);
    }, [_addToHistory]);

    const updateLayerIdToPrevShapeStates = ({shapeIds, newLayerId})=>{
        undoStack.filter(s=>s.action === 'stateChange').forEach(state=>{
            state.affectedObjects.forEach(affectedObj=>{
                if(shapeIds.has(affectedObj.uuid) && ['created', 'deleted'].includes(affectedObj.action)){
                    affectedObj.properties.layerId = newLayerId;
                }
            });
        });
        redoStack.filter(s=>s.action === 'stateChange').forEach(state=>{
            state.affectedObjects.forEach(affectedObj=>{
                if(shapeIds.has(affectedObj.uuid) && ['created', 'deleted'].includes(affectedObj.action)){
                    affectedObj.properties.layerId = newLayerId;
                }
            });
        });        
    }

    useEffect(() => {
        if (canvas) {
            const dataToHistoryObject = (data, action = 'modified') => {
                return {action, properties: data, uuid: data.uuid};
            }
            // generates attached object data for the given frame
            const generateAttachedObjectDataForFrame = (frame, action, useCalculatedPosition) => {
                const frameObjects = getFrameAttachedShapes(frame);
                const frameObjectsData = [];
                for (const frameObj of frameObjects) {
                    const objData = customToObject(frameObj, { useCalculatedPosition });
                    frameObjectsData.push(dataToHistoryObject(objData, action));

                    // handle frame object adding in nested level
                    if (frameObj.type === 'frame') {
                        const nestedFrameObjects = getFrameAttachedShapes(frameObj);
                        for (const nestedFrameObj of nestedFrameObjects) {
                            const objData = customToObject(nestedFrameObj, { useCalculatedPosition });
                            frameObjectsData.push(dataToHistoryObject(objData, action));
                        }
                    }
                }
                return frameObjectsData;
            }
            // adds polygons of the line to the given list
            const addLinePolygonsToList = (line, list, useCalculatedPosition) => {
                try {
                    for (const side of LINE_POLYGON_POINTS) {
                        const polygonSide = line[LINE_POLYGON_PREFIXES[side].polygon];
                        if (!polygonSide) continue;
                        const polygonSideObj = canvas.getObjects().find(e => e.uuid === polygonSide?.uuid);

                        if (polygonSideObj && !list.find(o => o.uuid === polygonSideObj.uuid)) {
                            const polygonSideData = customToObject(polygonSideObj, { useCalculatedPosition });
                            list.push(dataToHistoryObject(polygonSideData, 'modified'));
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
                return list;
            }


            const addtoUndoStackListener = (e) => {
                if (!isUserHasAccessToFeature('history_create_or_delete', userAccessRef.current)) return;

                const affectedObjects = [];
                for (const obj of e.objects) {
                    // if this called from restore, then we don't need to convert to custom object.
                    // because the object is already a custom object
                    if (e.action === 'restore') affectedObjects.push(dataToHistoryObject(obj.properties, 'created'));
                    else {
                        const objectData = customToObject(obj, { useCalculatedPosition: e.useCalculatedPosition ?? false })
                        let thisAttachments = [];
                        if (obj.type === 'frame') {
                            let actionForAttachments = 'created';
                            thisAttachments = generateAttachedObjectDataForFrame(obj, actionForAttachments, e.useCalculatedPosition ?? false);
                        }
                        affectedObjects.push(dataToHistoryObject(objectData, 'created'), ...thisAttachments);
                        if (obj?.type === 'curvedLine') {
                            addLinePolygonsToList(obj, affectedObjects, e.useCalculatedPosition ?? false);
                        }
                    }
                }
                addtoUndoStack({ action: 'stateChange', affectedObjects, processId: e?.transform?.processId || e.processId, aborted: e?.transform?.aborted || e.aborted  });
            }
            
            
            const stackOrderToUndoStackListener = (orderData) => {
                addtoUndoStack({ action: 'stackOrder', newStackOrder: orderData.stackOrder, oldStackOrder: orderData.oldStackOrder });
            }

            /**
             * Removes the affected objects from the given stack.
             * @param {object} data - Data that comes from socket handlers.
             * @param {{name:string}} stack - React state that we want to update.
             * @param {React.Dispatch<{name:string}>} stackStateUpdater - React state updater for the given stack state.
             */
            const removeFromTheStackOnHistoryUpdate = (data, stack, stackStateUpdater) => {
                const canvas = data?.canvas;
                let modifiedStack = stack;
                modifiedStack = modifiedStack.map(item => {
                    if (item.action === 'stackOrder') {
                        let affectedObjects = [];

                        item.newStackOrder.map((n) => {
                            const checkAffected = item.oldStackOrder.find(f => n.uuid === f.uuid)
                            if (n.zIndex !== checkAffected.zIndex) {
                                let changedObject = canvas.getObjects().find(c => c.uuid === n.uuid);
                                if (!changedObject) changedObject = data.affectedObjects.find(a => a.uuid === n.uuid);

                                affectedObjects.push(changedObject);
                            }
                        })

                        return { ...item, affectedObjects }
                    }
                    else return item
                })
                const allAffectedObjectsInUndoStack = modifiedStack.filter(
                    historyStack => (historyStack.action === 'stateChange' || historyStack?.action === 'stackOrder')
                ).map(
                    obj => obj.affectedObjects?.map(o => o.uuid)
                )?.flat();
                if (!allAffectedObjectsInUndoStack?.length) return;

                for (const obj of data.affectedObjects) {
                    if (allAffectedObjectsInUndoStack.includes(obj.uuid)) {
                        stackStateUpdater(data => {
                            const removedUuids = [];
                            for (let i = data.length - 1; i >= 0; i-- ) {
                                const singleStackData = data[i];
                                // get frame id if the object has frame that attached to it
                                const frameId = obj.properties?.attachedFrameId;
                                let shouldFilterFrameId = !!frameId;
                                let isThereAttachedFrameInCurrentStack = singleStackData?.affectedObjects?.filter(o => o.uuid === frameId)?.length ? true : false;

                                const filteredAffectedObjects = singleStackData?.affectedObjects?.filter(
                                    o => {
                                        // if the object is removed in the later stack, remove from this stack too
                                        if (removedUuids.includes(o.uuid)) return false;
                                        let filterResult = o.uuid !== obj.uuid && 
                                            // if the object has frame that attached to it, we should remove the frame from the stack
                                            (shouldFilterFrameId ? o.uuid !== frameId : true) &&
                                            // if the object has frame that attach to it and that frame isn't in the current stack, we should remove other objects
                                            ((shouldFilterFrameId && isThereAttachedFrameInCurrentStack) ? o?.properties?.attachedFrameId !== frameId : true)

                                        // if the object is newly created in this stack and reflection of it has a frame and that frame is somehow modified, we should remove the object from the stack
                                        if (o?.action === 'created' && shouldFilterFrameId && o?.properties?.type !== 'frame' && canvas) {
                                            const objInCanvas = canvas.getObjects().find(e => e.uuid === o?.uuid);
                                            if (objInCanvas?.attachedFrameId && objInCanvas.attachedFrameId === frameId) filterResult = filterResult && false;
                                        }

                                        // if we need to delete this object, add its' uuid to the removeduuids
                                        if (!filterResult) removedUuids.push(o.uuid);
                                        return filterResult;
                                    }
                                )
                                if (!filteredAffectedObjects?.length) {
                                    delete data[i];
                                } else {
                                    singleStackData.affectedObjects = filteredAffectedObjects;
                                }
                            }
                            // filter empty data
                            data = data.filter(d => d)
                            return data;
                        });
                    }
                }
            }
            /**
             * Adds the given history data to history stack.
             * @param {object} historyData - History data that we want to add to history.
             * @param canvas
             */
            const addToHistoryListener = (historyData, canvas) => {
                // remove affected objects from the stacks since last update made by another user
                removeFromTheStackOnHistoryUpdate(historyData, undoStack, setUndoStack, canvas);
                removeFromTheStackOnHistoryUpdate(historyData, redoStack, setRedoStack, canvas);

                _addToHistory(historyData, { updateFrames: true })
            }

            const checkTargetAndArrangeText = (target) => {
                if (isTargetIncludeText(target)) {
                    const textObj = target._objects[1];
                    textObj.visible = true;
                    textObj.breakWords = true;
                    textObj.splitByGrapheme = false;
                    textObj.objectCaching = false;
                    arrangeTextInsideShape(target);
                }
            }

            /**
             * Creates a fabric object from data.
             * @param {object} data - The object data that we want to create an fabric instance.
             * @returns 
             */
            const _createObjectFromData = async (data) => {
                return new Promise((resolve) => {
                    createFabricInstance(data, function (objects) {
                        objects.forEach(function (o) {
                            o.toObject = cloneFabricObjectHelper(o);
                            o.isCreatedViaHistory = true;
                            canvas.add(o);
                            
                            if (o.type === 'curvedLine') {
                                attachPolygonsWithCanvasMap(o, canvas);
                            } else if (o.lines) {
                                attachLinesInUuidList(o, canvas)
                            }

                            if (o.type === 'frame') {
                                onFrameDrawn(o, canvas);
                            } else if (o.type === 'table') {
                                onTableDrawn(o);
                            } else if (o.type === 'curvedLine') { // Needed to calculate dimensions, path offset etc for the seamless alignment.
                                o.calculateBoundingBoxForCurvedLine();
                            }

                            // Getting owner name
                            if (o.type === 'group' && o.shapeType === 'sticky') {
                                const owner = getOwnerOfSticky(userId);

                                if (owner?.name) {
                                    o.ownerName = owner.name;
                                }
                            }

                            checkTargetAndArrangeText(o);
                            
                            resolve(o);
                        });
                    });
                });
            }

            /**
             * Creates a fabric object from data and returns the object.
             * @param {object} data - The object data that we want to create an fabric instance.
             * @returns 
             */
            const createObjectFromData = async (data) => {
                try {
                    const object = await _createObjectFromData(data);

                    if (object.stackOrder && object.stackOrder > -1) {
                        setSingleObjectStack(canvas, object, object.stackOrder);
                    }
                    return object;
                } catch (err) {
                    console.error('Error while creating object from data', err);
                    return null;
                }
            }

            /**
             * Handles frame attachments on mutation from history.
             * @param object
             * @private
             */
            const _handleFrameAttachmentsOnMutate = (object) => {
                try {
                    // if the object frame, find object attachments and attach them to the frame
                    if (object.type === 'frame') {
                        const objectList = [];
                        if (object.attachments) {
                            for (const attachmentUuid of object.attachments) {
                                const attachedObj = canvas?.getObjects()?.find(e => e.uuid === attachmentUuid);
                                if (attachedObj) {
                                    objectList.push(attachedObj)
                                }
                            }
                        }

                        if (object?.attachedFrameId) {
                            const frame = canvas?.getObjects()?.find(e => e.uuid === object.attachedFrameId);
                            if (isObjectInsideOfObject(frame, object, { manualCheck: true })) {
                                attachToFrame(object, frame, { allowAttachingToLockedFrame: true })
                            }
                        }

                        //need to reAttach the sub objects if they still inside of deleted frame second step
                        const allObjectsExceptTarget = canvas.getObjects().filter(o => o !== object);
                        if (allObjectsExceptTarget) {
                            allObjectsExceptTarget.forEach(item => {
                                if (isObjectInsideOfObject(object, item, { manualCheck: true }) && !item.attachedFrameId) {
                                    attachToFrame(item, object, { allowAttachingToLockedFrame: true });
                                }
                            })
                        }

                        const attachedObjects = canvas.getObjects().filter(o => o !== object && o.attachedFrameId === object.uuid)
                        if (attachedObjects) {
                            const otherAttachments = attachedObjects.filter(o => !objectList.includes(o));
                            if (otherAttachments) {
                                objectList.push(...otherAttachments)
                            }
                        }

                        for (const attachedObject of attachedObjects) {
                            if (isObjectInsideOfObject(object, attachedObject, { manualCheck: true})) {
                                attachToFrame(attachedObject, object, { allowAttachingToLockedFrame: true })
                            }
                        }

                        let formerStackOrders = [];

                        undoStack[undoStack.length - 1].stackOrders.forEach((s) => {
                            canvas.getObjects().forEach((f) => {
                                if (f.uuid === s) {
                                    formerStackOrders.push(f);
                                }
                            })
                        });

                        formerStackOrders.forEach(item => {
                            handleFrameStackOrder(canvas, item);
                        });
                    } else {
                        // if object has frame and if it is inside of that frame, then attach it
                        if (object?.attachedFrameId) {
                            const frame = canvas?.getObjects()?.find(e => e.uuid === object.attachedFrameId);
                            if (isObjectInsideOfObject(frame, object, { manualCheck: true})) {
                                attachToFrame(object, frame, { allowAttachingToLockedFrame: true })
                            }
                        }
                    }
                } catch (err) {
                    console.error('error while handling frame attachment', object, err)
                }
            }

            /**
             * Creates a fabric object from data and pushes the object to the given list.
             * @param {object} data - The object data that we want to create an fabric instance.
             * @param {fabric.Object[]} list - The list that we want to push the created object.
             */
            const createObjectAndPushToList = async (data, list) => {
                const objectInstance = await createObjectFromData(data);
                _handleFrameAttachmentsOnMutate(objectInstance)
                if (objectInstance) {
                    list.push(objectInstance); 
                }
            }

            // emits the data list with given action
            const _emitListData = (list, action, options = {}) => {
                if (!list.length) {
                    return
                }
                
                if (action === 'created' || action === 'deleted') {
                    const emitData = {
                        objects: list,
                        action: action,
                        ...options
                    }

                    if (action === 'created') {
                        emitData.isCreatedViaHistory = true;
                    }

                    canvas.fire('history-emit-data', emitData);
                } else if (action === 'modified') {
                    canvas.collaborationManager.sendDirectPartialUpdate(list, pageId)
                }
            }

            const historyDeleteHandler = (data, addList) => {
                try {
                    const structuredObj = JSON.parse(JSON.stringify(data));
                    const localObj = canvas.getObjects().find(e => e.uuid === structuredObj.uuid);
                    if (localObj) {
                        addList.push(localObj);
                        canvas.remove(localObj);
                    }
                } catch (err) {
                    console.error('error while deleting object on undo redo', err);
                }

            }

            const historyCreateHandler = async (data, addList) => {
                try {
                    const structuredObj = JSON.parse(JSON.stringify(data));
                    await createObjectAndPushToList(structuredObj, addList); 
                } catch (error) {
                    console.error('error while creating object on undo redo', error);
                }

            }

            const undoRedoListener = async listenerData => {
                let type;
                if (typeof listenerData === 'string') {
                    type = listenerData;
                } else {
                    type = listenerData.type;
                }
                removeAllExistedLasso(canvas);
                if (isProcessing.current) {
                    console.log('undo-redo disabled until the process is completed');
                    return;
                }
                try {
                    isProcessing.current = true;
                    if (type === 'undo') {
                        canvas.discardActiveObject().requestRenderAll();
                        if (!undoStack || !undoStack.length) {
                            setUndoStack([]);
                            return;
                        }
                        const lastData = undoStack.slice(-1)[0];
                        
                        const data = undoStack.slice(0, undoStack.length - 1);
                        
                        let blockProcessId;

                        if (lastData?.action === 'stateChange') {
                            const affectedShapes = lastData.affectedObjects;
                            // don't need to find the send object instances.
                            const editingObjects = affectedShapes.filter(shapeData => shapeData.action === 'modified')
                                .map(state => ({...state, uuid: state.data.uuid}))

                            if (editingObjects.length) {
                                // abortion handler to revert the state
                                const abortStepHandler = (processId) => {
                                    const canvasObjects = canvas.getObjects()
                                    for (const state of editingObjects) {
                                        const objInCanvas = canvasObjects.find(o => o.uuid === state.uuid)
                                        if (!objInCanvas) {
                                            continue
                                        }
                                        
                                        const originalData = state.data;
                                        objInCanvas.onShapeChanged()
                                        objInCanvas.updateWithState(originalData)
                                    }
                                    canvas.lockManager.stopEditing(editingObjects, canvas.pageId, processId);
                                    canvas.fire('remove-from-redo-stack', { processId: lastData.processId })
                                    canvas.renderAll()
                                }

                                const { shapeUuids, processId } = canvas.lockManager.startEditing(
                                    editingObjects,
                                    canvas.pageId,
                                    (data) => {
                                        if (!data?.failedShapes || !data?.failedShapes.length) {
                                            return
                                        }
                                        abortStepHandler(data.processId)
                                    }
                                )
                                blockProcessId = processId;

                                if (!shapeUuids?.length || shapeUuids?.length !== editingObjects.length) {
                                    abortStepHandler(blockProcessId)
                                    return
                                } 
                            }

                            const allModifiedObjects = [];
                            const allCreatedObjects = [];
                            const allDeletedObjects = [];

                            for (const objectHistoryData of lastData.affectedObjects) {
                                if (objectHistoryData?.action === 'modified') {
                                    // we only support shape partial update from now on, 11.10.2024.
                                    if (!objectHistoryData.shapePartialUpdate) {
                                        return
                                    }
                                    const oldData = objectHistoryData.prevData;
                                    const objReflection = canvas.getObjects().find(e => e.uuid === objectHistoryData.data.uuid);
                                    objReflection.onShapeChanged()
                                    objReflection.updateWithState(oldData)
                                    moveTheCommentAndFireCanvasEvent(canvas, objReflection)
                                    
                                    allModifiedObjects.push(objectHistoryData.prevData);
                                } else if (objectHistoryData?.action === 'created' && isUserHasAccessToFeature('history_create_or_delete', userAccessRef.current)) {
                                    historyDeleteHandler({...objectHistoryData?.properties, zIndex: objectHistoryData?.properties?.zIndex}, allDeletedObjects);
                                } else if (objectHistoryData?.action === 'deleted' && isUserHasAccessToFeature('history_create_or_delete', userAccessRef.current)) {
                                    await historyCreateHandler({...objectHistoryData?.properties, zIndex: objectHistoryData?.properties?.zIndex}, allCreatedObjects);
                                }
                            }
                            
                            if (allCreatedObjects?.length) _emitListData(allCreatedObjects, 'created', {source: 'undoRedoListener'});
                            if (allModifiedObjects?.length) _emitListData(allModifiedObjects, 'modified', { useQueue: true });
                            if (allDeletedObjects?.length) _emitListData(allDeletedObjects, 'deleted', { addToHistory: false, source: 'undoRedoListener' }); 
                            
                            if (editingObjects.length) {
                                canvas.lockManager.stopEditing(editingObjects, canvas.pageId, blockProcessId);
                            }
                        } else if (lastData?.action === 'stackOrder') {
                            let prevStackorder = lastData.oldStackOrder;
                            if (prevStackorder) {
                                let changedObjects = [];

                                prevStackorder.forEach(p => {
                                    let matchedObj = canvas.getObjects().find(c => c.uuid === p.uuid);
                                    if (matchedObj && matchedObj.zIndex !== p.zIndex) {
                                        matchedObj.zIndex = p.zIndex;
                                        changedObjects.push(matchedObj);
                                    }
                                });

                                canvas.fire('history-emit-data', {
                                    action: 'stackOrder',
                                    stackOrder: prevStackorder,
                                    updatedStacks: changedObjects.map(item => {
                                        return {zIndex: item.zIndex, uuid: item.uuid}
                                    })
                                });
                            }
                            setObjectsStackWithZIndex(canvas);
                        }
                        
                        // set undo and redo
                        setUndoStack(Array.isArray(data) ? data : []);
                        setRedoStack(currentRedoData => {
                            const newRedoData = [...currentRedoData, { ...lastData }];
                            redoStackRef.current = newRedoData;
                            return newRedoData;
                        });
                    } else if (type === 'redo') {
                        canvas.discardActiveObject().requestRenderAll();
                        if (!redoStack || !redoStack.length) {
                            setRedoStack([])
                            return;
                        }
                        const lastData = redoStackRef.current.slice(-1)[0];
                        const data = redoStackRef.current.slice(0, redoStackRef.current.length - 1);

                        const affectedShapes = lastData.affectedObjects || [];

                        let blockProcessId;

                        if (lastData?.action === 'stateChange') {

                            // for modified shapes, we need to lock them first
                            const editingObjects = affectedShapes.filter(shapeData => shapeData.action === 'modified')
                                .map(state => ({...state, uuid: state.data.uuid}))

                            if (editingObjects.length) {
                                // abortion handler to revert the state
                                const abortStepHandler = (processId) => {
                                    const canvasObjects = canvas.getObjects()
                                    for (const state of editingObjects) {
                                        const objInCanvas = canvasObjects.find(o => o.uuid === state.uuid)
                                        if (!objInCanvas) {
                                            continue
                                        }

                                        const originalData = state.prevData;
                                        objInCanvas.onShapeChanged()
                                        objInCanvas.updateWithState(originalData)
                                    }
                                    canvas.lockManager.stopEditing(editingObjects, canvas.pageId, processId);
                                    canvas.renderAll()
                                    canvas.fire('remove-from-undo-stack', { processId: lastData.processId })
                                }
                                const { shapeUuids, processId } = canvas.lockManager.startEditing(
                                    editingObjects,
                                    canvas.pageId,
                                    (data) => {
                                        if (!data?.failedShapes || !data?.failedShapes.length) {
                                            return
                                        }
                                        abortStepHandler(data.processId)
                                    }
                                )

                                blockProcessId = processId;

                                if (!shapeUuids?.length || shapeUuids?.length !== editingObjects?.length) {
                                    abortStepHandler(blockProcessId)
                                    return
                                }
                            }

                            
                            const allModifiedObjects = [];
                            const allCreatedObjects = [];
                            const allDeletedObjects = [];

                            for (const objectHistoryData of lastData.affectedObjects) {
                                if (objectHistoryData?.action === 'modified') {
                                    const objReflection = canvas.getObjects().find(e => e.uuid === objectHistoryData.data.uuid);
                                    objReflection.onShapeChanged()
                                    objReflection.updateWithState(objectHistoryData.data)
                                    moveTheCommentAndFireCanvasEvent(canvas, objReflection)
                                    allModifiedObjects.push(objectHistoryData.data)
                                } else if (objectHistoryData?.action === 'created' && isUserHasAccessToFeature('history_create_or_delete', userAccessRef.current)) {
                                    await historyCreateHandler(objectHistoryData?.properties, allCreatedObjects);
                                } else if (objectHistoryData?.action === 'deleted' && isUserHasAccessToFeature('history_create_or_delete', userAccessRef.current)) {
                                    historyDeleteHandler(objectHistoryData?.properties, allDeletedObjects);
                                }
                            }

                            if (allCreatedObjects?.length) _emitListData(allCreatedObjects, 'created');
                            if (allModifiedObjects?.length) _emitListData(allModifiedObjects, 'modified', { useQueue: true });
                            if (allDeletedObjects?.length) _emitListData(allDeletedObjects, 'deleted');
                            setObjectsStackWithZIndex(canvas);
                            
                            if (editingObjects?.length) {
                                canvas.lockManager.stopEditing(editingObjects, canvas.pageId, blockProcessId);
                            }
                        } else if (lastData?.action === 'stackOrder') {
                            let newStackOrder = lastData.newStackOrder;
                            if (newStackOrder) {
                                let changedObjects = [];

                                newStackOrder.forEach(p => {
                                    const matchedObj = canvas.getObjects().find(c => c.uuid === p.uuid);
                                    if (matchedObj && matchedObj.zIndex !== p.zIndex) {
                                        matchedObj.zIndex = p.zIndex;
                                        changedObjects.push(matchedObj);
                                    }
                                });

                                canvas.fire('history-emit-data', {
                                    action: 'stackOrder',
                                    stackOrder: newStackOrder,
                                    updatedStacks: changedObjects.map(item => {
                                        return {zIndex: item.zIndex, uuid: item.uuid}
                                    })
                                });
                            }
                            setObjectsStackWithZIndex(canvas);
                        }
                        
                        setUndoStack(undoStack => [...undoStack, {...lastData }]);
                        setRedoStack(data);
                        redoStackRef.current = data;
                        
                        setObjectsStackWithZIndex(canvas);
                    }
                } catch (err) {
                    console.error('Error while undo redo', err);
                } finally {
                    isProcessing.current = false;
                    setObjectsStackWithZIndex(canvas);
                }
            }

            /**
             * Removes a specific undo stack with given processId.
             * @param {object} removeData The data that we want to remove from the undo stack.
             * @param {string} removeData.processId The processId that we want to remove from the undo stack.
             */
            const removeFromUndoStackListener = (removeData) => {
                if (!removeData || !removeData.processId) {
                    return
                }
                setUndoStack(data => {
                    return data.filter(d => d.processId !== removeData.processId);
                });
            }

            /**
             * Removes a specific redo stack with given processId.
             * @param {object} removeData The data that we want to remove from the redo stack.
             * @param {string} removeData.processId The processId that we want to remove from the redo stack.
             */
            const removeFromRedoStackListener = (removeData) => {
                if (!removeData || !removeData.processId) {
                    return
                }
                setRedoStack(data => {
                    return data.filter(d => d.processId !== removeData.processId);
                });
            }

            /**
             * Removes given shape from the undo and redo stacks so that user can't modify it with undo and redo.
             * @param {object} data The data that we want to remove from the undo and redo stacks.
             * @param {fabric.Object[]} data.shapes The shapes that we want to remove from the undo and redo stacks.
             */
            const removeFromUndoRedoStackListener = (data) => {
                const removeData = {
                    affectedObjects: data.shapes,
                    action: 'stateChange',
                    canvas
                }
                removeFromTheStackOnHistoryUpdate(removeData, undoStack, setUndoStack, canvas);
                removeFromTheStackOnHistoryUpdate(removeData, redoStack, setRedoStack, canvas);
            }

            /**
             * Listens shape-partial-update event. Earlier it was object:modified-after or modify-to-undo-stack.
             * @param {object} historyUpdateData History update data from collaboration manager.
             */
            const collaborationHistoryUpdateListener = (historyUpdateData) => {
                const data = []
                
                if (historyUpdateData.modified && historyUpdateData.modified?.data?.length) {
                    for (const singleData of historyUpdateData.modified.data) {
                        data.push({
                            action: 'modified',
                            shapePartialUpdate: true,
                            data: singleData,
                            prevData: historyUpdateData.modified.prevData.find(obj => obj.uuid === singleData.uuid),
                            uuid: singleData.uuid,
                        })
                    }
                }
                
                if (historyUpdateData.created && historyUpdateData.created?.data?.length) {
                    for (const createdShape of historyUpdateData.created.data) {
                        const objectData = customToObject(createdShape)
                        let thisAttachments = [];
                        if (createdShape.type === 'frame' && !createdShape.newlyCreated) {
                            let actionForAttachments = 'created';
                            thisAttachments = generateAttachedObjectDataForFrame(createdShape, actionForAttachments, true);
                        }
                        data.push(dataToHistoryObject(objectData, 'created'), ...thisAttachments); 
                    }
                }
                
                if (historyUpdateData.deleted && historyUpdateData.deleted?.data.length) {
                    for (const deletedShape of historyUpdateData.deleted.data) {
                        const objectData = customToObject(deletedShape)
                        if(!objectData.visible){
                            // while deleted object send to history make it visible so that if we create this object its visibility again set by its layer
                            objectData.visible = true;
                        }
                        data.push(dataToHistoryObject(objectData, 'deleted'));
                    }
                }
                
                addtoUndoStack({
                    action: 'stateChange',
                    processId: historyUpdateData.processId,
                    affectedObjects: data
                }, false)
            }

            canvas.on('add-to-undo-stack', addtoUndoStackListener);
            canvas.on('stackOrder-to-undo-stack', stackOrderToUndoStackListener);
            canvas.on('add-to-history', addToHistoryListener);
            canvas.on('undo-redo', undoRedoListener);
            canvas.on('remove-from-undo-stack', removeFromUndoStackListener);
            canvas.on('remove-from-redo-stack', removeFromRedoStackListener);
            canvas.on('remove-from-undo-redo-stack', removeFromUndoRedoStackListener);
            canvas.on('collaboration-history-update', collaborationHistoryUpdateListener);
            canvas.on('update-shape-prev-state-layer', updateLayerIdToPrevShapeStates);

            return () => {
                canvas.off('add-to-undo-stack', addtoUndoStackListener);
                canvas.off('stackOrder-to-undo-stack', stackOrderToUndoStackListener);
                canvas.off('add-to-history', addToHistoryListener);
                canvas.off('undo-redo', undoRedoListener);
                canvas.off('remove-from-undo-stack', removeFromUndoStackListener);
                canvas.off('remove-from-redo-stack', removeFromRedoStackListener);
                canvas.off('remove-from-undo-redo-stack', removeFromUndoRedoStackListener);
                canvas.off('collaboration-history-update', collaborationHistoryUpdateListener);
                canvas.off('update-shape-prev-state-layer', updateLayerIdToPrevShapeStates);
            }
        }
    }, [canvas, undoStack, redoStack, addtoUndoStack, history, moveTheCommentAndFireCanvasEvent]);

    useEffect(() => {
        if (undoStack.length > 0) {
            const lastData = undoStack[undoStack.length - 1];
            if (lastData.aborted) {
                canvas.fire('undo-one-step', { processId: lastData.processId });
            }
            setEnabledHistory(data => {
                return {
                    ...data,
                    undo: true
                }
            });
        } else {
            setEnabledHistory(data => {
                return {
                    ...data,
                    undo: false
                }
            });
        }
    }, [undoStack]);

    useEffect(() => {
        if (redoStack.length > 0) {
            setEnabledHistory(data => {
                return {
                    ...data,
                    redo: true
                }
            });
        } else {
            setEnabledHistory(data => {
                return {
                    ...data,
                    redo: false
                }
            });
        }
    }, [redoStack]);

    // clear history data when socket disconnected
    useEffect(() => {
        if (socketConnectionStatus === SOCKET_STATUS_MODS.DISCONNECTED || socketConnectionStatus === SOCKET_STATUS_MODS.RECONNECT_ATTEMPT) {
            setUndoStack([]);
            setRedoStack([]);
            setHistory([]);
        }
    }, [socketConnectionStatus]);

    return { enabledHistory };
}

export default useHistory;