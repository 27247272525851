import React, { useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from 'react';
import { fabric } from 'fabric';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
    getInnerColorOfShape,
    getOuterColorOfShape,
    getShapeOfTheTarget,
    isTargetImage,
    isTargetLine,
    modifyObjectPropertiesForLocking,
    isTargetLocked,
    applyNewStyleForTextBox,
    changeTextSizeForStickyAndShape,
    changeTextSizeForTextBox,
    getHighlightColorOfText,
    getCurrentTextSize,
    getColorsOfTextWithSelection,
    isTargetHasText,
    getTextColorOfShape,
    getTextShapeFromGroup, 
    getSelectionTextColorFromText,
    createSelectionForObjects,
    getShapeLayerInfo
} from '../../helpers/FabricMethods';

import { EMITTER_TYPES, LINE_TYPES, SHAPE_TYPES, SOCKET_EVENT, SUBTOOLBAR_FILTER_ITEMS, SUBTOOLBAR_ITEMS, TEXT_SIZE_OPTIONS, TOOLBAR_DISPACH_ACTIONS, TOOLBAR_INITIAL_STATE, TOOLBAR_MENU, TOOLBAR_MENU_NAMES, EDITING_METHODS, NOT_ALLOWED_SHAPE_FOR_LAYER } from '../../helpers/Constant';

import './SubToolbar.scss';
import {removeAllExistedLasso} from '../../hooks/UseLasso';
import {chooseTextColorBasedOnBackground, getBrightnessFromRGBA, getRgbaAsString} from '../../helpers/ColorHelper';

import 'rc-slider/assets/index.css';
import { getActiveFontStyle, getActiveTextAlign, getRgbaVal } from '../../helpers/toolbar/ActiveStyles';
import { isUserHasAccessToFeature, openShapeEditor } from '../../helpers/CommonFunctions';
import eventEmitter from '../../helpers/EventEmitter';
import { getExactFontSize } from '../../helpers/table/TableEventMethods';
import { createInstanceForChangedShape, getShapeTextareaDimensions } from '../../helpers/shapes/Common';
import { getSingleObjectStackOrder, getStackOrderFromCanvas, setSingleObjectStack } from '../../helpers/StackOrder';
import { attachToFrame, getFrameAttachedShapes } from '../../helpers/frame/FrameMethods';
import { findPositionOfConnectorControl } from '../../helpers/customControls/connector/ConnectorControlMethods';
import { deepClone, hexToRGBA } from '../../helpers/CommonUtils';
import detachControl from '../../helpers/lines/DetachControl';
import {changeZIndex} from '../../helpers/zIndex/ManageZIndex';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import ChangingProgressProvider from '../boardListing/ChangingProgressProvider';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import getToastIcon from '../../helpers/media/GetToastIcon';
import { isCurvedLineDrawingAsStraight } from '../../helpers/lines/LineMethods';
import HtmlEditorHelper from '../../helpers/HtmlEditorHelper';
import SubToolbarItem from './SubToolbarItem';
import { debounce } from '../../helpers/OptimizationUtils';
import { showFrameObjectsHiddenAffectedToast } from '../../helpers/LayersHelper';

let isMoved = false;

const reducer = (state, action) => {
    if (action.type === TOOLBAR_DISPACH_ACTIONS.SET_TARGET) {
        let target = action.target;
        if (!target) return TOOLBAR_INITIAL_STATE;

        if (target.type === 'comment' || target.type === 'mockFrame' || target.type === 'mockImage') return TOOLBAR_INITIAL_STATE;  // disable subtoolbar for comments
        if (target.shapeType && (
            target.shapeType === 'frameText' ||
            target.shapeType === 'loadingMockImagesGroup')) return TOOLBAR_INITIAL_STATE ;  // disable subtoolbar for frameText

        if (!target) return state;
        let subType = (target.type === 'group' || target.shapeType === 'sticky') ? target._objects[1].text ? 'textbox' : '' : '';
        if (target.type === 'group' && target.isHtmlEditingMode === true && (target.htmlMode === 'edit' || target._objects[1]?.text?.length > 0)) {
            subType = 'textbox';
        }
        if (target.type === 'table' && target.isCellSelected) {
            subType = 'textbox';
        }

        // get shape of the target
        const shape = getShapeOfTheTarget(target);
        if (!shape) return;

        let hide = false;
        if (target.showToolbarAfterCreate) {
            hide = true;
        }

        const innerColor = getInnerColorOfShape(shape, target);
        const outerColor = getOuterColorOfShape(shape);
        const highlightColor = getHighlightColorOfText(shape);
        const textColor = getTextColorOfShape(target);
        
        let isThereTextInShapes = isTargetHasText(target);
        
        return  {
            ...state,
            target,
            shape,
            type: target.type,
            subType,
            hide,
            isThereTextInShapes,
            innerColorObj: {
                displayColorPicker: false,
                color: getRgbaVal(innerColor),
                isColorChanged: false,
            },
            outerColorObj: {
                displayColorPicker: false,
                color: getRgbaVal(outerColor),
                isColorChanged: false,
            },
            textColorObj: {
                displayColorPicker: false,
                color: getRgbaVal(textColor),
                isColorChanged: false,
            },
            highlightTextColorObj :{
                displayColorPicker: false,
                color: highlightColor ? getRgbaVal(highlightColor) : getRgbaVal('rgba(255,255,255,1)'),
                isColorChanged: false,
            },
            shapeLayerObj :{
                showLayerList: false,
                disableShowLayerListBtn: false,
                layerId: null,
                ...getShapeLayerInfo(target)
            }
        }
    } else if (action.type === TOOLBAR_DISPACH_ACTIONS.HIDE) {
        return {
            ...state,
            menu: {
                [TOOLBAR_MENU_NAMES.LINE_TYPE]: false,
                [TOOLBAR_MENU_NAMES.ARROW_TYPE]: false,
                [TOOLBAR_MENU_NAMES.HYPERLINK]: false,
                [TOOLBAR_MENU_NAMES.CHANGE_SHAPE]: false,
                [TOOLBAR_MENU_NAMES.FILTERS_MENU]: false,
            },
            hide: true
        }
    } else if (action.type === TOOLBAR_DISPACH_ACTIONS.SHOW) {
        return {
            ...state,
            hide: false
        }
    } else if (action.type === TOOLBAR_DISPACH_ACTIONS.REMOVE) {
        return {
            ...state,
            target: null,
            hide: false,
            subType: null,
            menu: {
                [TOOLBAR_MENU_NAMES.LINE_TYPE]: false,
                [TOOLBAR_MENU_NAMES.ARROW_TYPE]: false,
                [TOOLBAR_MENU_NAMES.HYPERLINK]: false,
                [TOOLBAR_MENU_NAMES.CHANGE_SHAPE]: false,
                [TOOLBAR_MENU_NAMES.FILTERS_MENU]: false,
            }
        }
    } else if (action.type === TOOLBAR_DISPACH_ACTIONS.TOGGLE_COLOR_PICKER) {
        const colorPropertyName = `${action.colorType}ColorObj`;
        let menu = state.menu;
        // close other menus such as line type, arrow type, text align, font style
        if (action.closeMenu) {
            const otherMenu = Object.values(TOOLBAR_MENU);
            menu = Object.fromEntries(otherMenu.map(m => [TOOLBAR_MENU_NAMES[m], false]));
        }
        if (action.closeOthers) {
            return {
                ...state,
                innerColorObj: {
                    ...state.innerColorObj,
                    displayColorPicker: false,
                    isColorChanged: false,
                },
                outerColorObj: {
                    ...state.outerColorObj,
                    displayColorPicker: false,
                    isColorChanged: false,
                },
                textColorObj: {
                    ...state.textColorObj,
                    displayColorPicker: false,
                    isColorChanged: false,
                },
                highlightTextColorObj :{
                    ...state.highlightTextColorObj,
                    displayColorPicker: false,
                    isColorChanged: false,
                },
                [colorPropertyName]: {
                    ...state[colorPropertyName],
                    displayColorPicker: action.value,
                    isColorChanged: false,
                },
                menu: {
                    ...menu,
                }
            }
        }

        return {
            ...state,
            [colorPropertyName]: {
                ...state[colorPropertyName],
                displayColorPicker: action.value
            },
            menu: {
                ...menu,
            }
        }
    } else if (action.type === TOOLBAR_DISPACH_ACTIONS.CLOSE_ALL_COLOR_PICKERS) {
        return {
            ...state,
            innerColorObj: {
                ...state.innerColorObj,
                displayColorPicker: false,
                isColorChanged: false,
            },
            outerColorObj: {
                ...state.outerColorObj,
                displayColorPicker: false,
                isColorChanged: false,
            },
            textColorObj: {
                ...state.textColorObj,
                displayColorPicker: false,
                isColorChanged: false,
            },
            highlightTextColorObj :{
                ...state.highlightTextColorObj,
                displayColorPicker: false,
                isColorChanged: false,
            }
        }
    } else if (action.type === TOOLBAR_DISPACH_ACTIONS.CHANGE_COLOR) {
        const propertyName = `${action.colorType}ColorObj`;
        return {
            ...state,
            [propertyName]: {
                ...state[propertyName],
                color: action.value,
                isColorChanged: true
            }
        }
    } else if(action.type === TOOLBAR_DISPACH_ACTIONS.CHANGE_SELECTION_TEXT_BOX) {
        const colors = action.colors;
        return {
            ...state,
            outerColorObj: {
                ...state.textColorObj,
                color: getRgbaVal(colors.textColor),
            },
            highlightTextColorObj: {
                ...state.highlightTextColorObj,
                color: getRgbaVal(colors.textHighlightColor)
            }
        }
    } else if (action.type === TOOLBAR_DISPACH_ACTIONS.CHANGE_SELECTION_EDIT_TEXT_BOX) {
        const colors = action.colors;
        return {
            ...state,
            textColorObj: {
                ...state.textColorObj,
                color: getRgbaVal(colors.textColor),
            },
        } 
    } else if (action.type === TOOLBAR_DISPACH_ACTIONS.TOGGLE_MENU) {
        const menu = action.menu;

        if (
            menu &&
            Object.values(TOOLBAR_MENU).includes(menu)
        ) {
            const menuState = state?.menu?.[TOOLBAR_MENU_NAMES[menu]];
            // close other menu
            const otherMenu = Object.values(TOOLBAR_MENU).filter(m => m !== menu);
            const closedMenuState = Object.fromEntries(otherMenu.map(m => [TOOLBAR_MENU_NAMES[m], false]));

            return {
                ...state,
                menu: {
                    ...state?.menu,
                    ...closedMenuState,
                    [TOOLBAR_MENU_NAMES[menu]]: !menuState
                }
            }
        } else {
            // if menu is not sent, close all menus in the state
            const allMenu = Object.values(TOOLBAR_MENU);
            const closedMenuState = Object.fromEntries(allMenu.map(m => [TOOLBAR_MENU_NAMES[m], false]));

            return {
                ...state,
                menu: {
                    ...state?.menu,
                    ...closedMenuState,
                }
            }
        }
    } else if (action.type === TOOLBAR_DISPACH_ACTIONS.UPDATE_COLORS) {
        let target = action.target;
        const shape = getShapeOfTheTarget(target);
        if (!shape) return;

        const innerColor = getInnerColorOfShape(shape, target);
        const outerColor = getOuterColorOfShape(shape);
        const highlightColor = getHighlightColorOfText(shape);
        const textColor = getTextColorOfShape(target);

        return  {
            ...state,
            innerColorObj: {
                ...state.innerColorObj,
                color: getRgbaVal(innerColor),
                isColorChanged: false
            },
            outerColorObj: {
                ...state.outerColor,
                color: getRgbaVal(outerColor),
                isColorChanged: false
            },
            textColorObj: {
                ...state.textColorObj,
                color: getRgbaVal(textColor),
                isColorChanged: false
            },
            highlightTextColorObj :{
                ...state.highlightTextColorObj,
                color: highlightColor ? getRgbaVal(highlightColor) : getRgbaVal('rgba(255,255,255,1)'),
                isColorChanged: false
            }
        }
    } else if(action.type === TOOLBAR_DISPACH_ACTIONS.CLOSE_AVAILABLE_LAYERS_LIST){
        return {
            ...state,
            shapeLayerObj:{
                ...state.shapeLayerObj,
                showLayerList: false
            }
        }
    } else if(action.type === TOOLBAR_DISPACH_ACTIONS.SHOW_AVAILABLE_LAYERS_LIST){
        // close others menu
        const otherMenu = Object.values(TOOLBAR_MENU);
        const menu = Object.fromEntries(otherMenu.map(m => [TOOLBAR_MENU_NAMES[m], false]));
        return {
            ...state,
            menu:{
                ...menu
            },
            shapeLayerObj:{
                ...state.shapeLayerObj,
                showLayerList: true
            }
        }
    } else if(action.type === TOOLBAR_DISPACH_ACTIONS.CHANGE_SHAPE_LAYER){
        return {
            ...state,
            shapeLayerObj:{
                ...state.shapeLayerObj,
                layerId: action.layerId
            }
        }
    }
    return state
}

const SubToolbar = ({
    canvas,
    userAccess,
    socketRef,
    whiteBoardId
}) => {
    const [state, dispatch] = useReducer(reducer, TOOLBAR_INITIAL_STATE)
    const reduxDispatch = useDispatch();

    const colorChangeTimerRef = useRef();
    const textSizeChangeTimerRef = useRef();
    const toolbarRef = useRef();
    const thicknessTimerRef = useRef();
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [isSelectionUpdated, setIsSelectionUpdated] = useState(1);
    const activityHistory = useSelector(state => state?.history);
    const activePageId = useSelector(state => state?.rightDrawer?.activePage?.id);
    const recentSelectedColors = useSelector(state => state?.board?.recentSelectedColors);
    const layers = useSelector((state) => state?.layers?.list);
    const [imageDownloading, setImageDownloading] = useState(false);
    // get lock state of the target
    // since fabric js locks the movement of the object in editing mode,
    // we need to check textboxes separately
    const isLocked = state?.target?.type !== 'textbox'
        // if the target isn't a textbox, check the lock state
        ? state?.target?.lockMovementX && state?.target?.lockMovementY
        // if its a textbox, check if its on the editing mode
        : state?.target?.isEditing ? false : state?.target?.lockMovementX && state?.target?.lockMovementY;

    // should hide actions but unlock for active selection in mixed lock state
    const shouldHideActionsButUnlock = (
        (
            state?.target?.type === 'activeSelection' &&
            isLocked &&
            state?.target?.isMixedLockState === true
        ) ||
        (state?.target?.type === 'table' && state.target.isCellSelected)
    );

    const [thicknessVal, setThicknessVal] = useState();
    const [maximumSize, setMaximumSize] = useState(TEXT_SIZE_OPTIONS[TEXT_SIZE_OPTIONS.length - 1]);
    const hueToastRef = useRef();
    const layerChangeSuccessToastId = useRef(null);

    useEffect(() => {
        if(state.target && ['textbox', 'group'].includes(state.target.type)){
            setMaximumSize(TEXT_SIZE_OPTIONS[TEXT_SIZE_OPTIONS.length - 1]);
        }
    }, [state.target?.uuid]);

    const colorOpacityHandler = (colorType, color) => {
        const localColorRGB = {...color.rgb};
        const stateColor = state[`${colorType}ColorObj`];

        if ((stateColor?.color && localColorRGB && color.isColorSelected !== true) && (
            stateColor.color.r !== localColorRGB.r &&
            stateColor.color.g !== localColorRGB.g &&
            stateColor.color.b !== localColorRGB.b
        )) {
            localColorRGB.a = 1;
        }
        return {
            ...color,
            rgb: localColorRGB
        }
    }



    const setInnerColor = (obj, color) => {
        color = colorOpacityHandler('inner', color);

        dispatch({
            type: TOOLBAR_DISPACH_ACTIONS.CHANGE_COLOR,
            value: color.rgb,
            colorType: 'inner'
        });
        let shape;
        let oldColor;
        if (obj.type === 'group') shape = obj._objects[0];
        else shape = obj;
        if (obj.type === 'textbox') shape.set('backgroundColor', `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
        else if (isTargetLine(obj)) shape.set('stroke', `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
        else {
            oldColor = shape.fill;
            shape.set('fill', `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
        }

        if (obj.type === 'group') {
            try {
                // if text color is applied, check contrast and warn user if the text is not readable with the background
                if ((isTargetHasText(obj)) && obj.isTextColorApplied) {
                    const textColor = getTextColorOfShape(getTextShapeFromGroup(obj))
                    textColor && checkTextAndShapeContrast(textColor, shape.fill, 'shape', oldColor);
                }
                // if text color is not applied, change the text color automatically
                if (!obj.isTextColorApplied) {
                    // a handler method for changing the selected text color in the state
                    // when the text color is changed by hue contrast effect
                    const textColorChangeHandler = (color) => {
                        dispatch({type: TOOLBAR_DISPACH_ACTIONS.CHANGE_SELECTION_EDIT_TEXT_BOX, colors: {
                            textColor: color,
                        }})
                    }
                    changeTheTextColorForShapeAndSticky(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`, textColorChangeHandler);
                    // Apply formatting to html editor if the shape in editing mode.
                    if (state?.target?.isHtmlEditingMode) {
                        HtmlEditorHelper.format(
                            'color',
                            chooseTextColorBasedOnBackground(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`),
                            true
                        );
                    }
                } 
            } catch (err) {
                console.error(err)
            }

        }
        state.target.onShapeChanged();
        obj.onShapeChanged();
        if (!obj.isTextColorApplied) { // To update html editor text color. 
            eventEmitter.fire(EMITTER_TYPES.HTML_EDITOR_STYLE_UPDATED, {});
        }
        canvas.renderAll();
    };

    const changeTheTextColorForShapeAndSticky = (color, colorChangeHandler) => {
        if (state.target.type !== 'group') {
            return
        }
        const textColor = chooseTextColorBasedOnBackground(color);
        colorChangeHandler && colorChangeHandler(textColor)
        const textbox = getTextShapeFromGroup(state.target)
        if (!textbox) {
            return
        }
        
        textbox.set({ fill: textColor });
    }

    const setHighlightTextColor = (obj, color) => {
        color = colorOpacityHandler('highlightText', color);
        dispatch({
            type: TOOLBAR_DISPACH_ACTIONS.CHANGE_COLOR,
            value: color.rgb,
            colorType: 'highlightText'
        });
        if (obj.type === 'textbox') {
            let newStyle = {};
            newStyle.textBackgroundColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
            applyNewStyleForTextBox(obj, newStyle);
            state.target.onShapeChanged();
            obj.onShapeChanged();
        }
        canvas.renderAll();
    }

    const setOuterColor = (obj, color) => {
        color = colorOpacityHandler('outer', color);

        dispatch({
            type: TOOLBAR_DISPACH_ACTIONS.CHANGE_COLOR,
            value: color.rgb,
            colorType: 'outer'
        });

        let shape;
        if (obj.type === 'group') shape = obj._objects[0];
        else shape = obj;
        if (obj.type === 'textbox') {
            let newStyle = {};
            newStyle.fill = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
            applyNewStyleForTextBox(obj, newStyle);
        }
        else shape.set('stroke', `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);

        state.target.onShapeChanged();
        obj.onShapeChanged();
        canvas.renderAll();
    }

    const setTextColor = (obj, color) => {
        try {
            color = colorOpacityHandler('text', color);
            dispatch({
                type: TOOLBAR_DISPACH_ACTIONS.CHANGE_COLOR,
                value: color.rgb,
                colorType: 'text'
            });

            // Apply formatting to html editor instead of fabricjs if the shape in editing mode.
            if (state?.target?.isHtmlEditingMode) {
                HtmlEditorHelper.format('color', `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);

                // For edit mode, no need to send updates. For read mode, we need to send updates here.
                if (state.target.htmlMode === 'edit') {
                    obj.isTextColorApplied = true;
                    state.target.onShapeChanged();
                    obj.onShapeChanged();
                    canvas.renderAll(); 
                    return
                }
            }

            if (obj.type === 'group') {
                const textboxObject = getTextShapeFromGroup(obj)
                const textboxToApplyColor = textboxObject
                if (!textboxToApplyColor) {
                    return
                }

                let newStyle = {};
                let oldColor = getSelectionTextColorFromText(textboxToApplyColor)?.textColor;
                newStyle.fill = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                const groupShape = obj._objects[0];
                const isApplied = applyNewStyleForTextBox(textboxToApplyColor, newStyle); // can be null or false
                if (isApplied !== false) {
                    obj.isTextColorApplied = true;
                }
                checkTextAndShapeContrast(newStyle.fill, groupShape?.fill, 'text', oldColor)

            } else {
                // for curved lines
                obj.set({
                    textColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                });
            }

            state.target.onShapeChanged();
            obj.onShapeChanged();
            canvas.renderAll(); 
        } catch (err) {
            console.err(err)
        }
    }

    const _updateRecentColor = (color) => {
        if (typeof color === 'object' && Number.isInteger(color.r) && Number.isInteger(color.g) && Number.isInteger(color.b)) {
            color = getRgbaAsString(color);
        }

        socketRef.current.emit(SOCKET_EVENT.COLOR_SELECTED, {
            wbId: whiteBoardId,
            color
        });

        reduxDispatch({
            type: 'board/appendColorToRecentColors',
            payload: { color }
        });
    }

    const updateRecentColor = useCallback(debounce(_updateRecentColor, 500), [whiteBoardId]);

    
    // checks both of the text and shape color
    // if the text is not readable, displays toast message
    const checkTextAndShapeContrast = (textColor, shapeColor, from, oldColor) => {
        if (shapeColor === 'transparent') {
            return
        }
        const checkingColor = from === 'text' ? textColor : shapeColor;
        if (checkingColor === oldColor) {
            return
        }
        const textBrightness = getBrightnessFromRGBA(textColor)
        const bgBrightness = getBrightnessFromRGBA(shapeColor)
        const contrast = Math.abs(textBrightness - bgBrightness)
        if (contrast < 50) {
            if (toast.isActive(hueToastRef.current)) {
                return
            }
            hueToastRef.current = toast.warning('Improve text legibility: Adjust shape background color or text color', {
                className: 'wb_toast',
                icon: null,
            })
        } 
    }

    /**
     * deletes the object
     * @param {boolean} value
     * @param {boolean} isFrame
     */
    const shapeRemove = (value, isFrame) => {
        if (!isUserHasAccessToFeature('remove_object', userAccess)) { return; }
        setTimeout(() => {
            if(isLocked) return;
            if (isFrame) state.target.set({ deleteOnlyMainFrame: value })

            canvas.fire('remove-object', { activeObject: state.target, isOnlyFrame: value, stackOrders: getStackOrderFromCanvas(canvas) });
        }, 0);
    }

    const shapeClone = () => {
        if(isLocked) return;
        dispatch({
            type: TOOLBAR_DISPACH_ACTIONS.CLOSE_ALL_COLOR_PICKERS,
        })
        if (state.target.type === 'activeSelection') {
            // this process is required for cloning
            // otherwise it sets incorrect position to the cloned object
            const activeObject = canvas.getActiveObject();
            canvas.discardActiveObject().requestRenderAll();
            removeAllExistedLasso(canvas);
            canvas.fire('duplicate-object', activeObject);
        } else {
            canvas.fire('duplicate-object', state.target);
        }
    };

    const getCurrentTextStyle = (shape) =>{
        let selectionStart, selectionEnd;
        if (shape.hiddenTextarea) {
            selectionStart = shape.selectionStart;
            selectionEnd = shape.selectionEnd;
        } else {
            selectionStart = 0;
            selectionEnd = shape.text.length;
        }

        return shape.getSelectionStyles(selectionStart, selectionEnd);
    }

    const toggleStyles = (type) => {
        if(isLocked) return;
        let shape, sendUpdates = true;
        if (state.target.type === 'frame') return;

        // Apply formatting to html editor instead of fabricjs if the shape in editing mode.

        if (state?.target?.isHtmlEditingMode) {
            HtmlEditorHelper.format(type);

            // For edit mode, no need to send updates. For read mode, we need to send updates here.
            if (state.target.htmlMode === 'edit') return;
        }
        
        // get the textbox that will be modified
        if (state?.target?.type === 'textbox') {
            shape = state.target;
        } else if (state?.target?.type === 'group') {
            shape = state?.target?._objects[1];
        } else if (state?.target?.type === 'table') {
            if (state.target.editingTextbox) {
                shape = state.target.editingTextbox;

                // since the textbox is not an actual textbox of this group, we don't want to 
                // send updates immediately. Sending updates is being handled by the text's editing:exited event
                sendUpdates = false;
            } else if (state.target.isCellSelected && sendUpdates) {
                const cell = state.target.getSelectedCell();
                shape = cell.renderedText;
            }
        }

        // if the shape is not textbox, return null
        if (!shape || !['textbox', 'cellText'].includes(shape.type)) {
            console.error('textbox couldnt found');
            return;
        }

        // get new style
        let styleType = type;
        const activeFontStyle = getCurrentTextStyle(shape);

        let newStyle = {};
        const backupStyle = {}; // in case the process is aborted...
        switch (styleType) {
            case 'fontWeight': {
                const isBold = activeFontStyle.some(style => style.fontWeight === 'bold');
                newStyle.fontWeight = isBold ? 'normal' : 'bold';
                backupStyle.fontWeight = isBold ? 'bold' : 'normal';
                break;
            } case 'fontStyle': {
                const isItalic = activeFontStyle.some(style => style.fontStyle === 'italic');
                newStyle.fontStyle = isItalic ? 'normal' : 'italic';
                backupStyle.fontStyle = isItalic ? 'italic' : 'normal';
                break;
            } case 'underline': {
                const isUnderline = activeFontStyle.some(style => style.underline === true);
                newStyle.underline = isUnderline ? false : true;
                backupStyle.underline = isUnderline ? true : false;
                break;
            } default:
                console.log('Invalid typeValue');
        }
        let blockProcessId, isContinuous = false;
        if (!shape.editFor && !shape.editingStarted && (!state.target.isHtmlEditingMode || (state.target.isHtmlEditingMode && state.target?.htmlMode !== 'edit'))) {
            const { processId, aborted } = canvas.collaborationManager.startEditing(
                [state.target], 
                canvas.pageId, 
                EDITING_METHODS.TEXT_FONT_STYLE);
            if (aborted) {
                return;
            }
            blockProcessId = processId;
        } else {
            isContinuous = true;
            blockProcessId = target.editingProcessId;
            canvas.collaborationManager.addEditingMethod(blockProcessId, EDITING_METHODS.TEXT_FONT_STYLE)
        }
        
        // apply new changes to the textbox
        applyNewStyleForTextBox(shape, newStyle);

        if (state.target.type === 'table' && !state.target.editingTextbox) {
            state.target.changeCellStyles(shape.styles, true);
        }
        // if the textbox belongs to a group, dirty property should be set to true
        // because it will not be updated otherwise
        // and we don't want to call addWithUpdate 
        if (shape.group) {
            shape.group.dirty = true;
        }
        state.target.onShapeChanged();
        canvas.renderAll();
        
        if (isContinuous) {
            canvas.collaborationManager.updateContinuousEditing(
                blockProcessId
            )
        }
        if (blockProcessId && !isContinuous) {
            canvas.collaborationManager.commitEditing(blockProcessId, canvas.pageId)
        }

        // Update the activity log and other components if needed.
        canvas.fire('modified-with-event', {target: state.target});
        return;
    };

    /**
     * Adding hyperlink to the selection text or whole textbox.
     */
    const addHyperlink = (hrefLink) => {
        if (!hrefLink) return;
        // since the textbox is not an actual textbox of this group, we don't want to 
        // send updates immediately. Sending updates is being handled by the text's editing:exited event
        let isContinuous = false;
        let blockProcessId = '';
        let shape = state?.target;

        // First remove the all spaces.
        let link = hrefLink.replaceAll(/\s+/g, '');

        // If value doesn't start with http or https then add it.
        if (!link.startsWith('http://') && !link.startsWith('https://')) {
            link = 'https://' + link;
        }


        // Both for read and edit mode.
        if (state?.target?.isHtmlEditingMode) {
            HtmlEditorHelper.format('link', link);
        }
        
        if (state.target.type === 'textbox' && state.target.editingStarted) {
            blockProcessId = target.editingProcessId;
            canvas.collaborationManager.addEditingMethod(
                blockProcessId,
                EDITING_METHODS.TEXT_HYPERLINK
            )
            isContinuous = true;
        }

        // Apply formatting to html editor instead of fabricjs if the shape in editing mode.
        if (state?.target?.isHtmlEditingMode && state.target.htmlMode === 'edit') {
            blockProcessId = target.editingProcessId;
            canvas.collaborationManager.addEditingMethod(
                blockProcessId,
                EDITING_METHODS.TEXT_HYPERLINK
            )
            
            if (state.target.get('hasHyperlink') !== true) {
                state.target.set({ hasHyperlink: true });
            }

            isContinuous = true;
        } else {
            // Only groups and textbox objects supports hyperlinks.
            if (!['group', 'textbox', 'table'].includes(shape.type)) { return; }

            // If the object type is group, we need to get the textbox object inside of it.
            if (shape.type !== 'textbox') {
                shape.dirty = true;

                if (state?.target?.isHtmlEditingMode && state.target.htmlMode === 'read') {
                    shape = shape?.getObjects()[1];
                } else if (shape?.type === 'group') {
                    shape = shape?.getObjects()[1];
                } else if (shape.type === 'table' && shape.editingTextbox) {
                    shape = shape.editingTextbox;
                } else if (shape.type === 'table' && shape.isCellSelected) {
                    const cell = state.target.getSelectedCell();
                    shape = cell.renderedText;
                } else if (shape.type === 'table') {
                    return;
                }

                shape.dirty = true;
            }

            const selectionStart = shape.hiddenTextarea ? shape.selectionStart : 0;
            const selectionEnd = shape.hiddenTextarea ? shape.selectionEnd : shape.text.length;

            // if there is no selection, return null
            if (selectionStart === selectionEnd) {
                console.error('select text to add hyperlink');
                return;
            }

            if ((!shape.isEditing || !shape.editFor) && !shape.editingStarted && (!state.target.isHtmlEditingMode || (state.target.isHtmlEditingMode && state.target?.htmlMode !== 'edit'))) {
                target.onShapeChanged()
                const { processId, aborted } = canvas.collaborationManager.startEditing(
                    [state.target],
                    canvas.pageId,
                    EDITING_METHODS.TEXT_HYPERLINK
                );
                if (aborted) {
                    return;
                }
                blockProcessId = processId;
            }

            // Adding hyperlink style to the selection
            shape.setSelectionStyles({
                underline: true,
                fill: 'blue',
                isHyperlink: true,
                url: link
            }, selectionStart, selectionEnd);

            shape.set({ hasHyperlink: true });

            // Update the cell and cellText in case of editingTextbox is not exists. If exists, we updated on editing:exited event.
            if (state.target.type === 'table' && !state.target.editingTextbox) {
                state.target.changeCellStyles(shape.styles, true);
            }

            // There is a control in mouse:move listener, we first check the objects hasHyperlink into them.
            // So, if the textbox linked to a group, then we need to add hasHyperlink flag to the group object as well.
            // Therefore we can check the object has hyperlink into it before all operations.
            if (shape.group) {
                shape.group.set({ hasHyperlink: true });
            } else if (state.target.type === 'table') {
                state.target.set({ hasHyperlink: true });
            }
        }

        if (!isContinuous) {
            canvas.collaborationManager.commitEditing(blockProcessId, canvas.pageId);
        } else {
            canvas.collaborationManager.updateContinuousEditing(blockProcessId)
        }


        canvas.renderAll();

        dispatch({
            type: TOOLBAR_DISPACH_ACTIONS.TOGGLE_MENU,
            menu: null
        });

        // Update the activity log and other components if needed.
        canvas.fire('modified-with-event', {target: state.target})
    }

    const textAlign = (value) => {
        if(isLocked) return;
        let blockProcessId;
        let shape;

        if (state.target?.isHtmlEditingMode) {
            HtmlEditorHelper.format('align', value === 'left' ? '' : value, true);
        }

        if (state.target.type === 'group') {
            shape = state.target._objects[1];
        } else if (state.target.type === 'table' && state.target.editingTextbox) {
            shape = state.target.editingTextbox;
        } else if (state.target.type !== 'group') {
            shape = state.target;
        }

        let isContinuous = false;
        const shouldSet = state.target.type !== 'table' || (state.target.type === 'table' && state.target.editingTextbox);
        if (shouldSet) {
            // editFor means, the shape is a part of a group , stickyNote, shape and textbox
            // editingStarted checks if the textbox object that is added by user is already being edited or not.
            if (!shape.editFor && !shape.editingStarted && (!state.target.isHtmlEditingMode || (state.target.isHtmlEditingMode && state.target?.htmlMode !== 'edit'))) {
                const { processId, aborted } = canvas.collaborationManager.startEditing([state.target], canvas.pageId, EDITING_METHODS.TEXT_ALIGN)
                if (aborted) {
                    return
                }
                blockProcessId = processId;
                shape.set('textAlign', value);
            } else {
                // no need to start editing text here. It's handled by editing text box.
                canvas.collaborationManager.addEditingMethod(
                    target.editingProcessId,
                    EDITING_METHODS.TEXT_ALIGN
                )
                blockProcessId = target.editingProcessId
                isContinuous = true;

                shape.set('textAlign', value);
                shape.fire('updateOriginalCell')
            }
        } else if (state.target.isCellSelected) {
            const selectedCell = state?.target?.getSelectedCell();
            if (!selectedCell) {
                return
            }

            const { processId, aborted } = canvas.collaborationManager.startEditing([state.target], canvas.pageId, EDITING_METHODS.TEXT_ALIGN)
            if (aborted) {
                return;
            }
            
            blockProcessId = processId;
            
            state.target.changeCellStyles({ textAlign: value });
        }

        state.target.onShapeChanged();
        eventEmitter.fire(EMITTER_TYPES.HTML_EDITOR_STYLE_UPDATED, {});
        canvas.renderAll();
        if (blockProcessId && !isContinuous) {
            canvas.collaborationManager.commitEditing(blockProcessId, canvas.pageId)
        } else if (isContinuous) {
            canvas.collaborationManager.updateContinuousEditing(blockProcessId)
        }
        // Update the activity log and other components if needed.
        canvas.fire('modified-with-event', {target: state.target});
    };

    const toggleDashed = (obj, type, abortFns) => {
        if (isLocked) return;
        let isUpdated = false;
        const oldStrokeDashArray = obj.strokeDashArray;

        if (type !== null && type !== undefined) {
            if (type === true && !(obj.strokeDashArray && obj.strokeDashArray?.length) ) {
                obj.set('strokeDashArray', [5, 5]);
                isUpdated = true;
            } else if (type === false && (obj.strokeDashArray && obj.strokeDashArray?.length)) {
                obj.set('strokeDashArray', null);
                isUpdated = true;
            }
        } else {
            isUpdated = true;
            if (obj.strokeDashArray && obj.strokeDashArray.length) obj.set('strokeDashArray', null);
            else obj.set({
                'strokeDashArray': [5, 5],
                // 'stroke': 'black' // I have no idea why this is needed but it changes the line color to black
            });
        }
        
        abortFns.push(() => {
            obj.onShapeChanged();
            obj.set('strokeDashArray', oldStrokeDashArray);
            dispatch({
                type: TOOLBAR_DISPACH_ACTIONS.TOGGLE_MENU,
                menu: TOOLBAR_MENU.LINE_TYPE
            })
        })

        return isUpdated;
    };

    const changeThickness = (val) => {
        if (isLocked) return;
        setThicknessVal(val);

        // Update the activity log and other components if needed.
        canvas.fire('modified-with-event', {target: state.target});
    };

    const changeLineType = (obj, type, abortFns) => {
        if (isLocked) return;
        // not allow type changing to stepper
        if (type !== LINE_TYPES.STRAIGHT && type !== LINE_TYPES.CURVED) return;
        try {
            const oldType = obj.lineType;
            obj.lineType = type;

            if (isCurvedLineDrawingAsStraight(obj)) {
                obj.curvedLineVersion = 'v2';
            }

            if (state?.target?.type !== 'activeSelection') {
                obj.calculateBoundingBoxForCurvedLine(); // this is required to calculating dimensions.
            }
            state.target.onShapeChanged();
            obj.onShapeChanged();
            state.target.canvas.renderAll();

            // call organize controls on next loop
            setTimeout(() => {
                obj.organizeControls();
                state.target.canvas.renderAll();
            }, 0);
            dispatch({
                type: TOOLBAR_DISPACH_ACTIONS.TOGGLE_MENU,
                menu: TOOLBAR_MENU.LINE_TYPE
            })

            reduxDispatch({
                type: 'board/changeLineType',
                payload: { defaultLineType: type }
            });
            
            abortFns.push(() => {
                obj.onShapeChanged();
                obj.lineType = oldType;
                if (state?.target?.type !== 'activeSelection') {
                    obj.calculateBoundingBoxForCurvedLine(); // this is required to calculating dimensions.
                }
                setTimeout(() => {
                    obj.organizeControls();
                    state.target.canvas.renderAll();
                }, 0);
                dispatch({
                    type: TOOLBAR_DISPACH_ACTIONS.TOGGLE_MENU,
                    menu: TOOLBAR_MENU.LINE_TYPE
                })
            })
        } catch (err) {
            console.error('error while changing line type', err);
        }
    }

    const lockSingleTarget = (target, desiringLockState = null, options = {}) => {
        // if the textbox is on editing mode, exit editing mode
        if (target.shapeType === 'textbox') {
            if (target.isEditing) target.exitEditing();
        }

        // get the desiring lock state
        let lockedState = desiringLockState;
        if (lockedState === null) {
            lockedState = !target.lockMovementX;
        }

        if (target.isHtmlEditingMode && lockedState) {
            eventEmitter.fire(EMITTER_TYPES.CLOSE_HTML_EDITOR);
        } else if (target.type === 'group' && canvas.getActiveObject()?.uuid === target?.uuid) {
            openShapeEditor(target, canvas, { isEdit: false, actionFrom: 'lock' })
        }

        // lock the object and arrange the controls, borders, and movements
        modifyObjectPropertiesForLocking(target, lockedState);
        if (options?.shouldRender) {
            canvas?.renderAll();
        }
        return true;
    }


    const lockFrameObjects = (target, affectedObjects) => {
        if (target.type !== 'frame') { return; }
        if (!Array.isArray(target.attachments)) { return; }
        if (target.attachments.length === 0) { return; }

        const frameObjects = canvas.getObjects().filter((o) => target.attachments.includes(o.uuid));

        for (const frameObject of frameObjects) {
            frameObject.selectable = !isTargetLocked(target);

            if (lockSingleTarget(frameObject, isTargetLocked(target))) {
                affectedObjects.push(frameObject);
            }

            if (frameObject.type === 'frame') {
                const nestedFrameObjects = canvas.getObjects().filter(o => o.attachedFrameId === frameObject.uuid);
                for (const nestedFrameObject of nestedFrameObjects) {
                    nestedFrameObject.selectable = !isTargetLocked(frameObject);

                    if (lockSingleTarget(nestedFrameObject, isTargetLocked(frameObject))) {
                        affectedObjects.push(nestedFrameObject);
                    }
                }
            }
        }
    }



    const lockTarget = () => {
        resetDeleteDropdownActiveState();
        const affectedObjects = [];
        if (state?.target?.type === 'activeSelection') {
            const targetObjects = state.target.getObjects()?.filter(shape => shape?.uuid && shape?.type !== 'lasso');
            // get locked state of all objects
            const lockedObjectsMap = targetObjects.map(obj => obj.lockMovementX && obj.lockMovementY);
            const isAllLocked = lockedObjectsMap.every(locked => locked);
            const isAllUnlocked = lockedObjectsMap.every(locked => !locked);

            // find the desiring lock state
            let desiringLockState;
            if (isAllLocked) {
                desiringLockState = false;
            } else if (isAllUnlocked) {
                desiringLockState = true;
            } else {
                // if there is a mix of locked and unlocked objects, desired action should be unlocked
                desiringLockState = false;
            }

            // Set the editing map
            const editingMap = new Map();
            const tableUuids = targetObjects
                .filter((obj) => obj.type === 'frame')
                .map((table) => table?.uuid);

            targetObjects.forEach((obj) => {
                let method = EDITING_METHODS.LOCK;

                if (obj.attachedFrameId && tableUuids.includes(obj.attachedFrameId)) {
                    method = EDITING_METHODS.LOCK_AVOID_FOOTSTEP;
                }

                editingMap.set(obj, new Set([method]));
            })
            
            const { processId, aborted } = canvas.collaborationManager.startIsolatedEditing(
                targetObjects,
                canvas.pageId,
                editingMap
            )
            if (aborted) {
                return;
            }

            if (lockSingleTarget(state.target, desiringLockState)) {
                state.target.isMixedLockState = false;
                state.target.isAllLocked = desiringLockState;
            }

            const selectionFrameObjects = state.target.getObjects().filter((o) => o.type === 'frame');
            if (state?.target && typeof state.target.getObjects === 'function') {
                const frameIds = [];
                for (const object of targetObjects) {
                    // Don't lock/unlock the objects which are attached to a frame. They will be locked/unlocked by frame.
                    // However, if the objects linked to frame and if frame didn't selected, then the objects should be locked/unlocked now.
                    if (
                        !object.attachedFrameId ||
                        (object.attachedFrameId && !selectionFrameObjects.some((o) => o.uuid === object.attachedFrameId))
                    ) {
                        lockSingleTarget(object, desiringLockState);
                        affectedObjects.push(object);
                    }
    
                    if (object.type === 'frame') {
                        frameIds.push(object.uuid);
                        lockFrameObjects(object, affectedObjects);
                    }
                }
                showFrameObjectsHiddenAffectedToast(canvas, frameIds);
            }

            // close all menus
            dispatch({
                type: TOOLBAR_DISPACH_ACTIONS.TOGGLE_MENU,
                menu: null
            });
            canvas.collaborationManager.commitEditing(processId, canvas.pageId);

            canvas?.renderAll();
        } else {
            // If frame is locked, frame objects cannot be locked / unlocked.
            // New Upgrades: Subtoolbar will not show when locked frame object selected. So we expect below condition won't be true anytime.
            // I don't remove it though because there may a flow to trigger this function, and this is another step that we prevent to lock/unlock of frame object.
            if (state.target.attachedFrameId) {
                const frame = canvas.getObjects().find((o) => o.uuid === state.target.attachedFrameId);
                if (frame && isTargetLocked(frame)) {
                    return;
                }
            }

            let isContinuous = false;
            let blockProcessId;
            if (state.target.type === 'textbox' && state.target.uuid && state.target.editingStarted) {
                isContinuous = true;
                blockProcessId = state.target.editingProcessId;
            } else if (state.target.type === 'group' && state.target.editingProcessId) {
                isContinuous = true;
                blockProcessId = state.target.editingProcessId;
            }

            // close all menus
            dispatch({
                type: TOOLBAR_DISPACH_ACTIONS.TOGGLE_MENU,
                menu: null
            });
            
            if (isContinuous) {
                // if the process is continuous, we can add new editing method
                canvas.collaborationManager.addEditingMethod(
                    blockProcessId,
                    EDITING_METHODS.LOCK,
                )
                const lockingState = target.type === 'textbox' ? !target._savedProps.lockMovementX : !target.lockMovementX;
                modifyObjectPropertiesForLocking(target, !target.isLocked);
                
                // here no need call updateContinuousEditing since it will be automatically called with exitEditing method.
                
                // textbox saves original props to revert it back, so we should override them as well.
                if (target.type === 'textbox') {
                    target._savedProps.lockMovementX = lockingState;
                    target._savedProps.lockMovementY = lockingState;
                    target.exitEditing()
                } else if (target.type === 'group' && target.isHtmlEditingMode && target.htmlMode === 'edit') {
                    eventEmitter.fire(EMITTER_TYPES.CLOSE_HTML_EDITOR);
                }
                canvas.renderAll()
                return
            }
            
            let isTargetFrame = state.target.type === 'frame';

            const objectsToLock = [state.target];
            if (isTargetFrame) {
                const canvasObjects = canvas.getObjects()
                canvasObjects.forEach(o => {
                    if (o.wiredFrame && o.wiredFrame.uuid === state.target.uuid) {
                        objectsToLock.push(o)
                        
                        if (o.type === 'frame') {
                            canvasObjects.forEach(nestedObject => {
                                if (nestedObject.wiredFrame && nestedObject.wiredFrame?.uuid === o.uuid) {
                                    objectsToLock.push(nestedObject)
                                }
                            })
                        }
                    }
                });
            }

            // Set the editing map
            const editingMap = new Map();
            objectsToLock.forEach((obj) => {
                const method = obj.uuid === state.target?.uuid ?
                    EDITING_METHODS.LOCK
                    : EDITING_METHODS.LOCK_AVOID_FOOTSTEP;

                editingMap.set(obj, new Set([method]));
            })
            
            const { processId, aborted } = canvas.collaborationManager.startIsolatedEditing(
                objectsToLock,
                canvas.pageId,
                editingMap
            )
            if (aborted) {
                return;
            }
            
            
            // lock the element
            if (lockSingleTarget(state.target)) {
                affectedObjects.push(state.target);
            }

            if (state.target.type === 'frame') {
                lockFrameObjects(state.target, affectedObjects);
                showFrameObjectsHiddenAffectedToast(canvas, [state.target.uuid]);
            }
            canvas.collaborationManager.commitEditing(processId, canvas.pageId);
            canvas?.renderAll();
        }

        // Update the activity log and other components if needed.
        canvas.fire('modified-with-event', {target: state.target});
    }

    /**
     * When an object locked programatically.
     * @param {{object: fabric.Object, force: boolean}} param0 
     */
    const onObjectLocked = ({ object, force }) => {
        lockSingleTarget(object, force, { shouldRender: true });
    }

    const textBoldListener = () => {
        try {
            toggleStyles('fontWeight', 'bold');
        } catch (err) {
            console.error('Error happened on text bold listener', err);
        }
    }

    const textUnderlineListener = () => {
        try {
            toggleStyles('underline', true);
        } catch (err) {
            console.error('Error happened on text underline listener', err);
        }
    }

    const textItalicListener = () => {
        try {
            toggleStyles('fontStyle', 'italic');
        } catch (err) {
            console.error('Error happened on text italic listener', err);
        }
    }

    useEffect(() => {
        if (canvas) {
            canvas.on('text-bold', textBoldListener);
            canvas.on('text-italic', textItalicListener);
            canvas.on('text-underline', textUnderlineListener);
            canvas.on('lock-object', onObjectLocked)

            return () => {
                canvas.off('text-bold', textBoldListener);
                canvas.off('text-italic', textItalicListener);
                canvas.off('text-underline', textUnderlineListener);
                canvas.off('lock-object', onObjectLocked)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas, state?.target]);

    /**
     * Changing the layer order of objects according to the direction.
     * @param {fabric.Object} item
     * @param {('front'|'back')} direction
     * @date 11.07.2023 - 15:06:28
     */
    const changeLayerStack = (item, direction) => {
        // Frames always should be in top of stack and comments should be in bottom. So we mustn't change them.
        const notAllowedItemTypes = ['frame', 'comment'];
        if (!item || notAllowedItemTypes.includes(item.type) || isLocked) { return; }
        changeZIndex(canvas, direction, item.uuid ? [item] : item._objects, activityHistory[activePageId]?.shapes);
        if (direction === 'front') {
            item.bringToFront();
        } else {
            item.sendToBack();
        }
    }

    /**
     * Handling the change of the layer order of objects according to the direction.
     * If any object's order changed after the processes, going to emit a socket event and updated the history.
     * @param {('front'|'back')} direction
     * @date 11.07.2023 - 15:17:40
     */
    const handleChangeLayerStack = (direction) => {
        state.target.onOrderChanged();
        let frameUuids = [];
        if (state.target?.type === 'activeSelection') {
            let objects = [];
            try {
                objects = state?.target?.getObjects()
                if (objects.some(o => o.collabLocked)) {
                    canvas.lockManager.showToast();
                    return
                }
            } catch (err) {
                console.error('error while checking objects to change layer stack')
            }
            frameUuids = objects.filter(o=>o.type === 'frame').map(o=>o.uuid);
        }
        
        changeLayerStack(state.target, direction);

        const items = state.target.uuid ? [state.target] : state.target._objects

        const updatedStacks = items.filter(f => f.type !== 'frame').map(i => {
            return { zIndex: i.zIndex, uuid: i.uuid }
        })

        canvas.fire('stack-updated', {
            callback: ({oldStackOrder,newStackOrder}) => canvas.fire('stackOrder-to-undo-stack', {
                stackOrder: newStackOrder,
                oldStackOrder
            }),
            updatedStacks
        });

        if(canvas && frameUuids.length){
            showFrameObjectsHiddenAffectedToast(canvas, frameUuids);
        }
    }

    const getTextboxAlignIcon = () => {
        if (state.target.type === 'table' && state.target.isCellSelected) {
            const cell = target.getSelectedCell();
            return `icon-align${cell?.textAlign}`
        }

        let textObj;
        if (state.target.type === 'textbox') textObj = state.target;
        else if (state.subType === 'textbox' && state.type !== 'table') textObj = state.target.getObjects()?.find(obj => obj.type === 'textbox');
        else if (state.target.type === 'table' && state.target.isCellSelected) textObj = target.getSelectedCell();
        else return ''

        return `icon-canvas-redesign-text-align-${textObj?.textAlign}`
    }

    const handleTextSizeChange = (val) => {
        const target = state.target;

        if (isLocked) return false;
        
        // we need this because we don't want to abort text font size change when the text is in editing mode for 
        // sticky notes, shapes and tables since it will be handled in on text editing exited method.
        let isContinuousEvent = false;
        
        if (target.type === 'table' && target.editingTextbox) {
            isContinuousEvent = true;
        } else if (state.subTargetObject) {
            isContinuousEvent = true;
        }

        if (target.isHtmlEditingMode && target.htmlMode === 'edit') {
            isContinuousEvent = true;
        }

        let processIdBlock = null;
        
        if (target.type === 'textbox' && target.editingStarted) {
            isContinuousEvent = true;
        }
        
        if (!isContinuousEvent) {
            const { processId, aborted } = canvas.collaborationManager.startEditing([state.target], canvas.pageId, EDITING_METHODS.TEXT_FONT_SIZE)
            processIdBlock = processId;
            if (aborted) {
                return 
            }
        } else {
            processIdBlock = target.editingProcessId;
            canvas.collaborationManager.addEditingMethod(
                processIdBlock,
                EDITING_METHODS.TEXT_FONT_SIZE
            )
        }

        target.onShapeChanged();
        if (isTargetLine(target)) {
            target.set({
                textFontSize: val
            });
            target._setPositionDimensions({});
        } else if (target.type === 'textbox') {
            changeTextSizeForTextBox(state.target, val);
            target.canvas.renderAll();
            target.renderCursorOrSelection()
        } else if (target.shapeType === 'sticky' ||
            target.shapeType === 'rect' ||
            target.shapeType === 'ellipse' ||
            target.shapeType === 'triangle' ||
            target.shapeType === 'rhombus' ||
            target.shapeType === 'parallelogram') {
            const isChangeSuccess = changeTextSizeForStickyAndShape(state.target, val);
            if (!isChangeSuccess) return false;
        } else if (target.type === 'table' && target.isCellSelected) {
            target.changeCellStyles({ fontSize: getExactFontSize(val) });
        }

        target.canvas.renderAll();
        clearTimeout(textSizeChangeTimerRef.current);
        if (!isContinuousEvent) {
            canvas.collaborationManager.commitEditing(processIdBlock, canvas.pageId)
        } else {
            canvas.collaborationManager.updateContinuousEditing(processIdBlock)
        }

        eventEmitter.fire(EMITTER_TYPES.HTML_EDITOR_STYLE_UPDATED, {});
        // Update the activity log and other components if needed.
        canvas.fire('modified-with-event', {target: state.target});
        return true;
    }

    const handleAddTextToLine = () => {
        if (isLocked) return;
        canvas.fire('open_text_editor', state.target)
    }

    const handleChangeArrowPosition = (object, direction, abortFns) => {
        const target = state.target;
        let isValidRequest = false;
        
        // backup for abortion
        const oldData = {
            arrowEnabled: object.arrowEnabled,
            arrowLeft: object.arrowLeft,
            arrowRight: object.arrowRight
        }

        object.set({arrowEnabled:true})
        if (target.isLocked || object.isLocked) return;
        if (direction === 'left') {
            object.set({
                arrowLeft: true,
                arrowRight: false
            });
            isValidRequest = true;
        } else if (direction === 'right') {
            object.set({
                arrowLeft: false,
                arrowRight: true,
            });
            isValidRequest = true;
        } else if (direction === 'both') {
            object.set({
                arrowLeft: true,
                arrowRight: true,
            });
            isValidRequest = true;
        } else if (direction === 'none') {
            object.set({
                arrowLeft: false,
                arrowRight: false,
            });
            isValidRequest = true;
        }

        if (isValidRequest) {
            target.onShapeChanged();
            object.onShapeChanged();
            target.canvas.renderAll();
            dispatch({
                type: TOOLBAR_DISPACH_ACTIONS.TOGGLE_MENU,
                menu: TOOLBAR_MENU.ARROW_TYPE
            })
            
            abortFns.push(() => {
                object.onShapeChanged();
                object.set({arrowEnabled: oldData.arrowEnabled})
                object.set({arrowLeft: oldData.arrowLeft})
                object.set({arrowRight: oldData.arrowRight})
            })
        }
    }

    const calculateToolbarPosition = useCallback(() => {
        if (state?.target) {
            const positionOfTargetObject = {
                left: 0,
                top: 0,
            }
            if (state?.target)  {
                const target = state.target;
                const boundingBox = target.getBoundingRect();
                positionOfTargetObject.left = boundingBox.left;
                positionOfTargetObject.top = boundingBox.top;

                if (target.type === 'table') {
                    let point = null;
    
                    if (target.isCellSelected) {
                        const selectedCell = target.getSelectedCell();
                        if (selectedCell) {
                            const distance = (20 * target.scaleX);
    
                            point = new fabric.Point(
                                selectedCell.cellCoords.tl.x + distance,
                                selectedCell.cellCoords.tl.y + distance
                            );
                        }
                    } else {
                        point = new fabric.Point(
                            target.aCoords.textTL.x,
                            target.aCoords.textTL.y
                        );
                    }
    
                    if (point && canvas) {
                        const points = fabric.util.transformPoint(point, canvas.viewportTransform);
                        positionOfTargetObject.left = points.x;
                        positionOfTargetObject.top = points.y;
                    }
                }

                if (positionOfTargetObject.left < 10) {
                    positionOfTargetObject.left = 11;
                } else if (positionOfTargetObject.left + toolbarRef.current?.offsetWidth + 10 > window.innerWidth) {
                    positionOfTargetObject.left = window.innerWidth - toolbarRef.current?.offsetWidth - 10;
                }
                if (positionOfTargetObject.top < 70) {
                    positionOfTargetObject.top = 80;
                } else if (positionOfTargetObject.top + toolbarRef.current?.offsetHeight + 10 > window.innerHeight) {
                    positionOfTargetObject.top = window.innerHeight - toolbarRef.current?.offsetHeight - 10;
                }
            }
            if (toolbarRef.current) {
                toolbarRef.current.style.left = `${positionOfTargetObject.left}px`;
                toolbarRef.current.style.top = `${positionOfTargetObject.top - 70}px`;
            }
        }
    }, [state?.target, toolbarRef.current]);

    // set position on every render
    useLayoutEffect(calculateToolbarPosition);

    useEffect(() => {
        if (!thicknessVal || !state.target) return;
        const target = state?.target;
        clearTimeout(thicknessTimerRef.current);
        target.onShapeChanged();

        let objects = [];

        if (state?.target?.type === 'activeSelection') {
            objects = state.target.getObjects();
        } else {
            objects.push(state.target);
        }
        
        if (!target.thicknessEditingStarted) {
            const { processId, aborted } = canvas.collaborationManager.startContinuousEditing(
                objects, 
                canvas.pageId, 
                EDITING_METHODS.LINE_THICKNESS
            )
            if (aborted) {
                return
            }
            canvas.collaborationManager.setAbortionListener(processId, () => {
                canvas.discardActiveObject().requestRenderAll()
                target.thicknessEditingProcessId = null
                target.thicknessEditingStarted = false;
            })
            target.thicknessEditingProcessId = processId;
            target.thicknessEditingStarted = true;
        }

        for (const object of objects) {
            if (!object.initialStrokeWidth) {
                object.initialStrokeWidth = object.strokeWidth;
            }
            object.set({
                'strokeWidth': thicknessVal
            });
        }
        canvas.collaborationManager.updateContinuousEditing(target.thicknessEditingProcessId)

        canvas.renderAll();
        thicknessTimerRef.current = setTimeout(() => {
            canvas.collaborationManager.completeContinuousEditing(target.thicknessEditingProcessId)
            target.thicknessEditingStarted = false;
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thicknessVal]);

    const checkCanShowTextSizeOption = (target) => {
        if (target.type === 'textbox') return true;
        if (target.type === 'table' && target.isCellSelected) return true;
        if (target.shapeType === 'sticky' ||
            target.shapeType === 'rect' ||
            target.shapeType === 'ellipse' ||
            target.shapeType === 'triangle' ||
            target.shapeType === 'rhombus' ||
            target.shapeType === 'parallelogram') {
            if (target._objects[1].text.length > 0) return true;
            if (target.isHtmlEditingMode && target.htmlMode === 'edit') return true;
        }
        return false;
    }

    const changeIconToggleColorBaseOnSelectedText = ({target}) =>{
        if (target && target.type ==='textbox' && target.hiddenTextarea) {
            let selectionStart = target.selectionStart;
            let selectionEnd = target.selectionEnd;
            if(selectionStart === selectionEnd){
                if(selectionStart > 0){
                    selectionStart --;
                }else {
                    selectionEnd ++;
                }
            }

            // else, the target is an independent textbox
            const {textHighlightColor, textColor} = getColorsOfTextWithSelection(selectionStart, selectionEnd, state.target);
            dispatch({type: TOOLBAR_DISPACH_ACTIONS.CHANGE_SELECTION_TEXT_BOX, colors: {
                textColor: textColor,
                textHighlightColor:textHighlightColor,
            }})
        } else if (target && target.type === 'group' && target.isHtmlEditingMode) {
            const selectionStyles = HtmlEditorHelper.getSelectionStyles();

            if (selectionStyles?.color) {
                let color = Array.isArray(selectionStyles?.color) ? selectionStyles.color.pop() : selectionStyles.color;

                // Normally, all colors getting as hex or rgba format. However, there is only one case that came as blue, adding hyperlink. Its setting as blue intentionally in that case, so convert it to hex manually.
                if (color === 'blue') {
                    color = '#0000ff';
                }

                dispatch({type: TOOLBAR_DISPACH_ACTIONS.CHANGE_SELECTION_EDIT_TEXT_BOX, colors: {
                    textColor: (color && color.startsWith('#')) ? hexToRGBA(color) : color
                }})
            }
        }
    }

    const onNewTextScaleApplied = (scale) =>{
        const newTarget = state.target.set({scaleX: scale, scaleY: scale});

        // TODO: Normally; No need to set the subtoolbar for all shapes in order to only update the font size. Should be fixed for other shapes in future.
        if (state.target.type !== 'group') {
            dispatch({
                type: TOOLBAR_DISPACH_ACTIONS.SET_TARGET,
                target: newTarget
            });
        }
    }

    const resetDeleteDropdownActiveState = () => {
        const deleteDropdownItems = document?.querySelectorAll('.deleteOptionsInner__item');
        if (deleteDropdownItems) {
            deleteDropdownItems.forEach(item => item?.classList?.remove('active'));
            deleteDropdownItems[0]?.classList?.add('active');
            document?.querySelector('.delete.deleteBlock.active')?.classList?.remove('active');
        }
    }

    const handleHoverDeleteItem = (event) => {
        const deleteItems = document.querySelectorAll('.deleteOptionsInner__item');
        deleteItems.forEach(item => {
            item.classList.remove('active');
        })
        event.target.classList.add('active');
    }

    const onDeleteItemClicked = () => {
        if (state?.target?.type !== 'frame' || state?.target?.attachments?.length === 0) {
            shapeRemove(false, false);
        } else {
            const deleteButton = document.querySelector('.delete.deleteBlock');

            if (deleteButton.className.includes('active')) {
                resetDeleteDropdownActiveState();
            } else {
                deleteButton.classList.add('active');
            }
        }
    };

    const changeShapeType = async (newShapeType) => {
        try {
            const target = state.target;

            // If target is null, don't continue.
            if (!target) return;
    
            // If target shape type is same as selected shape type, don't continue.
            if (newShapeType === target.shapeType) return;
    
            const subObjects = target._objects;
    
            // If target has text, then don't continue.
            if (subObjects[1].text !== '') return;
            
            const objectLinesMap = new Map();
            
            if (target.lines && Array.isArray(target.lines)) {
                canvas.getObjects().forEach(line => {
                    if (line.type !== 'curvedLine') return;
                    if (!target.lines.includes(line.uuid)) { return; }

                    if (line.leftPolygon && line.leftPolygon?.uuid === target.uuid) {
                        objectLinesMap.set(line, 'left')
                    }
                    if (line.rightPolygon && line.rightPolygon?.uuid === target.uuid) {
                        objectLinesMap.set(line, 'right')
                    }
                })
            }
            
            // set editing methods of shape and its lines
            const editingMap = new Map();
            editingMap.set(target, new Set([EDITING_METHODS.CHANGE_SHAPE_TYPE]))
            
            const lockingMap = new Map()
            const objectLines = Array.from(objectLinesMap.keys())
            for (const line of objectLines) {
                editingMap.set(line, new Set([
                    objectLinesMap.get(line) === 'left' ? EDITING_METHODS.LINE_POLYGON_LEFT_AVOID_FOOTSTEP : EDITING_METHODS.LINE_POLYGON_RIGHT_AVOID_FOOTSTEP
                ]))
                lockingMap.set(line, false) // we should not lines.
            }

            const { processId, aborted } = canvas.collaborationManager.startIsolatedEditing(
                [target, ...objectLines], 
                canvas.pageId, 
                editingMap,
                lockingMap
            )
            
            if (aborted) {
                return;
            }
    
            let prevConnectorPoints = (Array.isArray(target.lines) && target.lines.length > 0) ? {
                left: findPositionOfConnectorControl(target, 'connectorLeft'),
                right: findPositionOfConnectorControl(target, 'connectorRight'),
                top: findPositionOfConnectorControl(target, 'connectorTop'),
                bottom: findPositionOfConnectorControl(target, 'connectorBottom'),
            } : { left: Infinity, right: Infinity, top: Infinity, bottom: Infinity }
    
            const structuredObj = JSON.parse(JSON.stringify(target));
    
            structuredObj.objects[0].type = newShapeType;
            structuredObj.shapeType = newShapeType;
    
            if (newShapeType === 'ellipse') {
                structuredObj.objects[0].rx = target.width / 2;
                structuredObj.objects[0].ry = target.height / 2;
                structuredObj.objects[0].cornerSize = 7;
                structuredObj.objects[0].strokeUniform = true;
            } else if (newShapeType === 'rect' && target.shapeType === 'ellipse') {
                delete structuredObj.objects[0].rx;
                delete structuredObj.objects[0].ry;
            }
    
            if (Array.isArray(target.lines) && target.lines.length > 0) {
                structuredObj.lines = target.lines;
            }
    
            // Getting stack order before create new one.
            const stackOrders = getStackOrderFromCanvas(canvas);
            const stackOrder = getSingleObjectStackOrder(canvas, target.uuid, stackOrders);
    
            const object = await createInstanceForChangedShape({
                data: structuredObj,
                target,
                canvas: canvas
            });

            // Why: Triangle center point is different from the center point of shape. So need to calculate center point and  update it.
            if (newShapeType === 'triangle') {
                const textareaDims = getShapeTextareaDimensions(object);

                const pointOnLocal = object.toLocalPoint(
                    new fabric.Point(textareaDims.left, textareaDims.top),
                    object.originX,
                    object.originY,
                );

                const [, textboxObj] = object.getObjects();
                textboxObj.set({
                    top: pointOnLocal.y / state.target.scaleY,
                    width: textareaDims.width
                });
            } else {
                const textareaDims = getShapeTextareaDimensions(object);
                const [, textboxObj] = object.getObjects();

                const pointOnLocal = object.toLocalPoint(
                    new fabric.Point(object.left, object.top),
                    object.originX,
                    object.originY,
                );

                textboxObj.set({
                    top: pointOnLocal.y / state.target.scaleY,
                    width: textareaDims.width
                });
            }
    
            object.setCoords();
    
            // Correct the left and right polygons.
            if (Array.isArray(object.lines) && object.lines.length > 0) {
                const leftPoints = findPositionOfConnectorControl(object, 'connectorLeft');
                const rightPoints = findPositionOfConnectorControl(object, 'connectorRight');
                const topPoints = findPositionOfConnectorControl(object, 'connectorTop');
                const bottomPoints = findPositionOfConnectorControl(object, 'connectorBottom');

                objectLines.forEach((o) => {
                    let isPointsUpdated = false;
    
                    if (o.leftPolygon?.uuid === object.uuid) {
                        const points = deepClone(o.points);
                        const firstPoint = points[0];
    
                        // Left and Right Connector Positions
                        const isLeftConnectorPosChanged = Math.floor(leftPoints.x) !== Math.floor(firstPoint.x) && Math.floor(prevConnectorPoints.left?.x) === Math.floor(firstPoint.x);
                        const isRightConnectorPosChanged = Math.floor(rightPoints.x) !== Math.floor(firstPoint.x) && Math.floor(prevConnectorPoints.right?.x) === Math.floor(firstPoint.x);
    
                        // Top and Bottom Connector Positions
                        const isTopConnectorPosChanged = Math.floor(topPoints.x) !== Math.floor(firstPoint.x) && Math.floor(prevConnectorPoints.top?.y) === Math.floor(firstPoint.y);
                        const isBottomConnectorPosChanged = Math.floor(bottomPoints.x) !== Math.floor(firstPoint.x) && Math.floor(prevConnectorPoints.bottom?.y) === Math.floor(firstPoint.y);
    
                        if (isLeftConnectorPosChanged || isRightConnectorPosChanged) {
                            isPointsUpdated = true;
                            points[0].x = isLeftConnectorPosChanged ? leftPoints.x : rightPoints.x;
                            points[0].y = isLeftConnectorPosChanged ? leftPoints.y : rightPoints.y;
                        }
    
                        if (isTopConnectorPosChanged || isBottomConnectorPosChanged) {
                            isPointsUpdated = true;
                            points[0].x = isTopConnectorPosChanged ? topPoints.x : bottomPoints.x;
                        }
    
                        o.set({
                            leftPolygon: object,
                            points
                        });
                    }
    
                    if (o.rightPolygon?.uuid === object.uuid) {
                        const points = deepClone(o.points);
                        const lastPoint = points[points.length - 1];
    
                        // Left and Right Connector Positions
                        const isLeftConnectorPosChanged = Math.floor(leftPoints.x) !== Math.floor(lastPoint.x) && Math.floor(prevConnectorPoints.left?.x) === Math.floor(lastPoint.x);
                        const isRightConnectorPosChanged = Math.floor(rightPoints.x) !== Math.floor(lastPoint.x) && Math.floor(prevConnectorPoints.right?.x) === Math.floor(lastPoint.x);
    
                        // Top and Bottom Connector Positions
                        const isTopConnectorPosChanged = Math.floor(topPoints.x) !== Math.floor(lastPoint.x) && Math.floor(prevConnectorPoints.top?.y) === Math.floor(lastPoint.y);
                        const isBottomConnectorPosChanged = Math.floor(bottomPoints.x) !== Math.floor(lastPoint.x) && Math.floor(prevConnectorPoints.bottom?.y) === Math.floor(lastPoint.y);
    
                        if (isLeftConnectorPosChanged || isRightConnectorPosChanged) {
                            isPointsUpdated = true;
                            points[points.length - 1].x = isLeftConnectorPosChanged ? leftPoints.x : rightPoints.x;
                            points[points.length - 1].y = isLeftConnectorPosChanged ? leftPoints.y : rightPoints.y;
                        }
    
                        if (isTopConnectorPosChanged || isBottomConnectorPosChanged) {
                            isPointsUpdated = true;
                            points[points.length - 1].x = isTopConnectorPosChanged ? topPoints.x : bottomPoints.x;
                            points[points.length - 1].y = isTopConnectorPosChanged ? topPoints.y : bottomPoints.y;
                        }
    
                        o.set({
                            rightPolygon: object,
                            points,
                            zIndex: target.zIndex
                        });
                    }
    
                    if (o.leftPolygon?.uuid === object.uuid || o.rightPolygon?.uuid === object.uuid) {
                        if (isPointsUpdated) {
                            o._setPositionDimensions({});
                            o.setCoords();
    
                            o.reattachAfterModify = true;
                            detachControl(canvas, o);
                        }
                    }

                    o.onShapeChanged();
                    o.calculateBoundingBoxForCurvedLine();
                });
            }
    
            // Remove the original object.
            target.avoidEmittingOnRemove = true;
            canvas.remove(target);
    
            // Update object stack order
            setSingleObjectStack(canvas, object, stackOrder);
    
            // Attach to frame again, if the object is inside of the frame.
            if (object.attachedFrameId) {
                const frame = canvas.getObjects().find((o) => o.uuid === object.attachedFrameId);
    
                if (frame) {
                    attachToFrame(object, frame);
                }
            }
            
            object.zIndex = target.zIndex;
    
            // Set the object as active.
            canvas.setActiveObject(object);
            canvas.renderAll();

            // I know this seems a bit hacky. I change the initial state shape here because the original target
            // is removed, and we added new target... And here is the only place this happens
            const initialStates = canvas.collaborationManager.initialStates.get(processId);
            const initialState = initialStates.find(state => state.shape.uuid === object.uuid)
            initialState.shape = object;
            
            object.oldShapeType = target.shapeType
            canvas.collaborationManager.commitEditing(processId, canvas.pageId)
            canvas.fire('modified-with-event', { target: state.target });
        } catch (err) {
            console.error('Error occurred during changing shape type', err);
        }
    }
    
    const toggleMenu = (menu, options = {}) => {
        if (menu === 'CLOSE_ALL_COLOR_PICKERS') {
            dispatch({
                type: TOOLBAR_DISPACH_ACTIONS.CLOSE_ALL_COLOR_PICKERS
            });

            return;
        }

        if(menu === TOOLBAR_DISPACH_ACTIONS.CLOSE_AVAILABLE_LAYERS_LIST){
            dispatch({
                type: TOOLBAR_DISPACH_ACTIONS.CLOSE_AVAILABLE_LAYERS_LIST,
            });
            return;
        }

        if(menu === TOOLBAR_DISPACH_ACTIONS.SHOW_AVAILABLE_LAYERS_LIST){
            dispatch({
                type: TOOLBAR_DISPACH_ACTIONS.SHOW_AVAILABLE_LAYERS_LIST,
            });
            return;
        }

        if (menu === TOOLBAR_MENU.TOGGLE_COLOR_PICKER) {
            dispatch({
                type: TOOLBAR_DISPACH_ACTIONS.TOGGLE_COLOR_PICKER,
                colorType: options?.colorType,
                value: true,
                closeOthers: true,
                closeMenu: true
            })
        }

        dispatch({
            type: TOOLBAR_DISPACH_ACTIONS.TOGGLE_MENU,
            menu
        });
    }
    
    /**
     * fetches the image to download it
     * @param {url} imageUrl
     */
    const toDataURL = async (url) => {
        const blob = await fetch(url).then(res => res.blob());
        return URL.createObjectURL(blob);
    }
    
    const handleDownloadImage = async () => {
        if(isLocked || imageDownloading) return;
        await toast.dismiss();
        let isError = false;
        setImageDownloading(true);
        toast.info(
            <span className="downloadImage_toast__text">
                <div className="downloadImage_toast__loader">
                    <ChangingProgressProvider values={[0, 100]}>
                        {(percentage) => (
                            <CircularProgressbar
                                value={percentage}
                                styles={buildStyles({
                                    pathTransition: percentage === 0 ? 'none' : 'stroke-dashoffset 1.2s ease 0s',
                                    rotation: 0.5 + (1 - percentage / 100) / 2,
                                    pathColor: '#6200EA',
                                    trailColor: '#B388FF4D',
                                    strokeWidth: 20
                                })}
                            />
                        )}
                    </ChangingProgressProvider>
                </div>
                <span className="downloadImage_toast__text--main">Downloading <span>1 image</span></span>
            </span>, {
                icon: false,
                autoClose: false,
                className: 'wb_toast wb_toast__downloadImage-loading',
                draggable: false,
                toastId: `downloadImage-loading-${state.target.uuid}-${Math.random()}`
            })
        try{
            const a = document.createElement('a');
            const imageUrl = state.target.createdWithLasso ? state.target._objects[0].imageData.full.url : state.target.imageData.full.url;
            a.href = await toDataURL(imageUrl);
            a.download = imageUrl.split('/').slice(-1)[0].split('-').slice(2).join('-');
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setImageDownloading(false);
            toast.dismiss();
            toast.success(
                <span className="downloadImage_toast__text">
                    <span className="downloadImage_toast__text--main"><span>1 image</span> downloaded successfully</span>
                </span>,
                {
                    icon: getToastIcon('success'),
                    autoClose: true,
                    className: 'wb_toast wb_toast__downloadImage-success',
                    draggable: false,
                    toastId: `downloadImage-success-${state.target.uuid}-${Math.random()}`
                })
        }
        catch(error){
            isError = true;
        }
        finally {
            if (isError) {
                setImageDownloading(false);
                toast.error('An error has occurred while downloading image. Please try again later!', {
                    icon: getToastIcon('error'),
                    className: 'wb_toast wb_toast__downloadImage',
                    toastId: `downloadImage-error-${state.target.uuid}-${Math.random()}`
                });
            }
        }
    }

    const handleOnChangeOfSubtoolbarItem = (item, args = {}) => {
        let objects = [];

        if (state?.target?.type === 'activeSelection') {
            objects = state.target.getObjects().filter(o => o.uuid && o.type !== 'lasso');
        } else {
            objects.push(state.target);
        }
        
        const abortFns = [];
        let isContinuousJob = false;
        let shouldCommitColorAfterUpdate = true;
        let blockProcessId;
        
        if (
            (item === SUBTOOLBAR_ITEMS.OUTER_COLOR.id) ||
            (item === SUBTOOLBAR_ITEMS.INNER_COLOR.id) ||
            (item === SUBTOOLBAR_ITEMS.LINE_TEXT_COLOR.id) ||
            (item === SUBTOOLBAR_ITEMS.TEXT_COLOR.id) ||
            (item === SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.id) 
        ) {
            isContinuousJob = true;

            if (state.target.type === 'textbox' && state.target.editingStarted) {
                shouldCommitColorAfterUpdate = false;
            } else if (state.subTargetObject) {
                shouldCommitColorAfterUpdate = false;
            } else if (args?.color?.isColorSelected) {
                isContinuousJob = false;
            }
        }

        if (!isContinuousJob) {
            const { processId, aborted } = canvas.collaborationManager.startEditing(objects, canvas.pageId, args.editingMethod);
            if (aborted) {
                return;
            }
            blockProcessId = processId;
        } else {
            if (!state.target.editingProcessId) {
                const { processId, aborted } = canvas.collaborationManager.startContinuousEditing(
                    objects,
                    canvas.pageId,
                    args.editingMethod
                )
                if (aborted) {
                    return
                }
                canvas.collaborationManager.setAbortionListener(processId, () => {
                    canvas.discardActiveObject().requestRenderAll()
                    state.target.editingProcessId = null
                })
                state.target.editingProcessId = processId
            } else if (state.target?.type !== 'activeSelection') {
                // we are in the editing mode for textbox. Add color editing mode as well.
                canvas.collaborationManager.addEditingMethod(
                    state.target.editingProcessId,
                    args.editingMethod
                )
            }
            blockProcessId = state.target.editingProcessId;
        }

        const toggleDashedObjects = [];
        for (const object of objects) {
            if (item === SUBTOOLBAR_ITEMS.OUTER_COLOR.id) {
                setOuterColor(object, args.color);
            } else if (item === SUBTOOLBAR_ITEMS.INNER_COLOR.id) {
                setInnerColor(object, args.color);
            } else if (item === SUBTOOLBAR_ITEMS.LINE_TEXT_COLOR.id) {
                setTextColor(object, args.color);
            } else if (item === SUBTOOLBAR_ITEMS.TEXT_COLOR.id) {
                setTextColor(object, args.color);
            } else if (item === SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.id) {
                setHighlightTextColor(object, args.color);
            } else if (item === SUBTOOLBAR_ITEMS.LINE_TYPE.id) {
                if (args.operation === 'changeLineType') {
                    changeLineType(object, args.type, abortFns)
                } else if (args.operation === 'toggleDashed') {
                    const isUpdated = toggleDashed(object, args.type, abortFns);
                    if (isUpdated) {
                        toggleDashedObjects.push(object);
                    }
                }
            } else if (item === SUBTOOLBAR_ITEMS.ARROW_TYPE.id) {
                handleChangeArrowPosition(object, args.direction, abortFns);
            }
        }
        
        if (isContinuousJob) {
            canvas.collaborationManager.updateContinuousEditing(state.target.editingProcessId)
        }

        
        if (args.operation === 'toggleDashed' && [
            SUBTOOLBAR_ITEMS.LINE_TYPE.id,
            SUBTOOLBAR_ITEMS.ARROW_TYPE.id,
        ].includes(item)) {
            for (const obj of toggleDashedObjects) {
                state.target.onShapeChanged();
                obj.onShapeChanged();
            }
            
            state.target.canvas.renderAll();
        } else if ([
            SUBTOOLBAR_ITEMS.OUTER_COLOR.id,
            SUBTOOLBAR_ITEMS.INNER_COLOR.id,
            SUBTOOLBAR_ITEMS.LINE_TEXT_COLOR.id,
            SUBTOOLBAR_ITEMS.TEXT_COLOR.id,
            SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.id
        ].includes(item)) {
            if (args.color?.isColorSelected) {
                toggleMenu('CLOSE_ALL_COLOR_PICKERS');
            } else if (shouldCommitColorAfterUpdate) {
                clearTimeout(colorChangeTimerRef.current)
                colorChangeTimerRef.current = setTimeout(() => {
                    canvas.collaborationManager.completeContinuousEditing(blockProcessId)
                    state.target.editingProcessId = null;
                }, 500)
            }
        }
        if (!isContinuousJob) {
            canvas.collaborationManager.commitEditing(blockProcessId, canvas.pageId)
        }

        canvas.fire('modified-with-event', { target: state.target });
    }

    const handleFilterSelection = ({ uuids }) => {
        if (!Array.isArray(uuids)) return;

        const objects = canvas.getObjects().filter((obj) => {
            return uuids.includes(obj.uuid);
        });

        canvas.discardActiveObject();
        createSelectionForObjects(canvas, objects, false, { createdBy: 'SUBTOOLBAR_FILTER' });

        toggleMenu(TOOLBAR_MENU.FILTERS_MENU);
        setIsFilterApplied(true);
    }

    const filters = useMemo(() => {
        let isLockedItemExist = false;
        const items = new Map();
        const target = state?.target;
        if (!target || target?.type !== 'activeSelection') {
            return {
                items,
                selectedObjectsCount: 0,
                itemsCount: 0,
                isLockedItemExist,
                multiSelectionType: ''
            };
        }

        const objects = target.getObjects()?.filter(o=>o.visible);
        let selectedObjectsCount = 0;

        objects.forEach((obj) => {
            if (obj.collabLocked) {
                return
            }
            // Step-1: Decide to type first.

            let type = null;
            const matchedTypes = {
                textbox: SUBTOOLBAR_FILTER_ITEMS.TEXT.id,
                path: SUBTOOLBAR_FILTER_ITEMS.PEN.id,
                curvedLine: SUBTOOLBAR_FILTER_ITEMS.CONNECTOR.id,
                frame: SUBTOOLBAR_FILTER_ITEMS.FRAME.id
            }

            if (obj.type === 'group') {
                type = obj.shapeType === 'sticky' ? SUBTOOLBAR_FILTER_ITEMS.STICKY.id : SUBTOOLBAR_FILTER_ITEMS.SHAPE.id;
            }  else if (matchedTypes[obj.type]) {
                type = matchedTypes[obj.type];
            }

            if (!type) return;

            // Step-2: Assign the values
            if (!items.has(type)) {
                items.set(type, { uuids: [], count: 0, type });
            }

            items.set(type, {
                uuids: [...items.get(type).uuids, obj.uuid],
                count: items.get(type).count + 1
            })

            if (isTargetLocked(obj)) {
                isLockedItemExist = true;
            }

            selectedObjectsCount+= 1;
        });

        return {
            items,
            selectedObjectsCount,
            itemsCount: items.size,
            isLockedItemExist,
            multiSelectionType: (
                items.size === 1 &&
                !shouldHideActionsButUnlock &&
                items.keys().next().value
            )
        };
    }, [isSelectionUpdated, shouldHideActionsButUnlock]);

    const onLayerSelectedHandler = (newLayerId) => {
        const target = state?.target;
        if (target && canvas) {
            let toastMessage = null;
            const nameOfNewLayer = layers.find((l) => l.uuid === newLayerId).name;
            const notIncludedShapeTypeSet = new Set(NOT_ALLOWED_SHAPE_FOR_LAYER);
            let affectedObjs = [];
            let checkFrameAttachedObj = false;
            if (target.type == 'activeSelection') {
                affectedObjs = target._objects?.filter((obj) => obj.shapeType && !notIncludedShapeTypeSet.has(obj.shapeType));
                toastMessage = `${affectedObjs.length} ${affectedObjs.length > 1 ? 'elements are' : 'element is'} added in layer`;
                checkFrameAttachedObj = target._objects?.some((obj) => obj.shapeType === 'frame');
            } else if (target?.type === 'frame') {
                affectedObjs = getFrameAttachedShapes(target, true)?.filter(obj=>!notIncludedShapeTypeSet.has( obj.shapeType)) ?? [];
                toastMessage = `${affectedObjs.length} ${affectedObjs.length > 1 ? 'elements are' : 'element is'} added in layer`;
                checkFrameAttachedObj = true;
            } else if (target.shapeType && !notIncludedShapeTypeSet.has(target.shapeType)) {
                const prevLayerName = layers.find((l) => l.uuid === (target.layerId)).name;
                toastMessage = `Layer updated from ${prevLayerName} to`;
                affectedObjs = [target];
            }else{
                // empty else
            }
            if(affectedObjs?.length){
                const { processId, aborted } = canvas.collaborationManager.startEditing(affectedObjs, canvas.pageId, EDITING_METHODS.CHANGE_SHAPE_LAYER);
                if (aborted) {
                    return;
                }
                const incLayerCountObjs = [];
                const decLayerCountObjs = [];
                if(checkFrameAttachedObj){
                    showFrameObjectsHiddenAffectedToast(canvas, affectedObjs.filter(o=>o.attachedFrameId).map(o=>o.attachedFrameId));
                }
                affectedObjs.forEach(o=>{
                    const oldLayerId = o.layerId;
                    if(oldLayerId !== newLayerId){                        
                        incLayerCountObjs.push({uuid:o.uuid, shapeType:o.shapeType, layerId: newLayerId});
                        decLayerCountObjs.push({uuid:o.uuid, shapeType:o.shapeType, layerId: oldLayerId});
                        o.set({layerId: newLayerId});
                    }
                });
                const commitSuccessCallback = () =>{
                    canvas.fire('update-shape-prev-state-layer', {shapeIds: new Set(affectedObjs.map(o=>o.uuid)), newLayerId});
                    eventEmitter.fire(EMITTER_TYPES.UPDATE_LAYERS_SHAPES_COUNT_AND_VISIBILITY,{addedObjects: incLayerCountObjs, removedObjects: decLayerCountObjs, action: 'countUpdate'});
                    dispatch({
                        type: TOOLBAR_DISPACH_ACTIONS.CHANGE_SHAPE_LAYER,
                        layerId: newLayerId
                    });
                    toast.dismiss(layerChangeSuccessToastId.current);
                    layerChangeSuccessToastId.current = toast.success(
                        <span className='content'>
                            {toastMessage}&nbsp;<strong>{nameOfNewLayer}</strong>
                        </span>,
                        {
                            icon: getToastIcon('success'),
                            autoClose: true,
                            className: 'wb_toast shape-layer-change-toast',
                            draggable: false,
                            toastId: `layer-change-success-${state.target.uuid}-${Math.random()}`,
                        }
                    );
                }
                canvas.collaborationManager.commitEditing(processId, canvas.pageId, {commitSuccessCallback, addToHistory: false});
            }
        }
    };

    /**
     * If hyperlink toolbar is closed and there is a hyperlink value, we need to remove the entered text.
     */
    useEffect(() => {
        if ((state?.menu?.showHyperlinkBox === false)) {
            eventEmitter.fire(EMITTER_TYPES.HYPERLINK);
        }
    }, [state?.menu?.showHyperlinkBox])

    /**
     * If target is changed, reset hyperlink value.
     */
    useEffect(() => {
        eventEmitter.fire(EMITTER_TYPES.HYPERLINK);
    }, [state?.target?.uuid]);

    useEffect(() => {
        if (canvas) {
            if (state.target && state.target.type ==='textbox') {
                if (isLocked) { state.target.set({ editable: false }); }
                else {
                    state.target.set({ editable: true });
                }
            }
            const subtoolbarShowListener = () => {
                const activeObject = canvas.getActiveObject();
                if(activeObject){
                    if((activeObject.type === 'activeSelection' && activeObject._objects.every(o=>!o.visible)) || (activeObject.shapeType && !activeObject.visible)){
                        canvas.discardActiveObject().requestRenderAll(); 
                        return;
                    }
                    setIsFilterApplied(false);
                    setIsSelectionUpdated(Math.random());
    
                    dispatch({
                        type: TOOLBAR_DISPACH_ACTIONS.SET_TARGET,
                        target: activeObject,
                    })
                }
            }
            const subtoolbarHideListener = () => {
                dispatch({
                    type: TOOLBAR_DISPACH_ACTIONS.HIDE,
                })
            }
            const subtoolbarRemoveListener = () => {
                dispatch({
                    type: TOOLBAR_DISPACH_ACTIONS.REMOVE,
                })
            }
            const zoomAndPanListener = () => {
                // update the target object while panning
                if (state?.target) {
                    calculateToolbarPosition();
                }
            }
            const subtoolbarObjectUpdateListener = ({ target }) => {
                if (state.hide === true) return;

                if (state.target?.type === 'activeSelection') {
                    const shape = state.shape?.group ?? state.shape;

                    if (shape?.uuid === target?.uuid) {
                        dispatch({
                            type: TOOLBAR_DISPACH_ACTIONS.UPDATE_COLORS,
                            target: shape
                        });
                    }
                } else if (state.target?.uuid === target?.uuid) {
                    dispatch({
                        type: TOOLBAR_DISPACH_ACTIONS.UPDATE_COLORS,
                        target: state.target
                    });
                }
            }
            const targetMovingListener = () => {
                isMoved = true;
                if (state?.target !== null) {
                    dispatch({
                        type: TOOLBAR_DISPACH_ACTIONS.HIDE,
                    })
                }
            }
            const mouseUpListener = ({target}) => {
                if (isMoved) {
                    dispatch({
                        type: TOOLBAR_DISPACH_ACTIONS.SHOW,
                    });
                    target.off('mouseup', mouseUpListener);
                    isMoved = false;
                } else {
                    changeIconToggleColorBaseOnSelectedText({target})
                }
            }
            
            // listen to the toolbar update
            const toolbarUpdateListener = () => {
                setIsSelectionUpdated(Math.random());
            }
            
            eventEmitter.on(EMITTER_TYPES.TOOLBAR_SHOW, subtoolbarShowListener);
            eventEmitter.on(EMITTER_TYPES.TOOLBAR_HIDE, subtoolbarHideListener);
            eventEmitter.on(EMITTER_TYPES.TOOLBAR_REMOVE, subtoolbarRemoveListener);
            eventEmitter.on(EMITTER_TYPES.TOOLBAR_UPDATE, toolbarUpdateListener);
            eventEmitter.on(EMITTER_TYPES.HTML_EDITOR_SELECTION_UPDATED, changeIconToggleColorBaseOnSelectedText);
            canvas.on('board:pan', zoomAndPanListener);
            canvas.on('board:zoom', zoomAndPanListener);
            canvas.on('new_text_scale', onNewTextScaleApplied);
            canvas.on('modified-with-event-socket', subtoolbarObjectUpdateListener);
            // if the target is curved line, show toolbar after drawing
            if (state?.target?.showToolbarAfterCreate) {
                isMoved = true;
                state.target.showToolbarAfterCreate = false;
            }

            if (state?.target) {
                state.target.on('moving', targetMovingListener)
                state.target.on('mouseup', mouseUpListener);
                state.target.on('mousedblclick', changeIconToggleColorBaseOnSelectedText);
            }

            return () => {
                eventEmitter.off(EMITTER_TYPES.TOOLBAR_SHOW, subtoolbarShowListener);
                eventEmitter.off(EMITTER_TYPES.TOOLBAR_HIDE, subtoolbarHideListener);
                eventEmitter.off(EMITTER_TYPES.TOOLBAR_REMOVE, subtoolbarRemoveListener);
                eventEmitter.off(EMITTER_TYPES.TOOLBAR_UPDATE, toolbarUpdateListener);
                eventEmitter.off(EMITTER_TYPES.HTML_EDITOR_SELECTION_UPDATED, changeIconToggleColorBaseOnSelectedText);
                canvas.off('board:pan', zoomAndPanListener);
                canvas.off('board:zoom', zoomAndPanListener);
                canvas.off('new_text_scale', onNewTextScaleApplied);
                canvas.off('modified-with-event-socket', subtoolbarObjectUpdateListener);
                if (state?.target) {
                    state.target.off('moving', targetMovingListener)
                    state.target.off('mouseup', mouseUpListener);
                    state.target.off('mousedblclick', changeIconToggleColorBaseOnSelectedText);
                }
            }
        }
    }, [canvas, state?.target, state?.hide])

    useEffect(() => {
        resetDeleteDropdownActiveState();
    }, [state.target]);

    useEffect(() => {
        const isUserHasAccess = isUserHasAccessToFeature('subtoolbar', userAccess)
        const isVisible = !(!state?.target || state.hide);

        reduxDispatch({
            type: 'ui/toggleSubtoolbarVisibility',
            payload: isVisible && isUserHasAccess
        });
    }, [state?.target, state?.hide, userAccess]);

    if (!state?.target || state.hide || !isUserHasAccessToFeature('subtoolbar', userAccess)) {
        return <div className="toolControls" hidden></div>
    }

    const target = state.target;

    return (
        <div className="toolControls" ref={toolbarRef} style={{position: 'absolute'}}>

            {/* ******** DELETE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.DELETE.id}
                targetType={target.type}
                targetAttachmentsLength={target.attachments?.length || 0}
                onClick={onDeleteItemClicked}
                onDisplay={() => !shouldHideActionsButUnlock}
                removeShape={shapeRemove}
                handleHoverDeleteItem={handleHoverDeleteItem}
                isDisabled={!isUserHasAccessToFeature('remove_object', userAccess) || isLocked}
            />

            {/* ******** LOCK / UNLOCK ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.LOCK.id}
                onDisplay={() => !!(target.type !== 'table' || (target.type === 'table' && !target.isCellSelected))}
                onClick={lockTarget}
                isLocked={isLocked}
            />
            
            {/* ******** DUPLICATE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.DUPLICATE.id}
                onDisplay={() => !shouldHideActionsButUnlock}
                onClick={shapeClone}
                isDisabled={!isUserHasAccessToFeature('remove_object', userAccess) || isLocked}
            />

            {/* ******* TAG LAYER ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.CHANGE_LAYER.id}
                onDisplay={() => !shouldHideActionsButUnlock && target.type !== 'curvedLine'}
                isDisabled={!isUserHasAccessToFeature('layers_tag', userAccess) || isLocked || state?.shapeLayerObj?.disableShowLayerListBtn}
                showMenu={state?.shapeLayerObj?.showLayerList}
                toggleMenu={toggleMenu}
                layerId={state?.shapeLayerObj?.layerId}
                onChange={onLayerSelectedHandler}
            />

            {/* ******** SEPERATOR ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.SEPERATOR.id}
                onDisplay={() => !!filters.multiSelectionType}
            />

            {/* ******* DOWNLOAD ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.DOWNLOAD.id}
                onDisplay={() => state.target.shapeType === 'optimizedImage' || (target.createdWithLasso && target._objects.length === 2 && target._objects[0].type === 'optimizedImage')}
                onClick={handleDownloadImage}
                isDisabled={isLocked}
                isLoading={imageDownloading}
            />

            {/* ******* CHANGE SHAPE TYPE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.CHANGE_SHAPE.id}
                onDisplay={() => !!(state.type === 'group' && Object.values(SHAPE_TYPES).includes(target.shapeType))}
                isDisabled={isLocked || isTargetHasText(target) || (target.isHtmlEditingMode && target.htmlMode === 'edit')}
                isLocked={isLocked}
                onClick={changeShapeType}
                isShapeHasTextOrEditMode={isTargetHasText(target)}
                shapeType={target?.shapeType}
                showMenu={state?.menu?.showChangeShape}
                toggleMenu={toggleMenu}
            />

            {/* ******* ALIGNMENT ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.ALIGNMENT.id}
                onDisplay={() => (state.subType === 'textbox' || target.type === 'textbox')}
                isDisabled={isLocked}
                onClick={textAlign}
                showMenu={state?.menu?.showTextAlign}
                toggleMenu={toggleMenu}
                getTextboxAlignIcon={getTextboxAlignIcon}
                getActiveTextAlign={() => getActiveTextAlign(state.target)}
            />

            {/* ******* FONT STYLE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.FONT_STYLE.id}
                onDisplay={() => (state.subType === 'textbox' || target.type === 'textbox')}
                isDisabled={isLocked}
                onClick={toggleStyles}
                showMenu={state?.menu?.showFontStyle}
                toggleMenu={toggleMenu}
                getActiveFontStyle={() => getActiveFontStyle(target)}
            />

            {/* ******* HYPERLINK ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.HYPERLINK.id}
                onDisplay={() => (state.subType === 'textbox' || target.type === 'textbox')}
                isDisabled={isLocked}
                onClick={addHyperlink}
                showMenu={state?.menu?.showHyperlinkBox}
                toggleMenu={toggleMenu}
            />

            {/* ******* LINE TYPE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.LINE_TYPE.id}
                onDisplay={() => {
                    return isTargetLine(target) || SUBTOOLBAR_ITEMS.LINE_TYPE.supportedMultiSelectionTypes.includes(filters.multiSelectionType)
                }}
                isDisabled={isLocked}
                showMenu={state?.menu?.showLineType}
                toggleMenu={toggleMenu}
                changeLineType={(type) => handleOnChangeOfSubtoolbarItem(SUBTOOLBAR_ITEMS.LINE_TYPE.id, { type, operation: 'changeLineType', editingMethod: EDITING_METHODS.LINE_TYPE })}
                changeThickness={changeThickness}
                toggleDashed={(type) => handleOnChangeOfSubtoolbarItem(SUBTOOLBAR_ITEMS.LINE_TYPE.id, { type, operation: 'toggleDashed', editingMethod: EDITING_METHODS.LINE_DASH })}
                strokeWidth={state?.shape?.strokeWidth}
                strokeDashArray={state?.shape?.strokeDashArray}
                lineType={state?.shape?.lineType}
            />

            {/* ******* ARROW TYPE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.ARROW_TYPE.id}
                onDisplay={() => {
                    return isTargetLine(target) || SUBTOOLBAR_ITEMS.ARROW_TYPE.supportedMultiSelectionTypes.includes(filters.multiSelectionType)
                }}
                isDisabled={isLocked}
                onClick={(direction) => handleOnChangeOfSubtoolbarItem(SUBTOOLBAR_ITEMS.ARROW_TYPE.id, { direction, editingMethod: EDITING_METHODS.LINE_ARROW_TYPE })}
                showMenu={state?.menu?.showArrowType}
                toggleMenu={toggleMenu}
                arrowLeft={state?.shape?.arrowLeft}
                arrowRight={state?.shape?.arrowRight}
            />
         
            {/* ******* ADD LINE TEXT TYPE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.ADD_LINE_TEXT.id}
                onDisplay={() => (isTargetLine(target) && !target.isTextEnabled())}
                isDisabled={isLocked}
                onClick={handleAddTextToLine}
            />

            {/* ******* LINE FONT SIZE TYPE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.LINE_FONT_SIZE.id}
                onDisplay={() => (isTargetLine(target) && target.isTextEnabled())}
                isDisabled={isLocked}
                onChange={handleTextSizeChange}
                textFontSize={target?.textFontSize}
                maximumSize={maximumSize}
            />

            {/* ******* LINE TEXT COLOR TYPE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.LINE_TEXT_COLOR.id}
                onDisplay={() => (isTargetLine(target) && target.isTextEnabled())}
                isDisabled={isLocked}
                showMenu={state?.textColorObj?.displayColorPicker}
                toggleMenu={toggleMenu}
                stateColor={getRgbaAsString(state?.textColorObj?.color)}
                recentColors={recentSelectedColors}
                setColor={(color) => handleOnChangeOfSubtoolbarItem(SUBTOOLBAR_ITEMS.LINE_TEXT_COLOR.id, { color, editingMethod: EDITING_METHODS.COLOR_TEXT })}
                updateRecentColor={updateRecentColor}
                targetType={target?.type}
            />

            {/* ******* TEXT SIZE TYPE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.FONT_SIZE.id}
                onDisplay={() => {
                    return (
                        !isTargetLine(target) &&
                        (target.shapeType && target.shapeType !== 'frame') &&
                        !isTargetImage(target) &&
                        (target.type !== 'table' || (target.type === 'table' && target.isCellSelected)) &&
                        checkCanShowTextSizeOption(target)
                    )
                }}
                isDisabled={isLocked}
                onChange={handleTextSizeChange}
                textFontSize={getCurrentTextSize(target)}
                maximumSize={maximumSize}
            />

            {/* ******* TEXT COLOR TYPE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.TEXT_COLOR.id}
                onDisplay={() => !!(
                    target.type === 'group' &&
                    (state.isThereTextInShapes || (state.target.isHtmlEditingMode && state.target.htmlMode === 'edit'))
                )}
                isDisabled={isLocked}
                showMenu={state?.textColorObj?.displayColorPicker}
                toggleMenu={toggleMenu}
                stateColor={getRgbaAsString(state?.textColorObj?.color)}
                recentColors={recentSelectedColors}
                setColor={(color) => handleOnChangeOfSubtoolbarItem(SUBTOOLBAR_ITEMS.TEXT_COLOR.id, { color, editingMethod: EDITING_METHODS.COLOR_TEXT })}
                updateRecentColor={updateRecentColor}
                targetType={target?.type}
            />

            {/* ******* OUTER COLOR TYPE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.OUTER_COLOR.id}
                onDisplay={() => {
                    const isNotLine = !isTargetLine(target);
                    const isNotFrame = (target.shapeType && target.shapeType !== 'frame');
                    const isNotImage = !isTargetImage(target);

                    const isDisplayed = (
                        isNotLine &&
                        isNotFrame &&
                        isNotImage &&
                        (target.type !== 'table' || (target.type === 'table' && target.isCellSelected)) &&
                        (target.type !== 'table')
                    )

                    return (
                        isDisplayed ||
                        SUBTOOLBAR_ITEMS.OUTER_COLOR.supportedMultiSelectionTypes.includes(filters.multiSelectionType)
                    );
                }}
                isDisabled={isLocked}
                showMenu={state?.outerColorObj?.displayColorPicker}
                toggleMenu={toggleMenu}
                stateColor={getRgbaAsString(state?.outerColorObj?.color)}
                recentColors={recentSelectedColors}
                setColor={(color) => handleOnChangeOfSubtoolbarItem(SUBTOOLBAR_ITEMS.OUTER_COLOR.id, { color, editingMethod: EDITING_METHODS.COLOR_OUTER })}
                updateRecentColor={updateRecentColor}
                targetType={target?.type === 'activeSelection' ? state?.shape?.type : target?.type}
            />

            {/* ******* HIGHLIGHT COLOR TYPE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.id}
                onDisplay={() => {
                    const isDisplayed = (
                        !isTargetLine(target) &&
                        (target.shapeType && target.shapeType !== 'frame') &&
                        !isTargetImage(target) &&
                        (target.type !== 'table' || (target.type === 'table' && target.isCellSelected)) &&
                        (target.type === 'textbox')
                    )

                    return (
                        isDisplayed ||
                        SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.supportedMultiSelectionTypes.includes(filters.multiSelectionType)
                    );
                }}
                isDisabled={isLocked}
                showMenu={state?.highlightTextColorObj?.displayColorPicker}
                toggleMenu={toggleMenu}
                stateColor={getRgbaAsString(state?.highlightTextColorObj?.color)}
                recentColors={recentSelectedColors}
                setColor={(color) => handleOnChangeOfSubtoolbarItem(SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.id, { color, editingMethod: EDITING_METHODS.COLOR_HIGHLIGHT })}
                updateRecentColor={updateRecentColor}
                targetType={target?.type === 'activeSelection' ? state?.shape?.type : target?.type}
            />

            {/* ******* INNER COLOR TYPE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.INNER_COLOR.id}
                onDisplay={() => {
                    const isDisplayed = (
                        target.type !== 'activeSelection' &&
                        !isTargetImage(target) &&
                        target.type !== 'table'
                    );

                    return (
                        isDisplayed ||
                        SUBTOOLBAR_ITEMS.INNER_COLOR.supportedMultiSelectionTypes.includes(filters.multiSelectionType)
                    );
                }}
                isDisabled={isLocked}
                showMenu={state?.innerColorObj?.displayColorPicker}
                toggleMenu={toggleMenu}
                stateColor={getRgbaAsString(state?.innerColorObj?.color)}
                recentColors={recentSelectedColors}
                setColor={(color) => handleOnChangeOfSubtoolbarItem(SUBTOOLBAR_ITEMS.INNER_COLOR.id, { color, editingMethod: EDITING_METHODS.COLOR_INNER })}
                updateRecentColor={updateRecentColor}
                targetType={target?.type === 'activeSelection' ? state?.shape?.type : target?.type}
                isTargetLine={isTargetLine(target?.type === 'activeSelection' ? state?.shape : target)}
            />            

            {/* ******* FILTERS ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.SEPERATOR.id}
                onDisplay={() => {
                    return (
                        target.type === 'activeSelection' &&
                        filters.items.size > 1 &&
                        isFilterApplied === false &&
                        !shouldHideActionsButUnlock
                    );
                }}
            />

            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.FILTERS.id}
                onDisplay={() => {
                    return (
                        target.type === 'activeSelection' &&
                        (filters.items.size > 1 || isFilterApplied) &&
                        !shouldHideActionsButUnlock
                    );
                }}
                isDisabled={isLocked}
                showMenu={state?.menu?.showFilters}
                toggleMenu={toggleMenu}
                filters={filters}
                onClick={handleFilterSelection}
                isFilterApplied={isFilterApplied}
            />

            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.SEPERATOR.id}
                onDisplay={() => {
                    return (
                        target.type === 'activeSelection' &&
                        (filters.items.size > 1 || isFilterApplied) &&
                        !shouldHideActionsButUnlock
                    );
                }}
            />

            {/* ******** SEPERATOR ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.SEPERATOR.id}
                onDisplay={() => !!filters.multiSelectionType}
            />

            {/* ******* MORE ********** */}
            <SubToolbarItem
                item={SUBTOOLBAR_ITEMS.MORE.id}
                onDisplay={() => {
                    return (
                        (target.type && !['frame', 'comment'].includes(target.type)) &&
                        !shouldHideActionsButUnlock
                    )
                }}
                isDisabled={isLocked}
                onClick={handleChangeLayerStack}
            />
        </div>
    );
}

SubToolbar.propTypes = {
    canvas: PropTypes.object,
    userAccess: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED']),
    socketRef: PropTypes.shape({
        current: PropTypes.object
    }).isRequired,
    whiteBoardId: PropTypes.number.isRequired
}

export default SubToolbar;
