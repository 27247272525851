import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ErrorBoundary from '../ErrorBoundary';
import RightDrawerErrorFallBack from './RightDrawerErrorFallback';
import { MODAL_WINDOWS, RIGHT_DRAWER_TITLES } from '../../helpers/Constant';
import DrawerPagesContent from './components/Pages';

// ASSETS
import CloseIcon from '../../assets/images/close-circle.svg';
import './RightDrawer.scss';

const RightDrawer = ({
    socketRef,
    canvas
}) => {
    const dispatch = useDispatch();

    const activeWindowState = useSelector((state) => state?.modal?.activeWindow);
    const contentType = useSelector((state) => state.rightDrawer.activeContentType);

    const closeDrawer = () => {
        dispatch({ type: 'modal/toggleRightDrawer', payload: {
            shouldShow: false
        }})
        dispatch({ type: 'rightDrawer/closeDrawer' });
    }

    return (
        <div className={clsx('rightDrawer', { '__visible': activeWindowState === MODAL_WINDOWS.RIGHT_DRAWER })} tabIndex={-1}>
            <ErrorBoundary fallback={<RightDrawerErrorFallBack />}>
                <div className="rightDrawer__title">
                    <span>{contentType ? RIGHT_DRAWER_TITLES[contentType] : null}</span>

                    <button type="button" className="rightDrawer__closeBtn" onClick={closeDrawer}>
                        <img src={CloseIcon} />
                    </button>
                </div>

                <div className="rightDrawer__content">
                    <DrawerPagesContent canvas={canvas} socketRef={socketRef} />
                </div>
            </ErrorBoundary>
        </div>
    )
}

RightDrawer.propTypes = {
    socketRef: PropTypes.shape({
        current: PropTypes.object
    }),
    canvas: PropTypes.object
}

export default RightDrawer;
