import { fabric } from 'fabric';
import { isInRange } from '../alignment/Utils';
import { generateAlignmentLines } from '../alignment/GenerateAlignmentLines';

/**
 * @param eventData
 * @param transform
 * @param x
 * @param y
 */
function commonEventInfo(eventData, transform, x, y) {
    return {
        e: eventData,
        transform: transform,
        pointer: {
            x: x,
            y: y,
        }
    };
}


// default drag handler
const defaultDragHandler = (eventData, transform, x, y) => {
    let target = transform.target,
        newLeft = x - transform.offsetX,
        newTop = y - transform.offsetY,
        moveX = !target.get('lockMovementX') && target.left !== newLeft,
        moveY = !target.get('lockMovementY') && target.top !== newTop;

    moveX && target.set('left', newLeft);
    moveY && target.set('top', newTop);
    if (moveX || moveY) {
        fabric.controlsUtils.fireEvent('moving', commonEventInfo(eventData, transform, x, y));
    }
    return moveX || moveY;
}

/**
 * Overrides the default dragHandler of fabricjs in order to add alignment lines while moving the object.
 * @override
 * @param {object} eventData 
 * @param {object} transform 
 * @param {number} x 
 * @param {number} y 
 */
export default function dragHandler(eventData, transform, x, y) {
    let target = transform.target,
        newLeft = x - transform.offsetX,
        newTop = y - transform.offsetY,
        moveX = !target.get('lockMovementX') && target.left !== newLeft,
        moveY = !target.get('lockMovementY') && target.top !== newTop;
    
    // if the target is locked in any direction, do not do anything
    if (target?.get('lockMovementX') || target?.get('lockMovementY')) {
        return false;
    }

    let snapLeft;
    let snapTop;
  
    try {
        const canvas = target.canvas;
        if (target?.type === 'comment' || !canvas) {
            return defaultDragHandler(eventData, transform, x, y);
        }
    
        const ctx = canvas.getSelectionContext();
        if (!ctx) return defaultDragHandler(eventData, transform, x, y);
  
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        const zoom = canvas.getZoom();
        const {
            verticalLines,
            horizontalLines
        } = generateAlignmentLines(canvas, target, ctx);
  
        if (verticalLines.length) {
            snapLeft = verticalLines[0].applyingPos;
        }
  
        if (horizontalLines.length) {
            snapTop = horizontalLines[0].applyingPos;
        }
  
        let setLeft = false, setTop = false;
        if (snapLeft && isInRange(newLeft, snapLeft, zoom).isInRange) {
            newLeft = snapLeft;
            setLeft = true
        }
    
        if (snapTop && isInRange(newTop, snapTop, zoom).isInRange) {
            newTop = snapTop;
            setTop = true;
        }
  
  
        moveX && target.set('left', newLeft);
        moveY && target.set('top', newTop);
        if (setLeft || setTop) {
            target.setPositionByOrigin(
                new fabric.Point(newLeft, newTop),
                target.originX,
                target.originY
            )
        }
        if (moveX || moveY) {
            fabric.controlsUtils.fireEvent('moving', commonEventInfo(eventData, transform, x, y));
        }
        canvas.off('mouse:up', mouseUpHandlerForRemovingLines);
        canvas.on('mouse:up', mouseUpHandlerForRemovingLines);
    } catch (err) {
        console.error('error in dragHandler', err)
        return defaultDragHandler(eventData, transform, x, y);
    }
    return moveX || moveY;
}

/**
 * Clears the selection context.
 * @param {object} data 
 */
const mouseUpHandlerForRemovingLines = (data) => {
    try {
        const canvas = data?.target?.canvas;
        const ctx = canvas?.getSelectionContext();
        if (ctx) ctx.clearRect(0, 0, canvas.width, canvas.height);
    } catch (err) {
        console.error('error while removing lines from the context', err);
    }
}
