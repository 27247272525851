import { fabric } from 'fabric';
import { shapeLineMoveHandler } from '../CommonFunctions';
import { clearHighlightForObject, compareObjectPositionInStack, createHighlightForObject, getObjectIndex, isObjectInsideOfObject } from '../FabricMethods';
import { calculateObjPos, getActiveObjectPosition, getAdditionalPositionForAttaching } from './CalculatePositions';
import { attachToFrame, detachFromFrame, isActiveSelectionHasHighlightedObject, isObjectAttachableDuringMoving, updateHighlightedObjectLocation } from './FrameMethods';
import { makeCustomFlowchartFeature, removeFlowchartProps } from '../flowchart/CustomFlowchartShapeUtils';
import {EDITING_METHODS} from '../Constant';

const isDetachedFromFrame = (obj, frame) => {
    return  (obj.attachedFrameId !== frame.uuid && 
   frame.attachments && 
   frame.attachments.indexOf(obj.uuid) !== -1 );
}

const clearObjIfNotAttached = (obj) => {
    if (obj.detachedFrom) {
        obj.detachedFrom = null;
    }
    if (obj.refObj) obj.wiredFrame.remove(obj.refObj);
    obj.wiredFrame = null;
    
    obj.refObj = null;
}

export const objMouseUpHandler = (e) => {
    // if the mouse up already is handled, ignore
    if (e?.transform?.mouseUpHandled) {
        return
    }
    
    const obj = e.target;
    if (!obj.canvas) return;

    clearHighlightForObject(obj.canvas, obj);
    obj.canvas.renderAll();

    if (obj.wiredFrame && isObjectInsideOfObject(obj.wiredFrame, obj)) {
        attachToFrame(obj, obj.wiredFrame);

        // if detached from frame, emit detached_obj for frames tab
        const frames = obj.canvas.getObjects().filter(o => o.type === 'frame');
        frames.forEach(frame => {
            if (
                isDetachedFromFrame(obj, frame)
            ) {
                obj.canvas.fire('detached_obj', {target: {obj}, from: frame.uuid});
                frame.attachments.splice(frame.attachments.indexOf(obj.uuid), 1);
            }
        });

        const frame = obj.wiredFrame;
        try {
            if (frame.attachments && frame.attachments.indexOf(obj.uuid) === -1) {
                const isAttachmentsExtensible = Object.isExtensible(frame.attachments);
                if (!isAttachmentsExtensible) {
                    frame.attachments = [...frame.attachments];
                }
                obj?.wiredFrame?.attachments.push(obj.uuid);
            }
        } catch (err) {
            console.error('Error while adding object to the attachments of frame', err);
        }


        try {
            // handle attaching object to the flowchart frame
            if (!obj.flowchartProps && frame && frame.flowchartProps) {
                makeCustomFlowchartFeature(obj.canvas, obj);
            }

            // handle attaching object to another frame which is not a flowchart frame
            if (obj.flowchartProps && frame && !frame.flowchartProps) {
                removeFlowchartProps(obj);
            }

            if (obj.flowchartProps && frame && frame.flowchartProps) {
                if (obj.flowchartProps.flowchartName !== frame.flowchartProps.flowchartName) {
                    removeFlowchartProps(obj);
                    makeCustomFlowchartFeature(obj.canvas, obj);
                }
            }
        } catch (err) {
            console.error('error while handling flowchart prop changes', err);
        }


    } else {
        if (obj?.flowchartProps && obj?.flowchartProps?.flowchartItemType !== 'frame') {
            removeFlowchartProps(obj);
        }
        clearObjIfNotAttached(obj);
       
        const frames = obj.canvas.getObjects().filter(o => o.type === 'frame');
        frames.forEach(frame => {
            try {
                if (frame.attachments && frame.attachments.indexOf(obj.uuid) !== -1) {
                    const isAttachmentsExtensible = Object.isExtensible(frame.attachments);
                    if (!isAttachmentsExtensible) {
                        frame.attachments = [...frame.attachments];
                    }
                    obj.canvas.fire('detached_obj', {target: {obj}, from: frame.uuid});
                    frame.attachments.splice(frame.attachments.indexOf(obj.uuid), 1);
                }
            } catch (err) {
                console.error('Error happened', err);
            }

        });
    }
}

const _moveHandlerForFrameAttachedObjects = (canvas, frame, attachedObject, parentFrame = null) => {
    if (!attachedObject.wiredFrame || !attachedObject.calculatedPos) return;
    // if attached object is curved line and it has left and right polygon, do not move it
    if (attachedObject.type === 'curvedLine' && (attachedObject.leftPolygon || attachedObject.rightPolygon)) {
        return;
    }
    const newPos = calculateObjPos(frame, attachedObject.calculatedPos);
    const additionalPos = getAdditionalPositionForAttaching(attachedObject);
    
    attachedObject.set({
        left: newPos.x - additionalPos.left,
        top: newPos.y - additionalPos.top,
    });
    attachedObject.onTransformChanged();
    attachedObject.setCoords();

    // calculate if mid points need to be updated
    let isBothAttached = false;
    if (attachedObject.lines && attachedObject.lines.length)  {
        attachedObject.lines.forEach(connectorUuid => {
            const connector = canvas.getObjects().find(o => o.uuid === connectorUuid);
            if (!connector || connector.points?.length <= 2) {
                return
            }

            const shouldIncludeMidPoints = isLinePolygonsInSameFrame(canvas, connector, frame, parentFrame);
            if (shouldIncludeMidPoints) {
                isBothAttached = true;
            }
        });
    }
    shapeLineMoveHandler(attachedObject, canvas, { changeMidPoints: isBothAttached });
}

/**
 * Checks if line's polygons are in the same frame (INCLUDING NESTED FRAMES).
 * @param {fabric.Canvas} canvas Canvas instance.
 * @param {fabric.CurvedLine} connector Line instance to check polygons.
 * @param {fabric.Frame} frame Frame to check polygons in.
 * @param {fabric.Frame|null} parentFrame If set, parent frame will be also checked.
 * @returns {boolean} Returns true if all the line polygons are in the canvas.
 */
export const isLinePolygonsInSameFrame = (canvas, connector, frame, parentFrame = null) => {
    let attachedCount = 0,
        leftPolygonId = connector.leftPolygon?.uuid,
        rightPolygonId = connector.rightPolygon?.uuid,
        leftPolygon,
        rightPolygon;

    if (leftPolygonId) {
        leftPolygon = canvas.getObjects().find(o => o.uuid === leftPolygonId);
    }
    if (rightPolygonId) {
        rightPolygon = canvas.getObjects().find(o => o.uuid === rightPolygonId);
    }


    // find attached frames to this frame
    const attachedFrames = canvas.getObjects()?.filter(o => o.attachedFrameId === frame.uuid && o.type === 'frame');
    const parentAttachedFrames = parentFrame
        ? canvas.getObjects()?.filter(o => o.attachedFrameId === parentFrame?.uuid && o.type === 'frame')
        : []
    // create frame uuid array to check if the connector has some polygons that are attached to related frames 
    const frameUUIDSForMovingMidPoint = [frame.uuid, ...attachedFrames.map(o => o.uuid), ...parentAttachedFrames.map(o => o.uuid)];

    // check if left and right polygons are being moved with the frame or nested frames
    if (leftPolygon && leftPolygon.type !== 'frame' && leftPolygon.wiredFrame && frameUUIDSForMovingMidPoint.includes(leftPolygon.wiredFrame.uuid)) {
        attachedCount++;
    }
    if (rightPolygon && rightPolygon.type !== 'frame' && rightPolygon.wiredFrame && frameUUIDSForMovingMidPoint.includes(rightPolygon.wiredFrame.uuid)) {
        attachedCount++;
    }
    
    return attachedCount === 2
}

const moveHandlerForFrame = (e, canvas) => {
    const obj = e.target;
    const isActiveSelection = obj.type === 'activeSelection';
    const selectionObjects = isActiveSelection ? obj.getObjects() : [];
    const pos = getActiveObjectPosition(canvas);

    if (obj.duplicateShadowObj) return;

    // If object has highlighted object, or if one of the selected objects has highlighted object,
    // then those highlighted objects location should be changed accordingly by the selected object.
    if (obj.highlightObject || (isActiveSelection && isActiveSelectionHasHighlightedObject(canvas))) {
        if (isActiveSelection) {
            for (const item of selectionObjects) {
                if (item.highlightObject) {
                    updateHighlightedObjectLocation(item, pos?.items[item.uuid]?.x, pos?.items[item.uuid]?.y);
                }
            }
        } else {
            updateHighlightedObjectLocation(obj, obj.left, obj.top);
        }
    }
    if (obj.type === 'frame') {
        const wiredObjectsToThis = canvas.getObjects().filter(
            o => o !== obj && o.wiredFrame && o.wiredFrame.uuid === obj.uuid
        );
        wiredObjectsToThis.forEach(attachedObject => {
            _moveHandlerForFrameAttachedObjects(canvas, obj, attachedObject); 
            if (attachedObject.type === 'frame') {
                const wiredObjectsToNestedFrame = canvas.getObjects().filter(
                    nestedAttachedObj => nestedAttachedObj !== attachedObject && nestedAttachedObj.wiredFrame && nestedAttachedObj.wiredFrame.uuid === attachedObject.uuid
                );
                wiredObjectsToNestedFrame.forEach(nestedAttachedObj => {
                    _moveHandlerForFrameAttachedObjects(canvas, attachedObject, nestedAttachedObj, obj); 
                });
            }
        });
    }
    const objects = canvas.getObjects();
    const frames = objects.filter((o) => o.type === 'frame' && o !== obj);

    /**
     * Checking that whether selected object is inside of the frame, and according to the result,
     * attaching and highlighting the object or detaching and clearing the highlighted object.
     * @param {fabric.Object} frame
     * @param {fabric.Object} item
     */
    const handleAttachAndDetach = (frame, item) => {
        if (!e?.transform?.sentToLaterShapes) {
            e.transform.sentToLaterShapes = []
        }
        if (isObjectAttachableDuringMoving(item, frame)) {
            if (isObjectInsideOfObject(frame, item, { manualCheck: true })) {
                e.transform.isModifiedFrame = true;
                if (e?.transform?.processId && !e.transform.sentToLaterShapes?.includes(frame.uuid)) {
                    e.transform.sentToLaterShapes.push(frame.uuid)
                    canvas.collaborationManager.addLaterShapes([frame], e.transform.processId, EDITING_METHODS.FRAME_ATTACHMENTS)
                }
                createHighlightForObject(canvas, item);

                if (isActiveSelection) {
                    item.oneOfMultipleAttachedItem = true;
                    attachToFrame(item, frame, { overridedPos: pos?.items[item.uuid] });
                } else {
                    attachToFrame(item, frame);
                }
            } else if (item.wiredFrame && !isObjectInsideOfObject(item.wiredFrame, item, { manualCheck: true })) {
                e.transform.isModifiedFrame = true;
                if (e?.transform?.processId && !e.transform.sentToLaterShapes?.includes(frame.uuid)) {
                    e.transform.sentToLaterShapes.push(frame.uuid)
                    canvas.collaborationManager.addLaterShapes([frame], e.transform.processId, EDITING_METHODS.FRAME_ATTACHMENTS)
                }
                detachFromFrame(item, frame);
                clearHighlightForObject(canvas, item);
            }
        }
    }

    // If multiple objects are selected, we need to attach them to the frame one by one.
    if (isActiveSelection) {
        for (const item of selectionObjects) {
            for (const frame of frames.filter(frame => frame !== item && selectionObjects.indexOf(frame) === -1)) {
                if (item.type === 'frame') {
                    if (compareObjectPositionInStack(objects, item, frame)) {
                        continue;
                    }
                }
                handleAttachAndDetach(frame, item) 
            }
        }
    } else {
        for (const frame of frames) {
            if (obj.type === 'frame') {
                let currentFrameIndex = getObjectIndex(objects, frame);
                let objIndex = getObjectIndex(objects, obj);

                if (objIndex < currentFrameIndex) {
                    continue;
                }
            }
            handleAttachAndDetach(frame, obj)
        }
    }

    obj.off('mouseup', objMouseUpHandler);
    obj.on('mouseup', objMouseUpHandler);
    

}

export default moveHandlerForFrame;