import { fabric } from 'fabric';

import store from '../redux/Store';
import { modes } from '../helpers/Constant';
import { removeCanvasListener } from '../helpers/CommonFunctions';
import DashedBrush from '../customClasses/DashedBrush';

export const Lasso = fabric.util.createClass(fabric.Path, {
    type: 'lasso',

    initialize(element, options) {
        options || (options = {});
        const greenColor = new fabric.Color('#6200EA');
        const greenWithOpacity = greenColor.setAlpha(0.1);
        this.callSuper('initialize', element, options);
        this.set('fill', greenWithOpacity.toRgba());
    },

    _render(ctx) {
        this.callSuper('_render', ctx);
    },
    width: 1,
    stroke: '#6200EA',
    strokeDashArray: [5, 5],
});

export const createLasso = (canvas, onMouseDownLineHandler) => {
    store.dispatch({
        type: 'board/changeCurrentMode',
        payload: modes.LASSO,
    });
    removeCanvasListener(canvas);
    canvas.toggleDragMode(canvas, false);
    onMouseDownLineHandler(canvas);
    canvas.freeDrawingBrush = new DashedBrush(canvas);
    canvas.freeDrawingBrush.color = '#6200EA';
    canvas.isDrawingMode = true;
    canvas.discardActiveObject().requestRenderAll(); 
};
export const makeLassoClosedShape = (canvas, path, uuidGenerator) => {
    path.avoidEmittingOnRemove = true;
    canvas.remove(path);
    canvas.renderAll();
    const pathPoints = path.path;
    const startPoint = pathPoints[0];
    const endPoint = pathPoints[pathPoints.length - 1];
    if (endPoint) {
        if (startPoint[1] !== endPoint[1] || startPoint[2] !== endPoint[2]) {
            // The path is not closed, so we need to connect the first and last point
            path.path.push([
                'L',
                startPoint[1],
                startPoint[2],
                endPoint[1],
                endPoint[2],
            ]);
            path.setCoords();
        }
    }
    const lasso = new Lasso(path.path, { uuid: uuidGenerator(canvas) });
    removeAllExistedLasso(canvas);
    lasso.avoidEmittingOnAdd = true;
    canvas.add(lasso);
    canvas.renderAll();
    return lasso;
};

const _checkIfObjectIsNotLocked = (object) => {
    if (object.isLocked) return false;
    if (object.lockMovementX && object.lockMovementY) return false;
    return true;
};

export const checkAndSelectTheObjectCoveredByLasso = (canvas, lasso) => {
    const availableObjects = canvas
        .getObjects()
        .filter(
            (item) =>
                item.type !== 'lasso' &&
            _checkIfObjectIsNotLocked(item) &&
            item.type !== 'comment' &&
            item.visible
        );
    let covered = false;
    const coveredObjects = [];
    availableObjects.forEach((object) => {
        const isPathContainsObject = _checkIfTheLassoCoverTheObjects(lasso, object);
        if (isPathContainsObject) {
            covered = true;
            coveredObjects.push(object);
        }
    });

    if (!covered) {
        lasso.avoidEmittingOnRemove = true;
        canvas.remove(lasso);
        canvas.renderAll();
    } else {
        coveredObjects.push(lasso);
        canvas.discardActiveObject();
        let sel = new fabric.ActiveSelection(coveredObjects, {
            canvas: canvas,
            createdWithLasso: true  // in _renderControls of the ActiveSelection, we seek this prop to not render the controls of the child objects
        });
        canvas.setActiveObject(sel);
        canvas.requestRenderAll();
    }

    return covered;
};

const _checkIfTheLassoCoverTheObjects = (lasso, object) => {
    let top, left;
    if (
        object.type === 'curvedLine' ||
    object.shapeType === 'rect' ||
    object.shapeType === 'triangle' ||
    object.shapeType === 'sticky' || 
    object.shapeType === 'rhombus' ||
    object.shapeType === 'parallelogram'
    ) {
        top = object.top - object.getScaledHeight() / 2;
        left = object.left - object.getScaledWidth() / 2;
    } else {
        top = object.top;
        left = object.left;
    }
    const overlapBorder = _findTheOverlapRectBorder(
        {
            top1: top,
            left1: left,
            width1: object.getScaledWidth(),
            height1: object.getScaledHeight(),
        },
        {
            top2: lasso.top,
            left2: lasso.left,
            width2: lasso.width,
            height2: lasso.height,
        }
    );
    if (overlapBorder) {
        const { topResult, leftResult, widthResult, heightResult } = overlapBorder;
        return _checkIfTheOverlapReactIsCoveredByLasso(
            lasso,
            {
                rectTop: topResult,
                rectLeft: leftResult,
                rectWidth: widthResult,
                rectHeight: heightResult,
            },
            {
                objectWidth: object.getScaledWidth(),
                objectHeight: object.getScaledHeight(),
            }
        );
    } else {
        return false;
    }
};

export const checkIfClickOutSideTheLasso = (canvas, isLassoMode) => {
    const objects = canvas.getObjects();
    const hasPath = objects.some((object) => object.type === 'lasso');
    if (!hasPath) {
        if (isLassoMode) canvas.isDrawingMode = true;
        return;
    }
    const activeGroup = canvas.getActiveObject();
    if (activeGroup) {
        if (typeof activeGroup.getObjects !== 'function') {
            _clickOutsideLasso(canvas, isLassoMode);
        } else {
            if (!(activeGroup.getObjects().some((element) => element.type === 'lasso'))) {
                _clickOutsideLasso(canvas, isLassoMode);
            }
        }
    } else {
        _clickOutsideLasso(canvas, isLassoMode);
    }
};
// Ray-Casting algorithm
/**
 * @param path
 * @param point
 */
function _checkIfPolygonContainsPoint(path, point) {
    let count = 0;
    for (let b = 0; b < path.length; b++) {
        const vertex1 = path[b];
        const vertex2 = path[(b + 1) % path.length];

        if (west(vertex1, vertex2, point.x, point.y)) ++count;
    }
    return count % 2;

    /**
     * @param A
     * @param B
     * @param x
     * @param y
     */
    function west(A, B, x, y) {
        const Ax = A[1];
        const Ay = A[2];
        const Bx = B[1];
        const By = B[2];
        if (Ay <= By) {
            if (y <= Ay || y > By || (x >= Ax && x >= Bx)) {
                return false;
            } else if (x < Ax && x < Bx) {
                return true;
            } else {
                return (y - Ay) / (x - Ax) > (By - Ay) / (Bx - Ax);
            }
        } else {
            return west(B, A, x, y);
        }
    }
}

export const removeAllExistedLasso = (canvas) => {
    const existedLassos = canvas
        .getObjects()
        .filter((item) => item.type === 'lasso');
    existedLassos.forEach((lasso) => {
        lasso.avoidEmittingOnRemove = true;
        canvas.remove(lasso);
    });
    canvas.renderAll();
};

const _findTheOverlapRectBorder = (rect1, rect2) => {
    const { top1, left1, width1, height1 } = rect1;
    const { top2, left2, width2, height2 } = rect2;
    const topResult = Math.max(top1, top2);
    const leftResult = Math.max(left1, left2);
    const widthResult = Math.min(left1 + width1, left2 + width2) - leftResult;
    const heightResult = Math.min(top1 + height1, top2 + height2) - topResult;

    if (widthResult <= 0 || heightResult <= 0) {
        return;
    } else {
        return { topResult, leftResult, widthResult, heightResult };
    }
};

const _checkIfTheOverlapReactIsCoveredByLasso = (
    lasso,
    overlapRect,
    object
) => {
    // an object will be considered as covered by lasso, if 70% area of it was covered by lasso
    // to calculate the covered area, we only need to check in the overlap rectangle
    const { rectTop, rectLeft, rectWidth, rectHeight } = overlapRect;
    const { objectWidth, objectHeight } = object;
    let numberOfCoveredPoint = 0;
    // if the overlapRect is too big, separate it to 4900 small piece
    const stepWidth = rectWidth * rectHeight < 4900 ? 1 : rectWidth / 70;
    const stepHeight = rectWidth * rectHeight < 4900 ? 1 : rectHeight / 70;

    const totalArea = (objectWidth / stepWidth) * (objectHeight / stepHeight);
    // loop through all the piece
    for (let i = rectLeft; i < rectLeft + rectWidth; i += stepWidth) {
        for (let j = rectTop; j < rectTop + rectHeight; j += stepHeight) {
            const point = new fabric.Point(i, j);
            if (_checkIfPolygonContainsPoint(lasso.path, point))
                numberOfCoveredPoint++;
            if(numberOfCoveredPoint / totalArea > 0.2) return true;
        }
    }
    // the totalArea or acreage of the original object must be consider the step
    return false;
};

/**
 * @param canvas
 * @param isLassoMode
 */
function _clickOutsideLasso(canvas, isLassoMode) {
    canvas.discardActiveObject();
    removeAllExistedLasso(canvas);
    if (isLassoMode) canvas.isDrawingMode = true;
}
