import { toast } from 'react-toastify';
import { checkIfLineIsConnectWithShapeV2 } from '../hooks/UseLine';
import store from '../redux/Store';
import { NOT_ALLOWED_SHAPE_FOR_LAYER } from './Constant';
import getToastIcon from './media/GetToastIcon';
import { v4 as uuidv4 } from 'uuid';
import { fitToScreenAssistance } from './FitToScreenAssistance';
import { isAnyFrameAttachedObjectsInvisible } from './frame/FrameMethods';
let restoredHiddenShape = 'restored-hidden-shape';
let createdHiddenShape = 'created-hidden-shape';
let duplicatedHiddenShape = 'duplicated-hidden-shape';
let frameHidenElmAffInfo = 'frame-hidden-ele-affected-info';

/**
 * generate payload that are going to update in store and also updateVisibilty of shapes attached layer visibility
 * @param {*} canvas
 * @param {*} payload contain action and addedObject,removedObject if an action is `countUpdate` if action is `visibiltyUpdate` contain layerIds with its visibilty status
 * @returns return payload as layers that are going to update in store
 */
export const updateShapeAndGetPayloadToUpdatePageLayerData = (canvas, originalPayload) => {
    let updateLayers = {};
    const addedObjects = [];
    // i am resuse this payload and manulpulating it so don't want other listener to get the changed payload
    const payload = JSON.parse(JSON.stringify(originalPayload));

    if (payload.action === 'countUpdate') {
        // for having unique elements
        const idsSet = new Set();
        payload?.addedObjects?.forEach((o) => {
            if (o && !idsSet.has(o.uuid) && (o.shapeType ?? o.properties?.shapeType) && !NOT_ALLOWED_SHAPE_FOR_LAYER.includes(o.shapeType ?? o.properties.shapeType)) {
                idsSet.add(o.uuid);
                addedObjects.push(o);
            }
        });
        const removedObjects = [];
        idsSet.clear();
        payload?.removedObjects?.forEach((o) => {
            if (o && !idsSet.has(o.uuid) && (o.shapeType ?? o.properties?.shapeType) && !NOT_ALLOWED_SHAPE_FOR_LAYER.includes(o.shapeType ?? o.properties.shapeType)) {
                idsSet.add(o.uuid);
                removedObjects.push(o);
            }
        });

        if (!addedObjects.length && !removedObjects.length) {
            return {};
        }

        const pageLayersVisibility = store.getState()?.layers?.layersShapesVisibility ?? {};

        if (addedObjects.length) {
            addedObjects.forEach((o) => {
                const layerId = o.layerId ?? o?.properties?.layerId;
                if (!updateLayers[layerId]) {
                    updateLayers[layerId] = {
                        count: 1,
                        // adding visibilty here for use in page layer visibility
                        visibility: pageLayersVisibility?.[layerId]?.visibility ?? true,
                    };
                } else {
                    updateLayers[layerId].count += 1;
                }
            });
        }

        if (removedObjects.length) {
            removedObjects.forEach((o) => {
                const layerId = o.layerId ?? o?.properties?.layerId;
                if (!updateLayers[layerId]) {
                    updateLayers[layerId] = {
                        count: -1,
                        visibility: pageLayersVisibility?.[layerId]?.visibility ?? true,
                    };
                } else {
                    updateLayers[layerId].count -= 1;
                }
            });
        }
    }

    if (payload.action === 'visibilityUpdate') {
        updateLayers = payload;
    }

    updateShapesVisibility(canvas, updateLayers);

    if (['activityLog', 'undoRedoListener'].includes(payload.source) && addedObjects.length) {
        showToastForCreatedShapeBecomeInvisible(updateLayers, addedObjects);
    }

    checkAndCloseFitToScreenAssis(payload.action);
    delete updateLayers.action;
    delete updateLayers.source;

    return updateLayers;
};

/**
 * update shapes visibility as per layers visibility
 * @param {*} canvas
 * @param {*} updateLayers is object contain layersId and visibility
 */
export const updateShapesVisibility = (canvas, updateLayers) => {
    const allObjects = canvas.getObjects();
    const selectedObjects =
        allObjects?.filter(
            (o) =>
                o.shapeType &&
                !NOT_ALLOWED_SHAPE_FOR_LAYER.includes(o.shapeType ?? o.type) &&
                !!updateLayers[o.layerId] &&
                o.visible !== updateLayers[o.layerId].visibility
        ) ?? [];
    const visibilityChangedFrameIdsSet = new Set();
    const affectedLinesIds = [];
    if (selectedObjects.length) {
        // make the objects and all line attach to that object visible or invisible as per layer visibility
        selectedObjects.forEach((obj) => {
            affectedLinesIds.push(...(obj.lines ?? []));
            if (obj.attachedFrameId) {
                // store all attached direct frames
                visibilityChangedFrameIdsSet.add(obj.attachedFrameId);
            }
            const visibility = updateLayers[obj.layerId].visibility;
            obj.onShapeChanged();
            obj.visible = visibility;
            obj.hasBorders = visibility;
        });

        // return frames with levels (eg - if frame is having 1 frame attached its level is 1 otherwise 0)
        // set level based on frame attachment
        const getFramesLevel = (frames) => {
            const frameLevelMap = new Map();
            frames.forEach((f) => {
                if (!frameLevelMap.has(f.uuid)) {
                    frameLevelMap.set(f.uuid, { level: 0, obj: f });
                }
                if (f.attachedFrameId) {
                    const frame = frameLevelMap.get(f.attachedFrameId);
                    frameLevelMap.set(f.attachedFrameId, { level: (frame?.level ?? 0) + 1, obj: frame?.obj ?? frames.find((fo) => fo.uuid === f.attachedFrameId) });
                }
            });
            return new Map([...frameLevelMap.entries()].sort((a, b) => a[1].level - b[1].level));
        };

        const setFrameVisibilityBasedOnAttachment = (frameObj) => {
            // i am only setting frame shape visibility based on its attachment visibility
            // and attachment(like rect, circle,...other shapes) visibility already set by in start of function i know attachment visibility
            // if its all child are invisible than mark frame as invisible
            const frameAttachedItemsIdsSet = new Set(frameObj.attachments ?? []);
            if (frameAttachedItemsIdsSet.size) {
                const frameAttachedItemVisibilitySet = new Set(allObjects.filter((o) => frameAttachedItemsIdsSet.has(o.uuid)).map((o) => o.visible));
                if (frameAttachedItemVisibilitySet.size) {
                    let visibility;
                    // if set size is 1 it means attached shapes have common visibility
                    if (frameAttachedItemVisibilitySet.size === 1) {
                        // if all are not visible make frame also not
                        visibility = frameAttachedItemVisibilitySet.values().next().value;
                    } else {
                        // if size is 2 it means we have some shapes visible make frame visible then
                        visibility = true;
                    }
                    if (frameObj.visible !== visibility) {
                        frameObj.onShapeChanged();
                        frameObj.visible = visibility;
                        frameObj.hasBorders = visibility;
                        affectedLinesIds.push(...(frameObj.lines ?? []));
                    }
                }
            }
        };

        const getAllAffectedFrames = (allFrames, affectedFrames) => {
            const processedFramesIdsSet = new Set();
            const resp = [];
            const solve = (attachedFrameId) => {
                const newAffectedFrame = allFrames.find((f) => f.uuid === attachedFrameId);
                processedFramesIdsSet.add(newAffectedFrame.uuid);
                resp.push(newAffectedFrame);
                if (newAffectedFrame.attachedFrameId && !processedFramesIdsSet.has(newAffectedFrame.attachedFrameId)) {
                    solve(newAffectedFrame.attachedFrameId);
                }
            };
            affectedFrames.forEach((frame) => frame.attachedFrameId && !processedFramesIdsSet.has(frame.attachedFrameId) && solve(frame.attachedFrameId));
            return [...affectedFrames, ...resp];
        };
        if (visibilityChangedFrameIdsSet.size) {
            const allFrames = allObjects.filter((o) => o.shapeType === 'frame');
            const affectedFrames = allFrames.filter((o) => visibilityChangedFrameIdsSet.has(o.uuid));
            const allAffectedFrames = getAllAffectedFrames(allFrames, affectedFrames);
            const frameLevelMap = getFramesLevel(allAffectedFrames);
            frameLevelMap.forEach((val) => setFrameVisibilityBasedOnAttachment(val.obj));
        }
        setLinesVisibility(allObjects, affectedLinesIds);
        canvas.discardActiveObject().requestRenderAll();
    }
};

const showToastForCreatedShapeBecomeInvisible = (updateLayers, addedObjects) => {
    if (addedObjects.some((o) => !updateLayers[o.layerId ?? o.properties.layerId].visibility)) {
        toast.dismiss(restoredHiddenShape);
        restoredHiddenShape = toast.info('Element(s) restored but currently hidden from the board', {
            icon: getToastIcon('info'),
            autoClose: true,
            className: 'wb_toast',
            draggable: false,
            toastId: `restored-hidden-shape-${uuidv4()}`,
        });
    }
};

const checkAndCloseFitToScreenAssis = (action) => {
    if (fitToScreenAssistance.isInitialized && action === 'visibilityUpdate') {
        fitToScreenAssistance.destroy(true);
    }
};

/**
 * set line visibility based on attached objects
 * @param {*} allObjects
 * @param {*} affectedLinesIds line uuids
 */
const setLinesVisibility = (allObjects, affectedLinesIds) => {
    const affectedLinesIdsSet = new Set(affectedLinesIds);
    if (affectedLinesIdsSet.size) {
        const affectedLineObjs = [];
        allObjects.forEach((obj) => {
            if (affectedLinesIdsSet.has(obj.uuid)) {
                // affected line obj
                affectedLineObjs.push(obj);
            }
        });
        const proccessedLineIdsSet = new Set();
        allObjects.forEach((obj) => {
            if (Array.isArray(obj.lines) && obj.lines.length > 0) {
                const lines = affectedLineObjs.filter(
                    (line) => obj.lines.includes(line.uuid) && !proccessedLineIdsSet.has(line.uuid) && checkIfLineIsConnectWithShapeV2(line, obj)
                );
                lines.forEach((line) => {
                    if (!obj.visible) {
                        proccessedLineIdsSet.add(line.uuid);
                    }
                    line.onShapeChanged();
                    line.visible = obj.visible;
                });
            }
        });
    }
};

export const showElmCreatedWithHiddenToast = (createdObjects) => {
    const currentLayersState = store.getState()?.layers ?? {};
    const defaultLayerVisibilityState = currentLayersState.layersShapesVisibility?.[currentLayersState.defaultLayerId]?.visibility;
    if (defaultLayerVisibilityState === false && createdObjects.filter((o) => !NOT_ALLOWED_SHAPE_FOR_LAYER.includes(o.shapeType ?? o.properties.shapeType))?.length) {
        toast.dismiss(createdHiddenShape);
        createdHiddenShape = toast.info('Element(s) created but currently hidden from the board', {
            icon: getToastIcon('info'),
            autoClose: true,
            className: 'wb_toast',
            draggable: false,
            toastId: `created-hidden-shape-${uuidv4()}`,
        });
    }
};

export const showElmDuplicatedWithHiddenToast = (duplicatedObjects) => {
    const currentLayersState = store.getState()?.layers ?? {};
    const defaultLayerVisibilityState = currentLayersState.layersShapesVisibility?.[currentLayersState.defaultLayerId]?.visibility;
    if (defaultLayerVisibilityState === false && duplicatedObjects.filter((o) => !NOT_ALLOWED_SHAPE_FOR_LAYER.includes(o.shapeType ?? o.properties.shapeType))?.length) {
        toast.dismiss(duplicatedHiddenShape);
        duplicatedHiddenShape = toast.info('Element(s) duplicated but currently hidden from the board', {
            icon: getToastIcon('info'),
            autoClose: true,
            className: 'wb_toast',
            draggable: false,
            toastId: `duplicated-hidden-shape-${uuidv4()}`,
        });
    }
};

export const showFrameObjectsHiddenAffectedToast = (canvas, ids, key = 'visible') => {
    if (isAnyFrameAttachedObjectsInvisible(canvas, ids, key)) {
        toast.dismiss(frameHidenElmAffInfo);
        frameHidenElmAffInfo = toast.info('Some hidden elements are also affected by this action', {
            icon: getToastIcon('info'),
            autoClose: true,
            className: 'wb_toast',
            draggable: false,
            toastId: `frame-hidden-ele-affected-info-${uuidv4()}`,
        });
    }
};
