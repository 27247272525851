import { fabric } from 'fabric';
import {
    DUPLICATE_SHADOW_GROUP_TYPE,
    DUPLICATE_SHADOW_OBJECT_TYPE,
    EMITTER_TYPES,
    HIGHLIGHT_TYPE,
    customFields,
    TABLE_NOT_ALLOWED_CONTROL_TYPES,
    SHAPE_DEFAULTS,
    INVERSE_ADAPT_ZOOM_LEVEL_PADDING,
    EDITING_METHODS_FOR_TEXT,
    EDITING_METHODS,
    NOT_ALLOWED_SHAPE_FOR_LAYER
} from './Constant';

import { generateLinkedShapes, getFrameAttachedShapes } from './frame/FrameMethods';
import eventEmitter from './EventEmitter';
import {expanseControls, middleControls} from './customControls/CustomizeControlsForShape';
import HtmlEditorHelper from './HtmlEditorHelper';
import { openShapeEditor } from './CommonFunctions';
import { hexToRGBA } from './CommonUtils';


export const createFabricInstance = (data, cb) => fabric.util.enlivenObjects([data], cb);

const cloneFabricObject = (destination, source, options = {}) => {
    return function () {
        return fabric.util.object.extend(destination.call(this), source, options?.shouldCopyDeeply);
    };
};

export const cloneFabricObjectHelper = (obj, opts, options = {}) => {
    const sourceObj = {
        uuid: obj.uuid,
        whiteBoardId: obj.whiteBoardId,
        userId: obj.userId,
        createdBy: obj.createdBy,
        modifiedBy: obj.modifiedBy,
        shapeType: obj.shapeType,
        lines: obj.lines,
        etag: obj.etag,
        url: obj.url,
        size: obj.size,
        lockMovementX: obj.lockMovementX,
        lockMovementY: obj.lockMovementY,
        hasBorders: obj.hasBorders,
        hoverCursor: obj.hoverCursor,
        _controlsVisibility: obj._controlsVisibility,
        crossOrigin: obj.crossOrigin,
        isLocked: obj.isLocked,
        lockScalingFlip: obj.lockScalingFlip,
        flipX: obj.flipX,
        flipY: obj.flipY,
        hasControls: obj.hasControls,
        hasHyperlink: obj.hasHyperlink,
        zIndex: obj.zIndex
    }

    if (parseInt(obj.stackOrder, 10) > -1) {
        sourceObj.stackOrder = parseInt(obj.stackOrder, 10);
    }

    // handle frame attachments
    sourceObj.attachedFrameId = obj.attachedFrameId;
    sourceObj.calculatedPos = obj.calculatedPos;
    if (obj.type === 'frame') {
        sourceObj.attachments = obj.attachments;
        sourceObj.text = obj.text;
    }
    if (obj.type === 'curvedLine') {
        sourceObj.arrowEnabled = obj.arrowEnabled;
        sourceObj.arrowRight = obj.arrowRight;
        sourceObj.arrowLeft = obj.arrowLeft;
        sourceObj.text = obj.text;
        sourceObj.textFontSize = obj.textFontSize;
        sourceObj.textColor = obj.textColor;
        sourceObj.lineType = obj.lineType;
        sourceObj.isInitiallyConnector = obj.isInitiallyConnector;
        sourceObj.leftPolygon = opts?.narrowDefinitions ? obj?.leftPolygon?.uuid : obj?.leftPolygon;
        sourceObj.leftRelativeX = obj.leftRelativeX;
        sourceObj.leftRelativeY = obj.leftRelativeY;
        sourceObj.rightPolygon = opts?.narrowDefinitions ? obj?.rightPolygon?.uuid : obj.rightPolygon;
        sourceObj.rightRelativeX = obj.rightRelativeX;
        sourceObj.rightRelativeY = obj.rightRelativeY;
        sourceObj.curvedLineVersion = obj.curvedLineVersion;
    }
    if (obj.type === 'optimizedImage') {
        sourceObj.imageData = obj.imageData;
        sourceObj.text = obj.text;
        sourceObj.altUrl = obj.altUrl;
    }
    if (obj.flowchartProps) {
        sourceObj.flowchartProps = obj.flowchartProps;
    }
    if (obj.constantFlowchartProps) {
        sourceObj.constantFlowchartProps = obj.constantFlowchartProps;
    }

    if (obj.type === 'table') {
        sourceObj.title = obj.title;
        sourceObj.rows = obj.rows;
        sourceObj.cols = obj.cols;
        sourceObj.cells = obj.cells;
        sourceObj.defaultCellWidth = obj.defaultCellWidth;
        sourceObj.defaultCellHeight = obj.defaultCellHeight;
        sourceObj.cellTextPadding = obj.cellTextPadding;

        const NOT_ALLOWED_TABLE_CELL_PROPS = ['renderedText'];
        sourceObj.cells = sourceObj.cells.map((cell) => {
            NOT_ALLOWED_TABLE_CELL_PROPS.forEach((key) => delete cell[key]);
            return cell;
        });

    }

    if (obj.importedFromThirdParty) {
        sourceObj.importedFromThirdParty = true;
    }

    if(obj.type === 'textbox'){
        sourceObj.padding = obj.padding;
        sourceObj.fill = obj.fill;
        sourceObj.backgroundColor = obj.backgroundColor;
        sourceObj.enablePlaceholder = obj.enablePlaceholder;
        sourceObj.borderScaleFactor = obj.borderScaleFactor;
    }
    
    if (obj.type === 'group') {
        if (obj.isTextColorApplied) {
            sourceObj.isTextColorApplied = true;
        }
    }

    if(!NOT_ALLOWED_SHAPE_FOR_LAYER.includes(obj.shapeType)){
        if(obj?.layerId?.length){
            sourceObj.layerId = obj.layerId
        }else if(opts?.defaultLayerId?.length){
            // setting default layerId to created shape
            sourceObj.layerId = opts.defaultLayerId;
        }else{
            // empty else
        }
    }

    return cloneFabricObject(obj.toObject, sourceObj, options);
};

/**
 * @param {fabric.Object} obj - Object that will be converted to object.
 * @param {object} options
 * @param {boolean} options.useCalculatedPosition - If true, position of the object will be calculated. No need to discard active object with this option.
 * @param {boolean}  options.narrowDefinitions - If true, the definitions of the some props will be narrowed.
 * @param {boolean} options.shouldCopyDeeply if true, the object data will be copied deeply
 * @param {boolean} options.defaultLayerId if present, assign to object
 * @returns
 */
export const customToObject = (obj, options = {}) => {
    let leftPolygon = null, rightPolygon = null;
    if (!obj) {
        return null;
    }
    if (obj?.leftPolygon) {
        if (obj.leftPolygon?.toObject) leftPolygon = obj.leftPolygon.toObject(customFields);
        else leftPolygon = obj.leftPolygon;
    }
    if (obj?.rightPolygon) {
        if (obj.rightPolygon?.toObject) rightPolygon = obj.rightPolygon.toObject(customFields);
        else rightPolygon = obj.rightPolygon;
    }
    obj.leftPolygon = leftPolygon;
    obj.rightPolygon = rightPolygon;
    
    if (obj.toObject) {
        const optForCloneHelpers = {}
        if (options.narrowDefinitions) {
            optForCloneHelpers.narrowDefinitions = true;
        }
        if(options.defaultLayerId){
            optForCloneHelpers.defaultLayerId = options.defaultLayerId;
        }
        obj.toObject = cloneFabricObjectHelper(obj, optForCloneHelpers, options);
        const objValues = obj.toObject(customFields);
        if (objValues.hasOwnProperty('size') && typeof objValues.size === 'function') {
            objValues.size = null;
        }
        // if requested, recalculate position of the object if it is on the active selection
        if (options.useCalculatedPosition) {
            if (obj.group) {
                try {
                    // calculation by center points of the object and group
                    const objectCenterPoint = obj.getCenterPoint();
                    const groupCenterPoint = obj.group.getCenterPoint();
                    const objectPosition = { 
                        left: objectCenterPoint.x + groupCenterPoint.x,
                        top: objectCenterPoint.y + groupCenterPoint.y,
                    }
                    // if the object isn't positioned by center then calculate the position by origin
                    if (obj.originX === 'left') {
                        objectPosition.left = objectPosition.left - (obj.width * obj.scaleX) / 2;
                    }
                    if (obj.originY === 'top') {
                        objectPosition.top = objectPosition.top - (obj.height * obj.scaleY) / 2;
                    }
                    if (obj.originX === 'right') {
                        objectPosition.left = objectPosition.left + (obj.width * obj.scaleX) / 2;
                    }
                    if (obj.originY === 'bottom') {
                        objectPosition.top = objectPosition.top + (obj.height * obj.scaleY) / 2;
                    }
                    // set the calculated position to the object
                    objValues.left = objectPosition.left;
                    objValues.top = objectPosition.top;
                } catch (err) {
                    console.error('Error happened', err);
                }
            }
        }
        return objValues;
    }
    else return obj;
}



/**
 * Returns spesific fabric object with given property and value .
 * @param {fabric.Canvas} canvas
 * @param {string} property - Property of fabric object for searching.
 * @param {any} value - Unique value for given property.
 */
export const getFabricObject = (canvas, property, value) => {
    return canvas.getObjects().find(obj => obj[property] === value);
}


let cancelAnimRef = null;
/**
 * Centers the canvas according to given object.
 * @param {fabric.Canvas} canvas The canvas object to set zoom.
 * @param {fabric.Object} object The object to determine zoom level.
 * @param {object} options Settings for the animation process.
 * @param {boolean} options.adaptZoomLevel - If true, the zoom level will be changed to show all the object.
 * @param {boolean} options.showToolbar - If not false, an event emitter for showing the subtoolbar will be fired.
 * @param {boolean} options.useAdvanced - If true, the advanced centerObjectWithAnimation method will be used.
 */
export const centerToObjectWithAnimation = (canvas, object, options = {}) => {
    if (options.useAdvanced) {
        advancedCenterToObjectWithAnimation(canvas, object, options)
        return;
    }

    // Cancel the continuing animation if exist.
    if (typeof cancelAnimRef === 'function') {
        cancelAnimRef();
    }

    const isObjectActive = object.uuid === canvas.getActiveObject()?.uuid;
    eventEmitter.fire(EMITTER_TYPES.TOOLBAR_HIDE);
    if (HtmlEditorHelper.isHtmlEditorEnabled() && (!object.isHtmlEditingMode || isObjectActive)) {
        eventEmitter.fire(EMITTER_TYPES.CLOSE_HTML_EDITOR);
    }

    const adaptZoom = {
            zoom: 0,
            enabled: false
        },
        zoom = canvas.getZoom(),
        objWidth = object.getScaledWidth(),
        objHeight = object.getScaledHeight();

    // if object is bigger than canvas dimensions
    // and if options.adaptZoomLevel is true
    // then zoom level will be adapted to object
    if (options.adaptZoomLevel) {
        if (objWidth > canvas.width || objHeight > canvas.height) {
            if (20 * objHeight > 9 * objWidth) {
                if (objHeight > window.innerHeight) adaptZoom.zoom = window.innerHeight / objHeight;
                else adaptZoom.zoom = objHeight / window.innerHeight;
            } else {
                if (objWidth > window.innerWidth) adaptZoom.zoom = window.innerWidth / objWidth;
                else adaptZoom.zoom = objWidth / window.innerWidth;
            
            }
            adaptZoom.zoom /= 2;
            adaptZoom.enabled = true;
        }
    }

    // FACT: with deleting objWidth / 2, pan can be more centered to the object
    // get pan values for the object
    let panX, panY;

    if (adaptZoom.enabled) {
        panX = ((canvas.getWidth() / adaptZoom.zoom / 2) - (object.aCoords.tl.x) - (objWidth / 2)) * adaptZoom.zoom
        panY = ((canvas.getHeight() / adaptZoom.zoom / 2) - (object.aCoords.tl.y) - (objHeight / 2)) * adaptZoom.zoom
    } else {
        panX = ((canvas.getWidth() / zoom / 2) - (object.aCoords.tl.x) - (objWidth / 2)) * zoom
        panY = ((canvas.getHeight() / zoom / 2) - (object.aCoords.tl.y) - (objHeight / 2)) * zoom
    }


    // get current pan values from viewportTransform
    const canvasCurrentPan = {
        x: canvas.viewportTransform[4],
        y: canvas.viewportTransform[5]
    };

    // using fabric.util.animate to animate pan
    // startValue: 0, endValue: 1, duration: 500
    // startValue and endValue are used for calculating the current value with animation
    // do not change startValue and endValue
    cancelAnimRef = fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: 500,
        onChange: function(animationVal) {
        // get difference between current pan and pan values for the object
            const diffPanX = Math.abs(panX - canvasCurrentPan.x) * animationVal;
            const diffPanY = Math.abs(panY - canvasCurrentPan.y) * animationVal;

            // 2 lines below calculates should current pan be added or subtracted
            const diffPanXChange = panX < canvasCurrentPan.x ? diffPanX * -1 : diffPanX;
            const diffPanYChange = panY < canvasCurrentPan.y ? diffPanY * -1 : diffPanY;

            if (adaptZoom.enabled) {
                const diffZoom = Math.abs(adaptZoom.zoom - zoom) * animationVal;
                const plValZoom = adaptZoom.zoom < zoom ? diffZoom * -1 : diffZoom;

                canvas.setViewportTransform(
                    [zoom + plValZoom, 0, 0, zoom + plValZoom, canvasCurrentPan.x + diffPanXChange, canvasCurrentPan.y + diffPanYChange]
                );
            } else {
            // setting new pan values
                canvas.setViewportTransform(
                    [zoom, 0, 0, zoom, canvasCurrentPan.x + diffPanXChange, canvasCurrentPan.y + diffPanYChange]
                );
            }

        },
        onComplete: function() {
            canvas.fire('board:zoom');

            if (options.showToolbar !== false) {
                eventEmitter.fire(EMITTER_TYPES.TOOLBAR_SHOW);
            }

            if (options.selectAfterAnimation) {
                if (!object.collabLocked) {
                    canvas.setActiveObject(object);
                    canvas.renderAll();
                }
            } else if (isObjectActive && HtmlEditorHelper.isObjectAllowedToHtmlEditor(object)) {
                openShapeEditor(object, canvas, { isEdit: false, actionFrom: 'selection' });
            } else {
                const activeObj = canvas.getActiveObject();
                if (activeObj?.uuid !== object?.uuid && HtmlEditorHelper.isObjectAllowedToHtmlEditor(activeObj)) {
                    openShapeEditor(activeObj, canvas, { isEdit: false, actionFrom: 'selection' });
                }
            }

            cancelAnimRef = null;
        },
        easing: fabric.util.ease['easeInOutCirc']
    })
}

let cancelAdvancedAnimRef = null;
export const advancedCenterToObjectWithAnimation = (canvas, object, options = {}) => {
    // Cancel the continuing animation if exist.
    if (typeof cancelAdvancedAnimRef === 'function') {
        cancelAdvancedAnimRef();
    }

    const isObjectActive = object.uuid === canvas.getActiveObject()?.uuid;
    eventEmitter.fire(EMITTER_TYPES.TOOLBAR_HIDE);

    if (HtmlEditorHelper.isHtmlEditorEnabled() && (!object.isHtmlEditingMode || isObjectActive)) {
        eventEmitter.fire(EMITTER_TYPES.CLOSE_HTML_EDITOR);
    }

    // get current pan values from viewportTransform
    const canvasCurrentPan = {
        x: canvas.viewportTransform[4],
        y: canvas.viewportTransform[5]
    };
    

    const currentZoom = canvas.getZoom();

    const objWidth = object.getScaledWidth();
    const objHeight = object.getScaledHeight();

    // Calculate the zoom levels for both dimensions
    const widthZoom = (canvas.getWidth() * INVERSE_ADAPT_ZOOM_LEVEL_PADDING) / objWidth;
    const heightZoom = (canvas.getHeight() * INVERSE_ADAPT_ZOOM_LEVEL_PADDING) / objHeight;
    
    let desiredZoom = Math.min(widthZoom, heightZoom);
    
    desiredZoom = Math.max(0.01, Math.min(1, desiredZoom))
    

    let panX = ((canvas.getWidth() / desiredZoom / 2) - (object.aCoords.tl.x) - (objWidth / 2)) * desiredZoom
    let panY = ((canvas.getHeight() / desiredZoom / 2) - (object.aCoords.tl.y) - (objHeight / 2)) * desiredZoom

    // using fabric.util.animate to animate pan
    // startValue: 0, endValue: 1, duration: 500
    // startValue and endValue are used for calculating the current value with animation
    // do not change startValue and endValue
    cancelAdvancedAnimRef = fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: 500,
        onChange: function (animationVal) {
            // get difference between current pan and pan values for the object
            const diffPanX = Math.abs(panX - canvasCurrentPan.x) * animationVal;
            const diffPanY = Math.abs(panY - canvasCurrentPan.y) * animationVal;

            // 2 lines below calculates should current pan be added or subtracted
            const diffPanXChange = panX < canvasCurrentPan.x ? diffPanX * -1 : diffPanX;
            const diffPanYChange = panY < canvasCurrentPan.y ? diffPanY * -1 : diffPanY;

            const diffZoom = Math.abs(desiredZoom - currentZoom) * animationVal;
            const plValZoom = desiredZoom < currentZoom ? diffZoom * -1 : diffZoom;
            
            canvas.setViewportTransform(
                [currentZoom + plValZoom, 0, 0, currentZoom + plValZoom, canvasCurrentPan.x + diffPanXChange, canvasCurrentPan.y + diffPanYChange]
            );
        },
        onComplete: function () {
            canvas.fire('board:zoom');

            if (options.showToolbar !== false) {
                eventEmitter.fire(EMITTER_TYPES.TOOLBAR_SHOW);
            }

            if (options.selectAfterAnimation && !object.collabLocked) {
                canvas.setActiveObject(object);
                canvas.renderAll();
            } else if (isObjectActive && HtmlEditorHelper.isObjectAllowedToHtmlEditor(object)) {
                openShapeEditor(object, canvas, { isEdit: false, actionFrom: 'selection' });
            } else {
                const activeObj = canvas.getActiveObject();
                if (activeObj?.uuid !== object?.uuid && HtmlEditorHelper.isObjectAllowedToHtmlEditor(activeObj)) {
                    openShapeEditor(activeObj, canvas, { isEdit: false, actionFrom: 'selection' });
                }
            }

            cancelAdvancedAnimRef = null;
        },
        easing: fabric.util.ease['easeInOutCirc']
    })
}


/**
 * Returns text from given shape.
 * @param {fabric.Object} shape 
 * @param {boolean} isLocalShape - If true, shape is local shape. If false, shape supposed to be
 * object created by createObjectToBeEmitted function.
 * @returns String.
 */
export const getTextFromShape = (shape, isLocalShape = false) => {
    let itemText;

    if (isLocalShape) {
        if (shape.type === 'group') {
            const groupObjects = shape.getObjects();
            if (groupObjects.length > 1 && groupObjects[1].type === 'textbox') {
                itemText = groupObjects[1].text;
            }
        } else if (shape.type === 'textbox') {
            itemText = shape.text;
        } else if (shape.type === 'frame') {
            return shape.text
        } else if (shape.type === 'table') {
            return shape.title;
        } else if (shape.type === 'curvedLine') {
            if (shape.text && shape.text.trim().length > 0) itemText = shape.text;
        }
    } else {
        if (shape.type === 'group' 
            && Array.isArray(shape.objects) 
            && shape.objects.length > 1 
            && shape.objects[1].type === 'textbox') {
            itemText = shape.objects[1].text;
        } else if (shape.type === 'textbox') {
            itemText = shape.text;
        } else if (shape.type === 'frame') {
            itemText = shape.text;
        } else if (shape.type === 'table') {
            itemText = shape.title;
        }else if (shape.type === 'curvedLine') {
            if (shape.text && shape.text.trim().length > 0) itemText = shape.text;
        }
    }

    return itemText;
}

/**
 * Returns object corner coordinates by originX and originY.
 * @param {fabric.Object} object 
 * @param options
 */
export const getObjectCoordinatesByOrigin = (object) => {
    let objWidth = object.getScaledWidth();
    let objHeight = object.getScaledHeight();
    let objectLeft = object.left;
    let objectTop = object.top;

    if (object.originX === 'center') {
        objectLeft = object.left - objWidth / 2;
    } else if (object.originX === 'right') {
        objectLeft = object.left - objWidth;
    }
    if (object.originY === 'center') {
        objectTop = object.top - objHeight / 2;
    } else if (object.originY === 'bottom') {
        objectTop = object.top - objHeight;
    }

    return {
        tl: {
            x: objectLeft,
            y: objectTop
        },
        tr: {
            x: objectLeft + objWidth,
            y: objectTop
        },
        bl: {
            x: objectLeft,
            y: objectTop + objHeight
        },
        br: {
            x: objectLeft + objWidth,
            y: objectTop + objHeight
        }
    }
}

/**
 * Checks if given object is contains the pointer.
 * @param {fabric.Object} object 
 * @param {{ x: number, y: number }} pointer 
 * @returns 
 */
export const isPointerContainedWithObject = (object, pointer) => {
    const objectCoords = calculateObjectPosition(object);
    
    return (
        pointer.x >= objectCoords.tl.x &&
        pointer.x <= objectCoords.tr.x &&
        pointer.y >= objectCoords.tr.y &&
        pointer.y <= objectCoords.br.y
    );
}

/**
 * Checks if given objectToBeChecked is inside given object.
 * Uses coordinates to check.
 * @param {fabric.Object} object 
 * @param {fabric.Object} objectToBeChecked 
 * @returns 
 */
const manualCheckForIsObjectInsideOfObject = (object, objectToBeChecked) => {
    const objectCoords = calculateObjectPosition(object);
    const objectToBeCheckedCoords = calculateObjectPosition(objectToBeChecked);

    if (
        objectCoords.tl.x <= objectToBeCheckedCoords.tl.x &&
        objectCoords.tl.y <= objectToBeCheckedCoords.tl.y &&
        objectCoords.tr.x >= objectToBeCheckedCoords.tr.x &&
        objectCoords.tr.y <= objectToBeCheckedCoords.tr.y &&
        objectCoords.bl.x <= objectToBeCheckedCoords.bl.x &&
        objectCoords.bl.y >= objectToBeCheckedCoords.bl.y &&
        objectCoords.br.x >= objectToBeCheckedCoords.br.x &&
        objectCoords.br.y >= objectToBeCheckedCoords.br.y
    ) {
        return true;
    }
    return false;
}

/**
 * Checks if given objectToBeChecked is inside given object.
 * @param {fabric.Object} object 
 * @param {fabric.Object} objectToBeChecked 
 * @param options
 */
export const isObjectInsideOfObject = (object, objectToBeChecked, options = {}) => {
    if (options.manualCheck) {
        return manualCheckForIsObjectInsideOfObject(object, objectToBeChecked);
    }
    try {
        return objectToBeChecked.isContainedWithinObject(object);
    } catch (err) {
        return manualCheckForIsObjectInsideOfObject(object, objectToBeChecked);
    }
}

/**
 * Checks if the given line point is inside of given object.
 * @param {object} objectCoords 
 * @param {object} linePointCoords 
 * @returns 
 */
export const isLinePointInsideOfObject = (objectCoords, linePointCoords) => {
    try {
        if (
            objectCoords.tl.x <= linePointCoords.tl.x &&
            objectCoords.tl.y <= linePointCoords.tl.y &&
            objectCoords.tr.x >= linePointCoords.tr.x &&
            objectCoords.tr.y <= linePointCoords.tr.y &&
            objectCoords.bl.x <= linePointCoords.bl.x &&
            objectCoords.bl.y >= linePointCoords.bl.y &&
            objectCoords.br.x >= linePointCoords.br.x &&
            objectCoords.br.y >= linePointCoords.br.y
        ) {
            return true;
        }
        return false;
    } catch (err) {
        return false;
    }
}

/**
 * Creates higlight rectangle for given object. This is useful for giving feedback to user
 * if the object is ready to be attached to another object (eg: frame).
 * @param {fabric.Canvas} canvas 
 * @param {fabric.Object} obj 
 */
export const createHighlightForObject = (canvas, obj) => {
    if (!obj.highlightActivated) {
        const activeObject = canvas.getActiveObject();
        let left = obj.left;
        let top = obj.top;

        // If multiple objects are being selected at once, that objects location should be calculated based on the active selection.
        if (activeObject?.type === 'activeSelection') {
            const matrix = activeObject.calcTransformMatrix();
            const points = fabric.util.transformPoint({ x: left, y: top }, matrix);
            left = points.x;
            top = points.y;
        }

        const highlightObject = new fabric.Rect({
            left: left,
            top: top,
            angle: obj.angle,
            width: obj.getScaledWidth(),
            height: obj.getScaledHeight(),
            fill: 'rgba(0, 0, 0, 0.2)',
            selectable: false,
            evented: false,
            originX: obj.originX,
            originY: obj.originY,
            hasControls: false,
            hasBorders: false,
            hasRotatingPoint: false,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            lockUniScaling: true,
            lockSkewingX: true,
            lockSkewingY: true,
            lockScalingFlip: true,
            hoverCursor: 'default',
            perPixelTargetFind: true,
            objectCaching: false,
            shapeType: HIGHLIGHT_TYPE,
            type: HIGHLIGHT_TYPE,
            objectToBeHighlighted: obj,
            isDynamic: true  // tells that we will use this instance only for mocking
        });
        canvas.add(highlightObject);

        obj.highlightActivated = true;
        obj.highlightObject = highlightObject;
    }
}

/**
 * Removes the highlight rectangle for given object(s).
 * @param {fabric.Canvas} canvas 
 * @param {fabric.Object} obj 
 */
export const clearHighlightForObject = (canvas, obj) => {
    const clear = (item) => {
        if (item.highlightActivated) {
            canvas.remove(item.highlightObject);
            item.highlightActivated = false;
            item.highlightObject = null;
        }
    }

    if (obj.highlightActivated) {
        clear(obj);
    } else if (obj.type === 'activeSelection') {
        obj.getObjects().forEach((o) => {
            clear(o);
        });
    }
}

/**
 * Creates single or grouped selection for given object(s).
 * @param {fabric.Canvas} canvas 
 * @param {fabric.Object[]} objects 
 */
export const createSelectionForObjects = (canvas, objects, isLassoSelection, options = {}) => {
    if (objects.length === 1) {
        canvas.discardActiveObject();
        canvas.setActiveObject(objects[0]);
    } else if (objects.length > 1) {
        let selection = new fabric.ActiveSelection(objects, {
            canvas: canvas,
            createdWithLasso: isLassoSelection,
            selectionCreatedBy: options?.createdBy
        });
        canvas.discardActiveObject();
        canvas.setActiveObject(selection);
    }
    canvas.renderAll();
}

/**
 * Locks or unlocks the given object. It arranges the control visibility of the object.
 * @param {fabric.Object} object - The object that will be locked or unlocked.
 * @param {boolean} isLocked - If true, object will be locked. If false, object will be unlocked.
 */
export const arrangeControlsForLockedObject = (object, isLocked) => {
    const data = {
        mt: !isLocked && !isTargetText(object),
        mb: !isLocked && !isTargetText(object),
        ml: !isLocked,
        mr: !isLocked,
        bl: !isLocked,
        br: !isLocked,
        tl: !isLocked,
        tr: !isLocked,
        rotate: !isLocked,
        connectorLeft: !isLocked,
        connectorTop: !isLocked,
        connectorRight: !isLocked,
        connectorBottom: !isLocked,
        mtr: false,  // this always should be false
    }

    if (object.type === 'frame') {
        data.rotate = false;
    } else if (object.type === 'table') {
        TABLE_NOT_ALLOWED_CONTROL_TYPES.forEach((key) => data[key] = false);

        if (Array.isArray(object.cells)) {
            object.selected = !isLocked;
            object.setAllCellsAsUnSelected();
        }
    }

    if (object.type === 'optimizedImage') {
        for (const key of middleControls) {
            data[key] = false;
        }
    }

    for (const key of expanseControls) {
        // if the object is active selection, set expanse controls
        if (object.type === 'activeSelection') {
            data[key] = !isLocked;
        } else {
            // otherwise, expanse controls should be hidden for all other objects
            data[key] = false;
        }
    }



    try {
        // since we need to write to _controlsVisibility property, we need to make it extensible if it is not
        if (!Object.isExtensible(object._controlsVisibility)) {
            object._controlsVisibility = {
                ...object._controlsVisibility,
            };
        }
    } catch (err) {
        console.error('Error happened', err);
    }
    object.setControlsVisibility(data);
}

/**
 * Arranges object's movement and properties for locking action.
 * @param {fabric.Object} object - The object that will be locked or unlocked.
 * @param {boolean} lockedState - If true, object will be locked. If false, object will be unlocked.
 */
export const toggleObjectLockState = (object, lockedState) => {
    if (isTargetLine(object)) {
        object.hasControls = !lockedState;
    }
    if(object.shapeType === 'textbox'){
        object.editable = !lockedState;
    } 
    object.lockMovementX = lockedState;
    object.lockMovementY = lockedState;
    object.isLocked = lockedState;

    // if object is not a group or line, then border should be arranged for the locking action
    if (object.type !== 'activeSelection' && !isTargetLine(object)) {
        object.hasBorders = !lockedState;
        if(!object.visible){
            object.hasBorders = false;
        }
    }
    object.hoverCursor = lockedState ? null : 'move';
}

/**
 * Modifies object's borders, movements, and controls for locking action.
 * @param {fabric.Object} object - The object that will be handled for locking action.
 * @param {boolean} isLocked - If true, object will be locked. If false, object will be unlocked.
 */
export const modifyObjectPropertiesForLocking = (object, isLocked) => {
    toggleObjectLockState(object, isLocked);
    arrangeControlsForLockedObject(object, isLocked);
}

const getObjectPropertiesAsHighlight = (object) => {
    return {
        left: object.left,
        top: object.top,
        width: object.getScaledWidth(),
        height: object.getScaledHeight(),
        fill: 'rgba(3, 138, 255, .5)',
        selectable: false,
        evented: false,
        hasControls: false,
        hasBorders: false,
        hasRotatingPoint: false,
        lockMovementX: true,
        lockMovementY: true,
        originX: object.originX,
        originY: object.originY,
        angle: object.angle,
        skewX: object.skewX,
        skewY: object.skewY,
        shapeType: DUPLICATE_SHADOW_OBJECT_TYPE
    }
}

/**
 * Creates a highlight rectangle for given object. This is for giving feedback to user
 * if the object is duplicating.
 * @param {fabric.Canvas} canvas 
 * @param {fabric.Object} object 
 */
export const createDuplicateShadowObject = (canvas, object) => {
    let duplicateShadowObj;
    if (object.type !== 'activeSelection') {
        duplicateShadowObj = new fabric.Rect({
            ...getObjectPropertiesAsHighlight(object),
        });
    } else {
        const objectsInSelection = [];
        object.forEachObject((obj) => {
            const singleDuplicateShadowObj = new fabric.Rect({
                ...getObjectPropertiesAsHighlight(obj),
                represents: obj.uuid
            });
            objectsInSelection.push(singleDuplicateShadowObj);
        });
        duplicateShadowObj = new fabric.Group(objectsInSelection, {
            left: object.left,
            top: object.top,
            width: object.getScaledWidth(),
            height: object.getScaledHeight(),
            selectable: false,
            evented: false,
            hasControls: false,
            hasBorders: false,
            hasRotatingPoint: false,
            lockMovementX: true,
            lockMovementY: true,
            originX: 'center',
            originY: 'center',
            shapeType: DUPLICATE_SHADOW_GROUP_TYPE
        });
    }

    canvas.add(duplicateShadowObj);
    object.duplicateShadowObj = duplicateShadowObj;
}


export const isTargetLine = (target) => {
    return target && target.type === 'curvedLine';
}

export const isTargetLocked = (target) => {
    return target.lockMovementX && target.lockMovementY;
}

export const isTargetIncludeText = (target) => {
    return target.shapeType === 'rect' || 
        target.shapeType === 'ellipse' || 
        target.shapeType === 'triangle' ||
        target.shapeType === 'sticky' || 
        target.shapeType === 'rhombus' ||
        target.shapeType === 'parallelogram'
}

export const isTargetHasText = (target) => {
    if (!isTargetIncludeText(target)) {
        return false;
    }

    const objects = target.getObjects();
    if (objects.length === 0) {
        return false;
    }

    const textbox = objects[1];

    return textbox?.text?.length > 0
}

export const isTargetImage = (target) => target.type === 'image' || target.type === 'optimizedImage';
export const isTargetText = (target) => target.type === 'textbox' || target.type === 'itext' || target.type === 'text';

export const isTargetAttachableToLine = (target) => isObjectValid(target) && (
    target.type === 'group' || target.type === 'textbox' || target.type === 'image' || target.type === 'optimizedImage'
) && (!target.collabLocked)

export const isObjectValidForAttachingLines = (object) => {
    return (object.shapeType === 'rect' || 
        object.shapeType === 'ellipse' || 
        object.shapeType === 'triangle' || 
        object.shapeType === 'sticky' ||
        object.shapeType === 'image' ||
        object.shapeType === 'optimizedImage' ||
        object.type === 'textbox' ||
        object.shapeType === 'rhombus' ||
        object.shapeType === 'parallelogram'
    );
}

export const arrangeTextInsideShape = (target) => {
    let text = target._objects[1],
        scale = text.scaleY < text.scaleX ? text.scaleY : text.scaleX,
        textInstHeight = text.height,
        textInstWidth = text.width,
        topPos = target.shapeType === 'triangle' ? text.top : 0;

    text.set({
        width: textInstWidth * scale / scale,
        height: textInstHeight * scale / scale,
        top: topPos
    })
}

// uuid regex pattern
export const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

// checks if given object has valid object
export const isObjectValid = (object) => {
    return object.uuid && uuidPattern.test(object.uuid);
}

export const isObjectValidForAttachingComment = (object) => {
    return (object.type && object.type === 'mockImage') || isObjectValid(object);
}

export const setDefaultLayerIdToObjects = (data, defaultLayerId) => {
    if(data?.shapeType && !NOT_ALLOWED_SHAPE_FOR_LAYER.includes(data.shapeType)){        
        data.layerId = defaultLayerId;
    }
    return data;
};

/**
 * Creates and returns a javascript object with all the properties of the given object .
 * @param {fabric.Object} object 
 */
export const createObjectProperties = (object) => {
    const removeFlowchartPropsFromData = (data) => {
        if (data.flowchartProps) {
            data.flowchartProps = null;
            data.constantFlowchartProps = null;
            data.flowchartDuplicated = true;
        }
        return data;
    }
    const objectData = removeFlowchartPropsFromData(customToObject(object));
    const lines = [];

    const generateDeeplyLinkedShapes = (object) => {
        return object.linkedShapes.map(o => {
            const objectData = removeFlowchartPropsFromData(customToObject(o));

            if (o.type !== 'frame') {
                return objectData
            } else {
                return {
                    ...objectData,
                    linkedShapes: o.linkedShapes.map(nestedObj => {
                        const nestedObjectData = removeFlowchartPropsFromData(customToObject(nestedObj));
                        return nestedObjectData
                    })
                }
            }
        });
    }

    if (object.type === 'frame') {
        generateLinkedShapes(object, { shouldGenerateForNestedFrames: true, includeObjectConnectors: true });
        objectData.linkedShapes = generateDeeplyLinkedShapes(object)
    } else if (object.type === 'activeSelection') {
        object._objects.forEach(obj => {
            if (obj.type === 'frame') {
                generateLinkedShapes(obj, { shouldGenerateForNestedFrames: true, includeObjectConnectors: true });
                objectData.objects = objectData.objects.map(o => {
                    if (o.shapeType === 'frame' && o.uuid === obj.uuid) {
                        if (o.flowchartProps) {
                            if (o.flowchartProps) {
                                o.flowchartProps = null;
                                o.constantFlowchartProps = null;
                                o.flowchartDuplicated = true;
                            }
                        }
                        return {
                            ...o,
                            linkedShapes: generateDeeplyLinkedShapes(obj)
                        }
                    }
                    return o
                })
            } else if (isTargetLine(obj)) {
                lines.push(obj);
            }
        }); 
    }
    return objectData;
}

export const calculateObjectPosition = (object, options = {}) => {

    const group = object.group;
    if (group) {
        const objectCenterPoint = object.getCenterPoint();
        const groupCenterPoint = group.getCenterPoint();
        const objectPosition = {
            tl: {
                x: objectCenterPoint.x + groupCenterPoint.x - object.getScaledWidth() / 2,
                y: objectCenterPoint.y + groupCenterPoint.y - object.getScaledHeight() / 2,
            },
            tr: {
                x: objectCenterPoint.x + groupCenterPoint.x + object.getScaledWidth() / 2,
                y: objectCenterPoint.y + groupCenterPoint.y - object.getScaledHeight() / 2,
            },
            bl: {
                x: objectCenterPoint.x + groupCenterPoint.x - object.getScaledWidth() / 2,
                y: objectCenterPoint.y + groupCenterPoint.y + object.getScaledHeight() / 2,
            },
            br: {
                x: objectCenterPoint.x + groupCenterPoint.x + object.getScaledWidth() / 2,
                y: objectCenterPoint.y + groupCenterPoint.y + object.getScaledHeight() / 2,
            },
        }
    
        return objectPosition;
    }
    return getObjectCoordinatesByOrigin(object, options);
}

export const calculateObjectCenterPoint = (object) => {
    const objectCenter = object.getCenterPoint();
    const centerPoint = {
        ...objectCenter
    }
    if (object.group) {
        const groupCenter = object.group.getCenterPoint();
        centerPoint.x += groupCenter.x;
        centerPoint.y += groupCenter.y;
    }
    return centerPoint;
}

/**
 * Makes given object unselectable.
 * @param {fabric.Object} object - Object that will be unselectable.
 */
export const makeObjectUnselectable = (object) => {
    // save previous evented and selectable values in order to restore them in select mode
    object.prevEvented = object.prevEvented ? object.prevEvented : object.evented;
    object.prevSelectable = object.prevSelectable ? object.prevSelectable : object.selectable;

    // make object unselectable
    object.selectable = false;
    object.evented = false;
}

/**
 * Handles locking movement of the given object.
 * @param {fabric.Object} object 
 * @param {boolean} shouldLockMovement - Should lock the movement of the object.
 */
export const setObjectMovement = (object, shouldLockMovement) => {
    object.lockMovementX = shouldLockMovement;
    object.lockMovementY = shouldLockMovement;
}

/**
 * Rotates a point around given points.
 * @param {float} x - Rotated x.
 * @param {float} y - Rotated y.
 * @param {float} cx - Center of rotation x.
 * @param {float} cy - Center of rotation y.
 * @param {float} angleInDegrees - Rotation angle in degrees.
 * @returns 
 */
export function rotatePoint(x, y, cx, cy, angleInDegrees) {
    // use radians
    const angleInRadians = angleInDegrees * (Math.PI / 180);
  
    // find the original position relative to the center
    let offsetX = x - cx;
    let offsetY = y - cy;
  
    // rotate back the point
    let rotatedX = offsetX * Math.cos(angleInRadians) - offsetY * Math.sin(angleInRadians);
    let rotatedY = offsetX * Math.sin(angleInRadians) + offsetY * Math.cos(angleInRadians);
  
    // move the point back to its original position
    let originalX = rotatedX + cx;
    let originalY = rotatedY + cy;
  
    return { x: originalX, y: originalY };
}


/**
 * Returns the shape of the active object.
 * @param {fabric.Object} target 
 * @returns 
 */
export function getShapeOfTheTarget(target) {
    try {
        let shape;
        if (
            target?.type === 'activeSelection' &&
            target._objects?.length > 0 &&
            target._objects.every((o) => o.type === target._objects[0].type)
        ) {
            shape = getShapeOfTheTarget(target._objects[0]);
        } else if (target?._objects) {
            shape = target._objects[0];
        }
        else shape = target;
        if (!shape) return;
        return shape;
    } catch (err) {
        console.error('error while getting shape of target object', err);
    }

    return null;
}

/**
 * @param shape
 * @param mainTarget
 */
export function getInnerColorOfShape(shape, mainTarget) {
    let innerColor = shape.fill === null ? 'rgba(0, 0, 0)' : shape.fill;
    if (isTargetLine(shape)) {
        innerColor = shape.stroke;
    } else if (mainTarget.type === 'textbox') {
        innerColor = mainTarget.backgroundColor;
    } else if (mainTarget.type === 'activeSelection' && mainTarget.getObjects().every((o) => o.type === 'textbox')) {
        innerColor = mainTarget.getObjects()[0]?.backgroundColor;
    }

    if (innerColor === 'transparent') innerColor = 'rgba(0, 0, 0, 0)';
    return innerColor;
}

/**
 * @param shape
 */
export function getHighlightColorOfText(shape) {
    if(shape.type === 'textbox') return getColorsOfTextWithSelection(0, shape.text.length, shape).textHighlightColor;
}

/**
 * @param shape
 */
export function getOuterColorOfShape(shape) {
    let outerColor;
    if (shape.type === 'textbox') outerColor = getColorsOfTextWithSelection(0, shape.text.length, shape).textColor;
    else outerColor = shape.stroke === null ? 'rgb(0, 0, 0)' : shape.stroke
    if (outerColor === 'transparent') outerColor = 'rgba(0, 0, 0, 0)';

    return outerColor;
}

export function getTextColorOfShape(shape) {
    if (shape.type === 'curvedLine') {
        return shape.textColor ? shape.textColor : 'rgba(0,0,0,1)';
    } else if (shape.type === 'textbox') {
        return getOuterColorOfShape(shape)
    } else if (shape.type === 'group') {
        if (shape?.isHtmlEditingMode) {
            const selectionStyles = HtmlEditorHelper.getSelectionStyles();

            if (selectionStyles?.color) {
                let color = Array.isArray(selectionStyles?.color) ? selectionStyles.color.pop() : selectionStyles.color;

                if (color) {
                    return (color.startsWith('#')) ? hexToRGBA(color) : color;
                }
            }
        } else {
            const textbox = shape?._objects[1];
            if (textbox && textbox?.type === 'textbox') return getOuterColorOfShape(textbox)
        }
    }
    return 'rgba(0,0,0,1)';
}

export function getShapeLayerInfo(target) {
    // include those shape on which we can not tag layer
    const notIncludedShapeTypeSet = new Set(NOT_ALLOWED_SHAPE_FOR_LAYER);

    // it means we have selected multiple shapes
    if (target.type === 'activeSelection') {
        const objects = target._objects;
        if (objects?.length) {
            const objectsIdsSet = new Set(objects.filter((obj) => !(notIncludedShapeTypeSet.has(obj.type ?? obj.shapeType)) && obj.layerId).map((o) => o.layerId));
            // all allowed shape have same layerId
            if (objectsIdsSet.size === 1) {
                return {
                    layerId: objectsIdsSet.values().next().value,
                };
            } else if (objectsIdsSet.size >= 1) {
                return {
                    // we are not going to show selected layer as shape belong to different layers
                    layerId: null,
                };
            } else {
                // empty else
            }
        }
        // make btn disable as group contain shape on which we are not allowed to tag a layer
        return {
            disableShowLayerListBtn: true,
        };
    } else if (target.type == 'frame') {
        const attachedObjects = getFrameAttachedShapes(target, true)?.filter(obj=>!notIncludedShapeTypeSet.has( obj.shapeType)) ?? [];
        // we have shape attach to frame
        if (attachedObjects.length) {
            // contain those shapes on which we can tag layer
            const objectsIdsSet = new Set(
                attachedObjects.map((o) => o.layerId)
            );
            // all allowed shape have same layerId
            if (objectsIdsSet.size === 1) {
                return {
                    layerId: objectsIdsSet.values().next().value,
                };
            } else if (objectsIdsSet.size >= 1) {
                return {
                    // we are not going to show selected layer as shape belong to different layers
                    layerId: null,
                };
            } else {
                // empty else
            }
        }
        // make btn disable as we not have any attached shape to frame or frame non allowed tag layer shape
        return {
            disableShowLayerListBtn: true,
        };
    } else if (!notIncludedShapeTypeSet.has(target.type ?? target.shapeType)) {
        return { layerId: target.layerId};
    } else {
        return {
            layerId: null,
            disableShowLayerListBtn: true,
        };
    }
}

/**
 * @param evt
 * @param canvas
 */
export function adjustTextBoxWhileTyping(evt, canvas) {
    if (evt.target instanceof fabric.IText) {
        const lines = evt.target.textLines;
        const lastLine = lines[lines.length - 1];

        const canvasContext = canvas.getContext();
        canvasContext.font = evt.target.fontFamily + ' ' + evt.target.fontSize + 'px ' + evt.target.fontWeight;
        const lastLineWidth = canvasContext.measureText(lastLine).width;
        if (lastLineWidth > 300 && evt.target.getScaledWidth() < 300 * evt.target.scaleX) {
            const words = lastLine.split(' ');
            let currentLine = '';
            let newLines = lines.slice(0, -1);

            for (const word of words) {
                const testLine = currentLine + word + ' ';
                const testWidth = canvasContext.measureText(testLine).width;

                if (testWidth > 300) {
                    newLines.push(currentLine.trim());
                    currentLine = word + ' ';
                } else {
                    currentLine = testLine;
                }
            }

            newLines.push(currentLine.trim());
            evt.target.set({ text: newLines.join('\n') });
        } else {
            const scale = evt.target.scaleX;
            const text = evt.target.text || ''
            while (evt.target.textLines.length > text.split('\n').length && evt.target.getScaledWidth() < 300 * scale) {
                evt.target.set({ width: evt.target.width + 1 })
            }
        }
    }
}

export const applyNewStyleForTextBox = (shape, newStyle) => {
    // get the selection start and end to apply the style
    let selectionStart = 0, selectionEnd = 0;
    // if the textbox is in editing mode, get the selection start and end from the hidden textarea
    if (shape.hiddenTextarea) {
        selectionStart = shape.selectionStart;
        selectionEnd = shape.selectionEnd;
    } else {
        selectionStart = 0;
        selectionEnd = shape.text.length;
    }

    if (selectionStart === selectionEnd) {
        console.error('select text to toggle style');
        return false;
    }
    shape.setSelectionStyles(newStyle, selectionStart, selectionEnd);
} 

export const changeTextSizeForStickyAndShape = (target, val) => {
    const textObject = target.getObjects()[1];
    textObject.set({ fontSize: val });
    return true;
}

export const changeTextSizeForTextBox = (target, val) => {
    const scale = val / getCurrentTextSize(target);
    target.set({ fontSize: val, width: target.getScaledWidth() * scale, height: target.getScaledHeight() * scale });
    target.set({ scaleX: 1, scaleY: 1 });
}

export const checkIfStickyOrShapeContainText = (target)=>{
    let objects = target.getObjects();
    for (let i = 0 ; i < objects.length; i ++){
        if(objects[i].type ==='textbox' && objects[i].text.length >0) return true;
    }
    return  false;
}
export const getCurrentTextSize = (target)=>{
    if(isTargetLine(target)) return target.textFontSize;
    if(target.type ==='textbox') {
        const textSize = target.fontSize;
        const scale = target.scaleX;
        return Math.round(textSize * scale);
    }
    if (target.type === 'table' && target.isCellSelected) {
        const cell = target.getSelectedCell();
        return Math.round(cell.fontSize);
    }
    if (target.shapeType === 'sticky' ||
        target.shapeType === 'rect' ||
        target.shapeType === 'ellipse' ||
        target.shapeType === 'triangle' ||
        target.shapeType === 'rhombus' ||
        target.shapeType === 'parallelogram') {
        let scaleRect = 1, textFontSize = 0;
        let objects = target.getObjects();
        for (let i = 0; i < objects.length; i++) {
            if (objects[i].type === 'textbox') {
                textFontSize = getCurrentTextSize(objects[i]);
            }
            else {scaleRect = objects[i].scaleX}
        }
        return Math.round(textFontSize/scaleRect);
    }
}

export const getColorsOfTextWithSelection = (startSelection, endSelection, text) =>{
    let shortenTextCb = null;
    if (text.isTextShortened) {
        shortenTextCb = text.extendShortenedText();
    }

    let textHighlightColor = 'rgba(255, 255, 255, 1)';
    let textColor = text.fill;
    text.getSelectionStyles(startSelection, endSelection).forEach(style =>{
        textHighlightColor = style.textBackgroundColor ? style.textBackgroundColor : 'rgba(255, 255, 255, 1)';
        textColor = style.fill ? style.fill : text.fill;
    });
    // if the placeholder is enabled, use default color
    if (text.enablePlaceholder) {
        textColor = SHAPE_DEFAULTS.TEXT_COLOR;
    }
    if (!textColor?.startsWith('rgba')) {
        textColor = SHAPE_DEFAULTS.TEXT_COLOR;
    }

    if (shortenTextCb) shortenTextCb();
    return {textHighlightColor, textColor};
}
export const shouldAllowActionForActiveSelection = (activeObject) => {
    if (activeObject?.isAllLocked || activeObject?.isMixedLockState) return false;
    return true;
}

/**
 * Returns the object corrdinates from bounding rect.
 * @param {fabric.Object} object 
 * @param {number} padding - Padding.
 */
export const getBoundingCoordinates = (object, padding = 0) => {
    const boundingRect = object.getBoundingRect(true);
    if (object?.type === 'curvedLine') {
        boundingRect.width = object.width;
        boundingRect.height = object.height;
    }
  
    return {
        tl: {
            x: boundingRect.left - padding,
            y: boundingRect.top - padding
        },
        tr: {
            x: boundingRect.left + boundingRect.width + padding,
            y: boundingRect.top - padding
        },
        bl: {
            x: boundingRect.left - padding,
            y: boundingRect.top + boundingRect.height + padding
        },
        br: {
            x: boundingRect.left + boundingRect.width + padding,
            y: boundingRect.top + boundingRect.height + padding
        },
        center: {
            x: boundingRect.left + (boundingRect.width / 2),
            y: boundingRect.top + (boundingRect.height / 2),
        },
        width: boundingRect.width,
        height: boundingRect.height
    }
}
  

/**
 * Checks if the coordinates are colliding .
 * @param {object} coordinates1 
 * @param {object} coordinates2 
 * @returns 
 */
export const isCoordinatesColliding = (coordinates1, coordinates2) => {
    return !(
        coordinates1.br.x < coordinates2.tl.x ||
        coordinates1.tl.x > coordinates2.br.x ||
        coordinates1.br.y < coordinates2.tl.y ||
        coordinates1.tl.y > coordinates2.br.y
    );
}

/**
 * Finds an empty space with the given object properties.
 * @param canvas
 * @param {object} objectProperties
 * @param {number} objectProperties.width - Width of the object.
 * @param {number} objectProperties.height - Height of the object.
 * @param {object} options
 * @param {object} options.initiallyDesiredPosition - Desired position of the object.
 * @param {number} options.initiallyDesiredPosition.x - Desired x position of the object.
 * @param {number} options.initiallyDesiredPosition.y - Desired y position of the object.
 * @returns {object}
 */
export function findEmptyAreaForObject(canvas, objectProperties = {}, options = {}) {
    const { initiallyDesiredPosition } = options;
  
    /**
     * @param desiredPosition
     */
    function isColliding(desiredPosition) {
        const desiredObjectPosition = {
            tl: {
                x: desiredPosition.x - objectProperties.width / 2,
                y: desiredPosition.y - objectProperties.height / 2
            },
            tr: {
                x: desiredPosition.x + objectProperties.width / 2,
                y: desiredPosition.y - objectProperties.height / 2
            },
            bl: {
                x: desiredPosition.x - objectProperties.width / 2,
                y: desiredPosition.y + objectProperties.height / 2
            },
            br: {
                x: desiredPosition.x + objectProperties.width / 2,
                y: desiredPosition.y + objectProperties.height / 2
            }
        };
  
        return canvas.getObjects().some(object => {
            const objectPosition = getBoundingCoordinates(object);
            return isCoordinatesColliding(desiredObjectPosition, objectPosition);
        });
    }
  
    let desiredPosition = { ...initiallyDesiredPosition };
  
    // Keep searching in a spiral pattern until an empty area is found
    while (isColliding(desiredPosition)) {
        const newDesiredPositionRight = {
            x: desiredPosition.x,
            y: desiredPosition.y
        }
        
        // try right
        newDesiredPositionRight.x += objectProperties.width;
        if (!isColliding(newDesiredPositionRight)) {
            return newDesiredPositionRight;
        } else {
            newDesiredPositionRight.x -= objectProperties.width;
        }

        // try left
        newDesiredPositionRight.x -= objectProperties.width;
        if (!isColliding(newDesiredPositionRight)) {
            return newDesiredPositionRight; 
        } else {
            newDesiredPositionRight.x += objectProperties.width;
        }

        // try down
        newDesiredPositionRight.y += objectProperties.height
        if (!isColliding(newDesiredPositionRight)) {
            return newDesiredPositionRight;
        } else {
            newDesiredPositionRight.y -= objectProperties.height;
        }

        // try up
        newDesiredPositionRight.y -= objectProperties.height;
        if (!isColliding(newDesiredPositionRight)) {
            return newDesiredPositionRight;
        } else {
            newDesiredPositionRight.y += objectProperties.height;
        }


        // if still they are colliding, move direction to right in order to find an empty space
        desiredPosition.x += objectProperties.width;
    }
  
    return desiredPosition;
}

/**
 * Returns the object index in the canvas.
 * @param {fabric.Object[]} objects 
 * @param {fabric.Object} obj 
 * @returns 
 */
export const getObjectIndex = (objects, obj) => {
    try {
        return objects.indexOf(obj);
    } catch (err) {
        return null;
    }
}

/**
 * Checks if object1 is above or below object2 in the canvas.
 * @param {fabric.Object[]} objects 
 * @param {fabric.Object} object1 
 * @param {fabric.Object} object2 
 * @param {boolean=false} isUp 
 * @returns 
 */
export const compareObjectPositionInStack = (objects, object1, object2, isUp = false) => {
    const object1Index = getObjectIndex(objects, object1);
    const object2Index = getObjectIndex(objects, object2);

    if (isUp) {
        return object1Index > object2Index;
    } else {
        return object1Index < object2Index;
    }
}

/**
 * Makes the canvas objects selectable or unselectable.
 * @param {fabric.Canvas} canvas 
 * @param {boolean} isSelectable 
 */
export const changeObjectsSelectableProp = (canvas, isSelectable) => {
    canvas.getObjects().map(item => {
        return item.set({
            selectable: isSelectable
        });
    });
}

/**
 * Returns intersection point of two lines.
 * @param {*} s1 // line1 -> point1 
 * @param {*} s2 // line1 -> point2
 * @param {*} d1 // line2 -> point1
 * @param {*} d2  // line2 -> point2
 * @returns {fabric.Point}
 */
export const calculateIntersectionOfTwoLines = (s1, s2, d1, d2) => {
    const a1 = s2.y - s1.y;
    const b1 = s1.x - s2.x;
    const c1 = a1 * s1.x + b1 * s1.y;

    const a2 = d2.y - d1.y;
    const b2 = d1.x - d2.x;
    const c2 = a2 * d1.x + b2 * d1.y;

    const delta = a1 * b2 - a2 * b1;
    return new fabric.Point(((b2 * c1 - b1 * c2) / delta), ((a1 * c2 - a2 * c1) / delta));
}

/**
 * Finds and returns the textbox shape inside a group class.
 * @param {fabric.Object} target Target that textbox will be searched in.
 * @returns {fabric.Object|null} If textbox is found, returns textbox, otherwise returns null.
 */
export const getTextShapeFromGroup = (target) => {
    if (!target || target?.type !== 'group') {
        return null
    }
    const objects = target.getObjects()
    if (!objects.length) {
        return null
    }
    return objects.find(o => o.type === 'textbox')
}

/**
 * Returns selection area's text color with checking hidden text area.
 * @param {fabric.Textbox} textbox Textbox that will the color be retrieved.
 */
export const getSelectionTextColorFromText = (textbox) => {
    let selectionStart = 0, selectionEnd = 0;
    // if the textbox is in editing mode, get the selection start and end from the hidden textarea
    if (textbox.hiddenTextarea) {
        selectionStart = textbox.selectionStart;
        selectionEnd = textbox.selectionEnd;
    } else {
        selectionStart = 0;
        selectionEnd = textbox.text.length;
    }
    
    const res = getColorsOfTextWithSelection(selectionStart, selectionEnd, textbox);
    return res;
}

/**
 * Truncates the given coordinate if it includes 'e'.
 * @param {fabric.Object} object Object to be truncated.
 * @param {'left'|'top'} coordinate Coordinate to be truncated.
 * @private
 */
const _truncateLongPosition = (object, coordinate) => {
    try {
        const value = object[coordinate]
        const strValue = value.toString()
        const eIndex = strValue.indexOf('e')
        if (eIndex === -1) {
            return
        }
        object[coordinate] = parseFloat(strValue.substring(0, eIndex))
    } catch (err) {
        console.error('error in truncating', err)
    } 
}

/**
 * Truncates long positions of the given object.
 * @param {fabric.Object} object Object to be truncated.
 */
export const truncateLongPositions = (object) => {
    _truncateLongPosition(object, 'left')
    _truncateLongPosition(object, 'top')
}

/**
 * Returns the bounding box of the canvas objects.
 * @param {fabric.Canvas} canvas The canvas object that contains the objects.
 * @param options.includeVisibleOnly include only visible elements
 * @returns {{top: number, left: number, bottom: number, objects: *, width: number, right: number, height: number}|null} Returns the bounding box of the canvas objects.
 */
export const getCanvasObjectsBbox = (canvas, options={}) => {
    let objects = canvas.getObjects();
    if (options.includeVisibleOnly) {
        objects = objects?.filter((o) => o.visible);
    }
    if (!objects || !objects.length) {
        return null
    }

    const points = [];
    // Get all objects points to find left, right, top and bottom positions of all objects.
    objects.forEach((obj) => {
        let point;
        if (['comment', 'commentRefWrapper'].includes(obj.type)) {
            point = [{
                x: obj.left,
                y: obj.top,
                type: 'comment'
            }];
        } else {
            point = [
                obj.getPointByOrigin('left', 'top'),
                obj.getPointByOrigin('right', 'bottom'),
            ];
        }

        points.push(...point);
    });

    const x = points.map((point) => point.x);
    const y = points.map((point) => point.y);
    const xObjects = points.filter(point => point.type !== 'comment').map(point => point.x)
    const yObjects = points.filter(point => point.type !== 'comment').map(point => point.y)

    // bbox for all objects
    const left = Math.min(...x);
    const right = Math.max(...x);
    const top = Math.min(...y);
    const bottom = Math.max(...y);

    // Pan the entire canvas so that the active object is centered in the viewport.
    // The object's relative position in the canvas should not change.
    const objWidth = Math.abs(right - left);
    const objHeight = Math.abs(bottom - top);
    
    // bbox for only shapes, here 'o' stand for objects
    const oLeft = Math.min(...xObjects);
    const oRight = Math.max(...xObjects);
    const oTop = Math.min(...yObjects);
    const oBottom = Math.max(...yObjects);
    
    const shapesObjWidth = Math.abs(oRight - oLeft);
    const shapesObjHeight = Math.abs(oBottom - oTop);
    
    return {
        left,
        right,
        top,
        bottom,
        width: objWidth,
        height: objHeight,
        oLeft,
        oRight,
        oTop,
        shapesObjWidth,
        shapesObjHeight,
        oBottom,
        objects
    }
}

/**
 * Finds the editing objects with the given object.
 * @param {fabric.Canvas} canvas Canvas to check shapes.
 * @param {fabric.Object} object Shape to be checked.
 * @returns {fabric.Object[]} Editing shapes along with the given object.
 */
export function getEditingShapes(canvas, object) {
    if (object.type === 'activeSelection') {
        return object.getObjects().filter(o => o.type !== 'lasso');
    } else if (object.type === 'frame') {
        const frameObjects = [];
        for (const o of canvas.getObjects()) {
            if (o !== object && o.wiredFrame && o.wiredFrame.uuid === object.uuid) {
                if (o.type !== 'frame') {
                    frameObjects.push(o);
                } else {
                    frameObjects.push(...getEditingShapes(canvas, o));
                }
            }
        }
        return [object, ...frameObjects];
    }
    return [object];
}


/**
 * Returns the object that will be changed with given props in the group.
 * @param {fabric.Group} groupObject Group object whose children type are needed.
 * @param {string[]} methods Identifier for edit action.
 * @returns {string[]} Types of the children of the given group.
 */
export function getEditingObjectInGroup(groupObject, methods) {
    const editingChildTypes = new Set();
    if (
        methods.some(method => Object.values(EDITING_METHODS_FOR_TEXT).includes(method)) ||
        methods.includes(EDITING_METHODS.COLOR_TEXT) ||
        methods.includes(EDITING_METHODS.DIMENSION) ||
        methods.includes(EDITING_METHODS.CHANGE_SHAPE_TYPE)
    ) {
        editingChildTypes.add('textbox')
    }
    
    if (
        methods.includes(EDITING_METHODS.COLOR_OUTER) || 
        methods.includes(EDITING_METHODS.COLOR_INNER) ||
        methods.includes(EDITING_METHODS.DIMENSION) ||
        methods.includes(EDITING_METHODS.CHANGE_SHAPE_TYPE)
    ) {
        const childObject = groupObject.getObjects().find(o => o.type !== 'textbox')
        editingChildTypes.add(childObject.type)
    }
    
    return Array.from(editingChildTypes)
}

export const setObjectProperty = (obj, key, value) => {
    obj[key] = value;
};
