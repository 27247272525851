import {createFabricInstance, getEditingObjectInGroup, customToObject} from '../../FabricMethods';
import {EDITING_METHODS, LINE_POLYGON_POINTS} from '../../Constant';
import {deepClone} from '../../CommonUtils';
import {moveObjectLines} from '../../lines/LineMethods';

const KEY_OBJECTS = 'objects';
const VAL_TEXTBOX = 'textbox';

// TODO: update this partial state object
/**
 * Type for a partial state. 
 * @typedef {object} PartialState
 * @property {number|null} width Width of the shape.
 * @property {number|null} height Height of the shape.
 * @property {number|null} scaleX Scaled in X.
 * @property {number|null} scaleY Scaled in Y.
 * @property {number|null} left Position left.
 * @property {number|null} top Position top.
 * @property {boolean|null} hasHyperlink True if object has hyperlink.
 * @property {string|null} text The text of the textbox.
 * @property {object|null} styles The styles of the textbox.
 * @property {string|null} type Type of the child objects.
 * @property {PartialState[]|null} objects The objects array for groups.
 */


/**
 * Captures partial state with the given methods. It is useful for determining changed values.
 * @param {string} methods Method of editing process. This can be multiple string like 'edit1', 'edit2'.
 * @returns {PartialState} Captured partial state.
 */
export function captureState(...methods) {
    let state = {
        uuid: this.uuid
    }
    
    let calculatedQRDecompose;

    if (this.type === 'group') {
        const objects = getEditingObjectInGroup(this, methods)
        if (objects?.length) {
            for (const obj of objects) {
                state = {
                    ...state,
                    objects: {
                        ...state.objects,
                        [obj]: {}
                    }
                }
            }
        }
    }

    if (this.group && methods.some(method =>
        method === EDITING_METHODS.MOVE ||
        method === EDITING_METHODS.DIMENSION ||
        method === EDITING_METHODS.ROTATE
    )) {
        calculatedQRDecompose = this.getCalculatedQRDecompose(this.group)
    }
    
    const updateState = (values, options = {}) => {
        if (options.hasOwnProperty('key') && options.key && options.hasOwnProperty('value')) {
            state = {
                ...state,
                [options.key]: {
                    ...state[options.key],
                    [options.value]: {
                        ...state[options.key][options.value],
                        ...values
                    }
                }
            }
        } else {
            state = {
                ...state,
                ...values
            }
        }
    }
    
    const getGroupTextbox = (group) => {
        if (group.editingTextObj) {
            return group.editingTextObj;
        }
        return group.getObjects().find(obj => obj.type === 'textbox');
    }

    methods.forEach(method => {
        if (method === EDITING_METHODS.TEXT) {
            if (this.type === 'group') {
                const textboxObject = getGroupTextbox(this);
                const updates = {
                    text: textboxObject.text
                }
                updateState(updates, {key: KEY_OBJECTS, value: VAL_TEXTBOX});
            } else if (this.type === 'textbox') {
                updateState({
                    text: this.text,
                    enablePlaceholder: this.enablePlaceholder
                })
            } else if (this.type === 'table') {
                updateState({
                    width: this.width,
                    height: this.height,
                    scaleX: this.scaleX,
                    scaleY: this.scaleY,
                    rows: this.rows,
                    cols: this.cols,
                    cells: this.cloneCells()
                }) 
            }
        } else if (method === EDITING_METHODS.TEXT_FONT_STYLE) {
            if (this.type === 'group') {
                const textboxObject = getGroupTextbox(this);
                const updates = {
                    fontStyle: textboxObject?.fontStyle,
                    fontWeight: textboxObject?.fontWeight,
                    underline: textboxObject?.underline,
                    overline: textboxObject?.overline,
                    linethrough: textboxObject?.linethrough,
                    styles: deepClone(textboxObject?.styles)
                }
                updateState(updates, {key: KEY_OBJECTS, value: VAL_TEXTBOX});
            } else if (this.type === 'textbox') {
                const updates = {
                    fontStyle: this.fontStyle,
                    fontWeight: this.fontWeight,
                    underline: this.underline,
                    overline: this.overline,
                    linethrough: this.linethrough,
                    styles: deepClone(this.styles)
                }
                updateState(updates);
            } else if (this.type === 'table') {
                updateState({
                    width: this.width,
                    height: this.height,
                    scaleX: this.scaleX,
                    scaleY: this.scaleY,
                    rows: this.rows,
                    cols: this.cols,
                    cells: this.cloneCells()
                })
            }
        } else if (method === EDITING_METHODS.TEXT_ALIGN) {
            if (this.type === 'textbox') {
                updateState({
                    textAlign: this.textAlign
                })
            } else if (this.type === 'group') {
                const textboxObject = getGroupTextbox(this);
                const updates = {
                    textAlign: textboxObject.textAlign
                }
                updateState(updates, {key: KEY_OBJECTS, value: VAL_TEXTBOX});
            } else if (this.type === 'table') {
                updateState({
                    cells: this.cloneCells()
                })
            }
        } else if (method === EDITING_METHODS.TEXT_HYPERLINK) {
            updateState({
                hasHyperlink: this.hasHyperlink
            })
            
            if (this.type === 'group') {
                const textboxObject = getGroupTextbox(this);
                const updates = {
                    styles: deepClone(textboxObject?.styles)
                }
                updateState(updates, {key: KEY_OBJECTS, value: VAL_TEXTBOX});
            } else if (this.type === 'textbox') {
                updateState({
                    styles: deepClone(this.styles)
                })
            } else if (this.type === 'table') {
                updateState({
                    cells: this.cloneCells()
                })
            }
        } else if (method === EDITING_METHODS.TEXT_FONT_SIZE) {
            if (this.type === 'group') {
                const textboxObject = getGroupTextbox(this);
                updateState({
                    fontSize: textboxObject.fontSize
                }, {key: KEY_OBJECTS, value: VAL_TEXTBOX});
            } else if (this.type === 'curvedLine') {
                updateState({
                    textFontSize: this.textFontSize
                })
            } else if (this.type === 'textbox') {
                updateState({
                    fontSize: this.fontSize,
                    width: this.width,
                    height: this.height,
                    scaleX: this.scaleX,
                    scaleY: this.scaleY
                })
            } else if (this.type === 'table') {
                updateState({
                    width: this.width,
                    height: this.height,
                    scaleX: this.scaleX,
                    scaleY: this.scaleY,
                    rows: this.rows,
                    cols: this.cols,
                    cells: this.cloneCells()
                })
            }
        } else if (method === EDITING_METHODS.TEXT_TITLE) {
            if (this.type === 'frame' || this.type === 'optimizedImage' || this.type === 'curvedLine' ) {
                updateState({
                    text: this.text
                })
            } else if (this.type === 'table') {
                updateState({
                    title: this.title
                })
            }
        } else if (method === EDITING_METHODS.TEXT_MOVE) {
            if (this.type === 'group') {
                const textboxObject = getGroupTextbox(this);
                updateState({ left: textboxObject.left, top: textboxObject.top }, {key: KEY_OBJECTS, value: VAL_TEXTBOX});
            }
        } else if (method === EDITING_METHODS.LINE_TYPE) {
            updateState({
                lineType: this.lineType,
                curvedLineVersion: this.curvedLineVersion,
                points: deepClone(this.points)
            })
        } else if (method === EDITING_METHODS.LINE_DASH) {
            updateState({
                strokeDashArray: this.strokeDashArray,
            })
        } else if (method === EDITING_METHODS.LINE_ARROW_TYPE) {
            updateState({
                arrowEnabled: this.arrowEnabled,
                arrowLeft: this.arrowLeft,
                arrowRight: this.arrowRight
            })
        } else if (method === EDITING_METHODS.LINE_THICKNESS) {
            updateState({
                initialStrokeWidth: this.initialStrokeWidth,
                strokeWidth: this.strokeWidth,
            })
        } else if (method === EDITING_METHODS.LINE_POINT_UPDATE) {
            updateState({
                points: deepClone(this.points)
            })
        } else if (method === EDITING_METHODS.LINE_POINT_UPDATE_IN_GROUP) {
            // Calculates line endpoint coordinates without modifying them, unlike setLinePointsForCurrentPosition().
            // We need these coordinates to sync with other users but don't want to actually modify the line,
            // which could push it outside the group's bounding box.

            const points = deepClone(this.points);
            let left = this.left, top = this.top;
            if (this.group) {
                left = calculatedQRDecompose.left;
                top = calculatedQRDecompose.top;
            }

            for (const pointIndex in points) {
                const point = points[pointIndex];
                const actualPoint = {
                    x: left + point.x - this.pathOffset.x,
                    y: top + point.y - this.pathOffset.y
                }
                points[pointIndex] = {
                    x: actualPoint.x,
                    y: actualPoint.y
                }
            }

            updateState({
                points
            })
        } else if (method === EDITING_METHODS.COLOR_OUTER) {
            if (this.type === 'textbox') {
                updateState({
                    fill: this.fill,
                    styles: deepClone(this.styles)
                })
            } else if (this.type === 'group') {
                const object = this.getObjects().find(o => o.type !== 'textbox')
                updateState({
                    stroke: object.stroke,
                }, {key: KEY_OBJECTS, value: object.type})
            } else {
                updateState({
                    stroke: this.stroke,
                })
            }
        } else if (method === EDITING_METHODS.COLOR_INNER) {
            if (this.type === 'group') {
                const object = this.getObjects().find(o => o.type !== 'textbox')

                updateState({
                    isTextColorApplied: this.isTextColorApplied
                })

                updateState({
                    fill: object.fill,
                }, {key: KEY_OBJECTS, value: object.type})

                const textbox = getGroupTextbox(this)
                updateState({
                    fill: textbox.fill,
                    styles: deepClone(textbox.styles),
                }, {key: KEY_OBJECTS, value: VAL_TEXTBOX})
            } else if (this.type === 'curvedLine') {
                updateState({
                    stroke: this.stroke
                })
            } else if (this.type === 'textbox') {
                updateState({
                    backgroundColor: this.backgroundColor
                })
            } else {
                updateState({
                    fill: this.fill
                })
            }
        } else if (method === EDITING_METHODS.COLOR_TEXT) {
            if (this.type === 'group') {
                updateState({
                    isTextColorApplied: this.isTextColorApplied
                })

                const textbox = getGroupTextbox(this);
                updateState({
                    fill: textbox.fill,
                    styles: deepClone(textbox.styles)
                }, {key: KEY_OBJECTS, value: VAL_TEXTBOX})
            } else if (this.type === 'curvedLine') {
                updateState({
                    textColor: this.textColor
                })
            }
        } else if (method === EDITING_METHODS.COLOR_HIGHLIGHT) {
            if (this.type === 'textbox') {
                updateState({
                    styles: deepClone(this.styles)
                })
            }
        } else if (method === EDITING_METHODS.DIMENSION) {
            const updatingState = {
                width: this.width,
                height: this.height,
                scaleX: this.scaleX,
                scaleY: this.scaleY,
            }
            
            if (this.group && calculatedQRDecompose) {
                updatingState.scaleX = calculatedQRDecompose.scaleX;
                updatingState.scaleY = calculatedQRDecompose.scaleY;
            }
            
            updateState(updatingState)
            
            if (this.type === 'group') {
                const groupObjects = this.getObjects()
                for (let obj of groupObjects) {
                    if (obj.type === 'ellipse') {
                        updateState({
                            rx: obj.rx,
                            ry: obj.ry,
                            scaleX: obj.scaleX,
                            scaleY: obj.scaleY,
                        }, { key: KEY_OBJECTS, value: obj.type })
                    } else {
                        if (obj.type === 'textbox' && this.editingTextObj) {
                            obj = this.editingTextObj
                        }
                        updateState({
                            width: obj.width,
                            height: obj.height,
                            scaleX: obj.scaleX,
                            scaleY: obj.scaleY,
                        }, { key: KEY_OBJECTS, value: obj.type }) 
                    }
                }
            }
        } else if ([EDITING_METHODS.LOCK, EDITING_METHODS.LOCK_AVOID_FOOTSTEP].includes(method)) {
            const lockingState = {
                lockMovementX: this.lockMovementX,
                lockMovementY: this.lockMovementY,
                isLocked: this.isLocked,
                hoverCursor: this.hoverCursor,
                _controlsVisibility: deepClone(this._controlsVisibility),
                selectable: this.selectable,
                dontCreateFootstep: false
            }
            
            if (this.type === 'curvedLine') {
                lockingState.hasControls = this.hasControls;
            } else {
                lockingState.hasBorders = this.hasBorders;
            }

            if (method === EDITING_METHODS.LOCK_AVOID_FOOTSTEP) {
                lockingState.dontCreateFootstep = true;
            }
            
            updateState(lockingState)
        } else if ([EDITING_METHODS.MOVE_AVOID_FOOTSTEP, EDITING_METHODS.MOVE].includes(method)) {
            let left = this.left, top = this.top, dontCreateFootstep = false;
            
            if (this.group && calculatedQRDecompose) {
                left = calculatedQRDecompose.left
                top = calculatedQRDecompose.top;
            }

            if (method === EDITING_METHODS.MOVE_AVOID_FOOTSTEP) {
                dontCreateFootstep = true;
            }
            
            updateState({
                left,
                top,
                dontCreateFootstep
            })
        } else if (method === EDITING_METHODS.ROTATE) {
            let angle = this.angle;
            if (this.group && calculatedQRDecompose) {
                angle = calculatedQRDecompose.angle;
            }
            
            updateState({
                angle,
            })
        } else if (method === EDITING_METHODS.ATTACHMENT_LINE) {
            updateState({
                lines: Array.isArray(this.lines) ? Array.from(this.lines) : [],
                dontCreateFootstep: true
            })
        } else if ([EDITING_METHODS.ATTACHMENT_FRAME_AVOID_FOOTSTEP, EDITING_METHODS.ATTACHMENT_FRAME].includes(method)) {
            let dontCreateFootstep = false;

            if (method === EDITING_METHODS.ATTACHMENT_FRAME_AVOID_FOOTSTEP) {
                dontCreateFootstep = true;
            }

            updateState({
                attachedFrameId: this.attachedFrameId,
                calculatedPos: deepClone(this.calculatedPos),
                dontCreateFootstep
            })
        } else if ([
            EDITING_METHODS.LINE_POLYGON_LEFT,
            EDITING_METHODS.LINE_POLYGON_LEFT_AVOID_FOOTSTEP
        ].includes(method)) {
            if (this.type === 'curvedLine') {
                updateState({
                    leftRelativeX: this.leftRelativeX,
                    leftRelativeY: this.leftRelativeY,
                    leftPolygon: this.leftPolygon?.uuid,
                    dontCreateFootstep: method === EDITING_METHODS.LINE_POLYGON_LEFT_AVOID_FOOTSTEP
                })
            }
        } else if ([
            EDITING_METHODS.LINE_POLYGON_RIGHT,
            EDITING_METHODS.LINE_POLYGON_RIGHT_AVOID_FOOTSTEP
        ].includes(method)) {
            if (this.type === 'curvedLine') {
                updateState({
                    rightRelativeX: this.rightRelativeX,
                    rightRelativeY: this.rightRelativeY,
                    rightPolygon: this.rightPolygon?.uuid,
                    dontCreateFootstep: method === EDITING_METHODS.LINE_POLYGON_RIGHT_AVOID_FOOTSTEP
                })
            }
        } else if (method === EDITING_METHODS.FRAME_ATTACHMENTS) {
            if (this.type === 'frame') {
                updateState({
                    attachments: Array.isArray(this.attachments) ? Array.from(this.attachments) : [],
                    dontCreateFootstep: true
                })
            }
        } else if (method === EDITING_METHODS.CHANGE_SHAPE_TYPE) {
            const fullState = this.captureFullState()
            const changingShapeKey = Object.keys(fullState?.objects).find(key => key !== 'textbox')
            const textboxKey = Object.keys(fullState?.objects).find(key => key === 'textbox')
            
            if (changingShapeKey && textboxKey) {
                const changingShape = fullState.objects[changingShapeKey];
                const textbox = fullState.objects[textboxKey];
                updateState({
                    changeShapeTypeTo: changingShape.type,
                    oldShapeType: this.oldShapeType
                }) 
                
                updateState(textbox, {
                    key: KEY_OBJECTS, value: VAL_TEXTBOX
                })
                updateState(changingShape, {
                    key: KEY_OBJECTS, value: changingShape.type
                })
            }
        } else if(method === EDITING_METHODS.CHANGE_SHAPE_LAYER){
            updateState({ layerId: this.layerId, dontCreateFootstep: true });
        }
    })

    return state

}

/**
 * Updates object with given partial state.
 * @param {PartialState} state State that will update the shape.
 */
export function updateWithState(state) {
    const clonedState = deepClone(state)
    const stateObjects = deepClone(clonedState.objects)
    delete clonedState.objects
    delete clonedState.uuid;
    
    // if the shape type is changed, a new instance should be created
    if (clonedState.changeShapeTypeTo) {
        const stateObject = stateObjects[clonedState.changeShapeTypeTo]
        if (stateObject) {
            createFabricInstance(stateObject, (objects) => {
                if (objects.length !== 1) {
                    return
                }
                const objInstance = objects[0]
                this.insertAt(objInstance, 0, true) // replace first shape with new shape
            })
        }
    }
    
    if (this.type === 'group' && !clonedState.changeShapeTypeTo) {
        for (const [key, value] of Object.entries(stateObjects)) {
            const object = this.getObjects().find(obj => obj.type === key);
            if (!object) {
                continue
            }
            object.set(value);
        }
    }
    if (Object.keys(clonedState).length > 0) {
        this.set(clonedState);
    }
    if (this.type === 'curvedLine') {
        if (clonedState.hasOwnProperty('points')) {
            this._setPositionDimensions({})
        }

        if (this.canvas) {
            // check polygons
            for (const side of LINE_POLYGON_POINTS) {
                const polygonName = `${side}Polygon`;
                if (!clonedState.hasOwnProperty(polygonName)) {
                    continue
                }

                this[polygonName] = clonedState[polygonName]

                // this check means, polygon is updated with state and it is string.
                if (typeof this[polygonName] !== 'string') {
                    continue
                }
                
                // find polygon in canvas to access it in a better way.
                this[polygonName] = this.canvas.getObjects().find(obj => obj.uuid === clonedState[polygonName])
                
                if (side === 'left') {
                    this.moveTailToLeftPolygon()
                } else {
                    this.moveHeadToRightPolygon()
                }
            }
        }
        
    }
    
    // after update, if object has lines, move them.
    if (this.lines && Array.isArray(this.lines) && this.canvas) {
        moveObjectLines(this.canvas, this)
    }
    
    // handle frame scenarios
    if (this.attachedFrameId) {
        if (
            // if attached frame is changed
            (this.wiredFrame && this.wiredFrame?.uuid !== this.attachedFrameId) ||
            // if newly attached
            !this.wiredFrame
        ) {
            const frame = this.canvas.getObjects().find(obj => obj.type === 'frame' && obj.uuid === this.attachedFrameId);
            if (frame) {
                this.wiredFrame = frame;
            }
        }
    }
    
    // handle detached
    if (!this.attachedFrameId && this.wiredFrame) {
        this.wiredFrame = null;
    }

    this.setCoords()
}

/**
 * Calls customToObject and fixes some part of it.
 * @param {object|null} options Same options with customToObject method.
 * @returns {object} State of the object.
 */
export function captureFullState(options = {}) {
    const state = customToObject(this, options)

    if (this.type === 'group') {
        
        // instead of array, we need to store objects in object and type of the object must be key
        state.objects = state.objects.reduce((accum, curr) => {
            accum[curr.type] = curr
            return accum
        }, {})
        
        const textboxInState = state.objects['textbox']
        const textboxInsideObject = this.getObjects().find(o => o.type === 'textbox');
        if (textboxInState && textboxInsideObject) {
            textboxInState.styles = deepClone(textboxInsideObject.styles)
        }
    }
    if (this.type === 'table') {
        state.cells = this.cloneCells()
    }
    
    return state
}

/**
 * Updates the object with given full representation of json object.
 * @param {object} state Full JSON data created by captureFullState.
 */
export function updateWithFullState(state) {
    const objects = deepClone(state.objects)
    delete state.uuid;
    delete state.type;
    delete state.objects
    if (Object.keys(state).length > 0) {
        this.set(state);
    }

    if (this.type === 'group') {
        for (const obj of objects) {
            const thisObj = this.getObjects().find(thisObject => thisObject.type === obj.type)
            thisObj.set(obj)
        }
    }

    if (this.lines && Array.isArray(this.lines) && this.canvas) {
        for (const attachedLineUuid of this.lines) {
            const attachedLine = this.canvas.getObjects().find(e => e.uuid === attachedLineUuid);
            if (!attachedLine) {
                continue
            }
            if (attachedLine?.leftPolygon?.uuid === this?.uuid) {
                attachedLine.leftPolygon = this;
                attachedLine.moveTailToLeftPolygon();
            }
            if (attachedLine?.rightPolygon?.uuid === this?.uuid) {
                attachedLine.rightPolygon = this;
                attachedLine.moveHeadToRightPolygon();
            }
        }
    }
}


function mergeStates(currentState, initialState, initialFullState, isRecursiveKeyChecking = false) {
    const fresh = {}
    for (const key in currentState) {
        if (key === 'uuid') {
            fresh.uuid = currentState.uuid
            continue
        }
        
        if (!isRecursiveKeyChecking && key === 'objects') {
            if (!fresh.objects) {
                fresh.objects = {}
            }
            if (!initialState.objects) {
                initialState.objects = {}
            }
            
            const types = Object.keys(currentState.objects)
            for (const type of types) {
                fresh.objects[type] = mergeStates(currentState.objects[type], initialState.objects[type], initialFullState.objects[type], true)
            }
        }
        
        if (!initialState || !(key in initialState)) {
            fresh[key] = initialFullState[key]
        } else {
            fresh[key] = initialState[key]
        }
    }

    return fresh;
}

export function getInitialStateFromFullState(data) {
    const { currentState, initialState, initialFullState } = data;
    
    return mergeStates(currentState, initialState, initialFullState)
}