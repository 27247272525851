import { fabric } from 'fabric';
import { GUIDE_LINE_ALIGN_THRESHOLD, GUIDE_LINE_COLOR, GUIDE_LINE_DASH, GUIDE_LINE_OFFSET, GUIDE_LINE_WIDTH } from '../Constant';

/**
 * @param coords
 * @param ctx
 * @param canvas
 */
export function drawVerticalLine(coords, ctx, canvas) {
    drawLine(
        {
            x1: coords.x + 0.5,
            y1: coords.y1 > coords.y2 ? coords.y2 : coords.y1,
            x2: coords.x + 0.5,
            y2: coords.y2 > coords.y1 ? coords.y2 : coords.y1,
        },
        ctx,
        canvas
    );
}

/**
 * @param coords
 * @param ctx
 * @param canvas
 */
export function drawHorizontalLine(coords, ctx, canvas) {
    drawLine(
        {
            x1: coords.x1 > coords.x2 ? coords.x2 : coords.x1,
            y1: coords.y + 0.5,
            x2: coords.x2 > coords.x1 ? coords.x2 : coords.x1,
            y2: coords.y + 0.5
        },
        ctx,
        canvas
    );
}

/**
 * @param coords
 * @param ctx
 * @param canvas
 */
function drawLine(coords, ctx, canvas) {
    ctx.save();
    ctx.lineWidth = GUIDE_LINE_WIDTH;
    ctx.strokeStyle = GUIDE_LINE_COLOR;
    ctx.setLineDash(GUIDE_LINE_DASH);
    ctx.beginPath();
    const point1 = fabric.util.transformPoint(
        { x: coords.x1, y: coords.y1 },
        canvas.viewportTransform
    );
    const point2 = fabric.util.transformPoint(
        { x: coords.x2, y: coords.y2 },
        canvas.viewportTransform
    );
    ctx.moveTo(point1.x, point1.y);
    ctx.lineTo(point2.x, point2.y);
    ctx.stroke();
    ctx.restore();
}

/**
 * Clears the selection context which is used for drawing alignment lines.
 * @param {fabric.Canvas} canvas 
 */
export function clearAlignmentLines(canvas) {
    try {
        const context = canvas.getSelectionContext();
        context.clearRect(0, 0, canvas.width, canvas.height);
    } catch(error) {
        console.error('error while clearing lines', error);
    }
}

/**
 * @param {fabric.Object} object 
 * @deprecated Use getBoundingCoordinates instead.
 */
export const calculateObjectCoordinateKeysWithAngle = (object) => {
    let topLeft = 'tl',
        topRight = 'tr',
        horizontalTop = 'tl',
        horizontalBottom = 'bl';
    if (object.angle === 0) {
        topLeft = 'tl';
        topRight = 'tr';
        horizontalBottom = 'bl';
        horizontalTop = 'tl';
    }
    if (object.angle > 0 && object.angle <= 90) {
        topLeft = 'bl';
        topRight = 'tr';
        horizontalTop = 'tl';
        horizontalBottom = 'br';
    } else if (object.angle > 90 && object.angle <= 180) {
        topLeft = 'br';
        topRight = 'tl';
        horizontalTop = 'bl';
        horizontalBottom = 'tr';
    } else if (object.angle > 180 && object.angle <= 270) {
        topLeft = 'tr';
        topRight = 'bl';
        horizontalTop = 'br';
        horizontalBottom = 'tl';
    } else if (object.angle > 270 && object.angle <= 360) {
        topLeft = 'tl';
        topRight = 'br';
        horizontalTop = 'tr';
        horizontalBottom = 'bl';
    }

    return {
        topLeft,
        topRight,
        horizontalTop,
        horizontalBottom,
    };
};

/**
 * Checks if the objects are in the range.
 * @param {number} value1 - The value of the first object.
 * @param {number} value2 - The value of the second object.
 * @param {number} zoom - Current zoom level.
 * @returns 
 */
export const isInRange = (value1, value2, zoom = 1) => {
    try {
        const range = Math.abs(value1 - value2)
        return {
            isInRange: range < (GUIDE_LINE_ALIGN_THRESHOLD / zoom),
            range 
        } 
    } catch (err) {
        console.error('error while checking range', err);
    }
    return {
        isInRange: false,
        range: 999
    }
}


/**
 * Returns distance between two objects.
 * @param {object} boundingRect1 - Bounding coordinates of the first object -- from getBoundingCoordinates.
 * @param {object} boundingRect2 - Bounding coordinates of the second object -- from getBoundingCoordinates.
 * @returns 
 */
export const getProximityDistance = (boundingRect1, boundingRect2) => {
    // Destructure the coordinates
    const { tl: {x: Ax1, y: Ay1}, width: width1, height: height1 } = boundingRect1;
    const { tl: {x: Bx1, y: By1}, width: width2, height: height2 } = boundingRect2;
  
    // Calculate the bottom-right coordinates
    const Ax2 = Ax1 + width1;
    const Ay2 = Ay1 + height1;
    const Bx2 = Bx1 + width2;
    const By2 = By1 + height2;
  
    // Calculate the horizontal and vertical distances
    const horizontalDistance = Math.max(0, Math.max(Bx1 - Ax2, Ax1 - Bx2));
    const verticalDistance = Math.max(0, Math.max(By1 - Ay2, Ay1 - By2));
  
    return {
        horizontal: horizontalDistance,
        vertical: verticalDistance,
    };
};


export const findLeastProximityForLine = (lines, key = 'vertical') => {
    let leastLineProximity = {
        proximity: null,
        line: null,
    };
    for (const line of lines) {
        if (
            leastLineProximity.proximity === null ||
      line.proximity[key] < leastLineProximity.proximity
        ) {
            leastLineProximity.proximity = line.proximity[key];
            leastLineProximity.line = line;
        }
    }
    return lines.find((line) => line === leastLineProximity.line);
};

export const sortReduce = (lines) => {
    return lines.reduce((acc, line) => {
        if (!acc.length) {
            acc.push(line);
        }
        const lastLine = acc[acc.length - 1];
        if (line === lastLine) return acc;
        if (Math.abs(Math.round(lastLine.applyingPos) - Math.round(line.applyingPos)) === 0) {
            acc.push(line);
        } else {
            const ranges = acc.map((l) => l.range);
            // check if line range is smaller than ranges
            if (line.range < Math.min(...ranges)) {
                acc = [line];
            }
        }
        return acc;
    }, []);
};

export const sortVerticalLinesByProximity = (lines) => {
    const sortedLines = sortReduce(lines);
  
    const leftLine = findLeastProximityForLine(
        sortedLines.filter((line) => line.verticalLinePosition === 'left')
    );
    const rightLine = findLeastProximityForLine(
        sortedLines.filter((line) => line.verticalLinePosition === 'right')
    );
    const centerLine = findLeastProximityForLine(
        sortedLines.filter((line) => line.verticalLinePosition === 'center')
    );
    const categorizedLines = [];
  
    if (leftLine) {
        categorizedLines.push(leftLine);
    }
    if (rightLine) {
        categorizedLines.push(rightLine);
    }
    if (centerLine) {
        categorizedLines.push(centerLine);
    }
    return categorizedLines;
};

export const sortHorizontalLinesByProximity = (lines) => {
    const sortedLines = sortReduce(lines);

    const topLine = findLeastProximityForLine(
        sortedLines.filter((line) => line.horizontalLinePosition === 'top'),
        'horizontal'
    );
    const bottomLine = findLeastProximityForLine(
        sortedLines.filter((line) => line.horizontalLinePosition === 'bottom'),
        'horizontal'
    );
    const centerLine = findLeastProximityForLine(
        sortedLines.filter((line) => line.horizontalLinePosition === 'center'),
        'horizontal'
    );

    const categorizedLines = [];

    if (topLine) {
        categorizedLines.push(topLine);
    }
    if (bottomLine) {
        categorizedLines.push(bottomLine);
    }
    if (centerLine) {
        categorizedLines.push(centerLine);
    }
    return categorizedLines;
};

/**
 * Returns the bounding coordinates of the object as same as the object that returns from calcCoords .
 * @param {fabric.Object} object 
 * @param padding
 * @returns 
 */
export const getBoundingCoordinates = (object, padding = 0) => {
    const boundingRect = object.type === 'activeSelection' ? object.getCompleteBoundingRect() : object.getBoundingRect(true);
    if (object?.type === 'curvedLine') {
        boundingRect.width = object.width;
        boundingRect.height = object.height;
    }

    return {
        tl: {
            x: boundingRect.left - padding,
            y: boundingRect.top - padding
        },
        tr: {
            x: boundingRect.left + boundingRect.width + padding,
            y: boundingRect.top - padding
        },
        bl: {
            x: boundingRect.left - padding,
            y: boundingRect.top + boundingRect.height + padding
        },
        br: {
            x: boundingRect.left + boundingRect.width + padding,
            y: boundingRect.top + boundingRect.height + padding
        },
        center: {
            x: boundingRect.left + (boundingRect.width / 2),
            y: boundingRect.top + (boundingRect.height / 2),
        },
        width: boundingRect.width,
        height: boundingRect.height
    }
}


/**
 * Returns the coordinates for vertical guide line.
 * @param {object} activeObjectCoords - The object coordinates from getBoundingCoordinates.
 * @param {*} nearObjectCoords - The near object coordinates from getBoundingCoordinates.
 * @returns 
 */
export function generateVerticalCoordinates(activeObjectCoords, nearObjectCoords) {
    return {
        y1:
      nearObjectCoords.tl.y < activeObjectCoords.tl.y
          ? nearObjectCoords.tl.y - GUIDE_LINE_OFFSET
          : nearObjectCoords.bl.y + GUIDE_LINE_OFFSET,
        y2:
      activeObjectCoords.tl.y > nearObjectCoords.tl.y
          ? activeObjectCoords.bl.y + GUIDE_LINE_OFFSET
          : activeObjectCoords.tl.y - GUIDE_LINE_OFFSET,
    };
}

/**
 * Returns the coordinates for horizontal guide line.
 * @param {object} activeObjectCoords - The object coordinates from getBoundingCoordinates.
 * @param {*} nearObjectCoords - The near object coordinates from getBoundingCoordinates.
 * @returns 
 */
export function generateHorizontalCoordinates(activeObjectCoords, nearObjectCoords) {
    return {
        x1:
      nearObjectCoords.tl.x < activeObjectCoords.tl.x
          ? nearObjectCoords.tl.x - GUIDE_LINE_OFFSET
          : nearObjectCoords.tr.x + GUIDE_LINE_OFFSET,
        x2:
      activeObjectCoords.tl.x > nearObjectCoords.tl.x
          ? activeObjectCoords.tr.x + GUIDE_LINE_OFFSET
          : activeObjectCoords.tl.x - GUIDE_LINE_OFFSET,
    };
}

const originXPoints = {
    left: {
        left: 0,
        center: 0.5,
        right: 1,
    },
    center: {
        left: -0.5,
        center: 0,
        right: 0.5
    },
    right: {
        left: 1,
        center: 0.5,
        right: 0
    }
}

const originYPoints = {
    top: {
        top: 0,
        center: 0.5,
        bottom: 1,
    },
    bottom: {
        top: 1,
        center: 0.5,
        bottom: 0,
    },
    center: {
        top: -0.5,
        center: 0,
        bottom: 0.5
    }
}

/**
 * Returns the width difference between the origin and the aligning
 * in order to align the object horizontally according to its originX property.
 * @param {fabric.Object} object 
 * @param {number} width - Width from getBoundingCoordinates.
 * @param {'left'|'center'|'right'} aligning - Where the object is aligning.
 * @returns 
 */
export function getWidthDifferenceBetweenOrigin(object, width, aligning) {
    return width * originXPoints[aligning][object.originX];
}

/**
 * Returns the height difference between the origin and the aligning
 * in order to align the object vertically according to its originX property.
 * @param {fabric.Object} object 
 * @param {number} width - Width from getBoundingCoordinates.
 * @param height
 * @param {'top'|'center'|'bottom'} aligning - Where the object is aligning.
 * @returns 
 */
export function getHeightDifferenceBetweenOrigin(object, height, aligning) {
    return height * originYPoints[aligning][object.originY];
}