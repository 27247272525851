import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import ZoomAdjuster from './components/ZoomAdjuster';
import FitToScreen from './components/FitToScreen';
import MiniMapWrapper from '../miniMap/MiniMapWrapper';
import ActivityLogButton from './components/ActivityLogButton';
import PageSelector from './components/PageSelector';

import './bottomBars.scss';
import {useSelector} from 'react-redux';
import {MODAL_WINDOWS} from '../../helpers/Constant';

const BottomBars = ({
    canvas,
    canvasRef,
    isLeftDrawerOpen,
    userAccessRef,
    onActivityLogButtonClicked,
    handleToggleCommentDrawer
}) => {
    const activeWindowState = useSelector(state => state?.modal?.activeWindow);
    return (
        <>
            <div className={clsx('leftBottomBlock', { leftDrawerActive: isLeftDrawerOpen})}>
                <div className="wrapper">
                    <ActivityLogButton
                        isLeftDrawerOpen={isLeftDrawerOpen}
                        onActivityLogButtonClicked={onActivityLogButtonClicked}
                        userAccessRef={userAccessRef}
                        handleToggleCommentDrawer={handleToggleCommentDrawer}
                    />
                    <ZoomAdjuster canvas={canvas} canvasRef={canvasRef} /> 
                </div>
         
            </div>

            <div className={clsx('rightBottomBlock', {
                '--commentDrawerExpanded' : activeWindowState === MODAL_WINDOWS.COMMENT_DRAWER,
                '--rightPanelExpanded' : activeWindowState === MODAL_WINDOWS.RIGHT_DRAWER
            })}>
                <div className="wrapper">
                    <FitToScreen canvas={canvas} />
                    <PageSelector handleToggleCommentDrawer={handleToggleCommentDrawer} canvas={canvas} />
                    <MiniMapWrapper handleToggleCommentDrawer={handleToggleCommentDrawer} canvas={canvas} />
           
                </div>
            </div>
        </>
    )
}

BottomBars.propTypes = {
    canvas: PropTypes.object,
    canvasRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLCanvasElement)
    }),
    isLeftDrawerOpen: PropTypes.bool,
    userAccessRef: PropTypes.shape({
        current: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
    }),
    onActivityLogButtonClicked: PropTypes.func,
    handleToggleCommentDrawer: PropTypes.func.isRequired,
}

export default BottomBars;
