import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { renderComment } from '../helpers/comments/RenderAllComments';
import {
    createFabricInstance,
    cloneFabricObjectHelper,
    isTargetImage,
    makeObjectUnselectable,
    isTargetLocked,
    isObjectInsideOfObject, 
    truncateLongPositions
} from '../helpers/FabricMethods';
import { EMITTER_TYPES, USER_ROLES } from '../helpers/Constant';
import onFrameDrawn, { setFrameOptions } from '../helpers/frame/OnFrameDrawn';
import { getOriginalStateOfGroup, setOriginalStateForGroup } from '../helpers/ObjectUtils';
import { updateAllPropertiesOfTextboxObject } from '../helpers/Textarea';
import {attachToFrame} from '../helpers/frame/FrameMethods';
import eventEmitter from '../helpers/EventEmitter';
import { onTableDrawn } from '../helpers/table/TableMethods';
import {changeZIndex} from '../helpers/zIndex/ManageZIndex';
import {attachLinesInUuidList, attachPolygonsWithCanvasMap} from '../helpers/lines/LineMethods';
import { isPublicLink, isUserHasAccessToBoardWhileNotMember } from '../helpers/UserPermissions';
import { showUnauthorizedToastMessage } from '../helpers/CommonFunctions';

export const onShapeDrawnEvent = async (data, canvas, options = {}, history = [], firstTime) => {
    const linePolygonUpdater = options?.linePolygonUpdater;
    let user = data?.user;
    if (!canvas.getObjects().filter(e => e.uuid === data.uuid).length) {
        return new Promise((resolve, reject) => {
            // get all user ids
            const users = options?.users?.map(user => user.info.userId);

            if (data.shapeType === 'frame') {
                // for frame titles we need to set fixedWidth property in order to 
                // avoid misplacing of the frame because of the long text width
                try {
                    const frameObjects = data?.properties?.objects; 
                    const frameTitle = frameObjects?.find(obj => obj?.type === 'text');
                    const frameRect = frameObjects?.find(obj => obj?.type === 'rect');
                    if (frameTitle && frameRect) {
                        frameTitle.shapeType = 'frameText';
                        frameTitle.fixedWidth = frameRect.width;
                    }
                } catch (err) {
                    console.error('Error happened', err)
                }
            }
            createFabricInstance({...data.properties, user }, function (objects) {
                if (!objects.length) {
                    reject('could not create')
                }
                objects.forEach(function (o) {
                    truncateLongPositions(o)
                    if (o.type === 'curvedLine') {
                        // save polygon updater on line in case line relative points are updated
                        o.polygonUpdater = linePolygonUpdater;

                        attachPolygonsWithCanvasMap(o, canvas);
                        linePolygonUpdater?.add(data)
                    } else if (o.lines) {
                        attachLinesInUuidList(o, canvas);
                    }
                       
                    o.toObject = cloneFabricObjectHelper(o);

                    // if object is a frame, objectCaching should be disabled
                    if (o.type === 'frame') {
                        onFrameDrawn(o, canvas);
                    } else if (o.type === 'table') {
                        onTableDrawn(o);
                    }

                    if (o.type === 'textbox') {
                        o.objectCaching = false;
                    }

                    if (options) {
                        if (options.modifiedBy) {
                            o.modifiedBy = options.modifiedBy;
                        }
                        if (options.createdBy) {
                            o.createdBy = options.createdBy;
                        }
                        if (options.isRestored) {
                            o.isRestored = options.isRestored;
                        }
                        if (options.hideFromActivityLog) {
                            o.hideFromActivityLog = true;
                        }
                        if (data.owner) {
                            o.ownerName = data.owner.name;
                        }
                        if (data.isCreatedViaHistory) {
                            o.isCreatedViaHistory = data.isCreatedViaHistory;
                        }
                        if (options.hasOwnProperty('visible')){
                            o.visible = options.visible;
                        }

                        // Frame object shouldn't be selected if frame is locked.
                        if (
                            options.triggeredBy === 'loadShapes' &&
                            o.attachedFrameId &&
                            isTargetLocked(o) &&
                            isTargetLocked(data.properties)
                        ) {
                            o.selectable = false;
                        }
                    }

                    if (data?.hideLog) {
                        o.hideFromActivityLog = true;
                    }
                    if (data.actionTaken === 'duplicated') {
                        o.isDuplicated = true;
                    }
                    // if created object is image, search for mock image and remove it
                    if (isTargetImage(o)) {
                        // get mock image for this image with mockFor property
                        const mockObj = canvas.getObjects().find(canvasObject =>
                            canvasObject?.type === 'mockImage' && canvasObject?.mockFor === o?.uuid
                        );
                        // if mock image has attached comments, attach them to the loaded image
                        if (mockObj && mockObj.attachedComments) {
                            o.attachedComments = mockObj.attachedComments;
                            o.attachedCommentDifs = mockObj.attachedCommentDifs;
                            for (const attachedComment of o.attachedComments) {
                                attachedComment.attachedShape = o;
                            }
                        }
                        
                        mockObj && canvas.remove(mockObj);
                    }

                    // if select mode is comment or pan, make object unselectable
                    if (canvas.selectMode === 'comment' || canvas.selectMode === 'pan') {
                        makeObjectUnselectable(o);
                    }

                    if (users && users.length && Array.isArray(users) && options.ownerId) {
                        // if createdBy doesn't match with the current users, assign it to the owner
                        if (!users.includes(o.createdBy)) {
                            o.createdBy = options.ownerId;
                        }
                        // if modifiedBy doesn't match with the current users, assign it to the owner
                        if (!users.includes(o.modifiedBy)) {
                            o.modifiedBy = options.ownerId;
                        }
                    }
                    
                    //add zIndex if it is undefined?? but how
                    if(options.isRestored && !o.zIndex){
                        //add z-index
                        changeZIndex(canvas, 'front' ,[o], history);
                    }
                    
                    canvas.add(o, {history,firstTime: firstTime === true ? true : false});

                    if (o.attachedFrameId) {
                        try {
                            const frame = canvas.getObjects().find(obj => obj.uuid === o.attachedFrameId)
                            let isObjectInsideOfTheFrame = isObjectInsideOfObject(frame, o, { manualCheck: true });
                            if (isObjectInsideOfTheFrame) {
                                attachToFrame(o, frame);
                            } else {
                                o.attachedFrameId = null;
                            }
                        } catch (err) {
                            console.error('Error while attaching to the frame', err)
                        }
                    }

                    // To calculate shortened text
                    if (o.type === 'group') {
                        const _textboxObj = o.getObjects().find((obj) => obj.type === 'textbox');
                        if (options.isRestored || _textboxObj?.text?.length > 0) {
                            _textboxObj.initDimensions();
                        }
                    }

                    if (options?.collabLockedProp?.userId) {
                        eventEmitter.fire(EMITTER_TYPES.COLLAB_LOCK, {object: o, userId: options.collabLockedProp.userId})
                    }

                    resolve(o);
                });
            });
        });
    } else {
        return Promise.reject('Object already exists');
    }
};

/**
 * Creates an object instance with the given properties and add its properties to the same object in canvas.
 * @param {object} data 
 * @param {object} data.properties - The properties of the object.
 * @param {fabric.Canvas} canvas 
 * @returns 
 */
const modifyObjectWithPromise = async (data, canvas) => {
    return new Promise((resolve) => {
        createFabricInstance(data.properties, function (objects) {
            objects.forEach(function (o) {
                o.modifiedBy = data.modifiedBy;
                o.toObject = cloneFabricObjectHelper(o);

                const object = canvas.getObjects().find(e => e.uuid === data.uuid);
                object.onShapeChanged();

                if (o.type === 'curvedLine') {
                    attachPolygonsWithCanvasMap(o, canvas)
                }

                // Updates properties
                if (o.type === 'group') {
                    const originalGroupState = getOriginalStateOfGroup(o);
                    object.set(o).setCoords().addWithUpdate()
                    setOriginalStateForGroup(object, originalGroupState);
                } else if (o.type === 'frame') {
                    object.set(o).setCoords();
                } else if (o.type === 'textbox') {
                    const newObject = updateAllPropertiesOfTextboxObject(o);
                    object.set(newObject).setCoords();
                } else {
                    object.set(o).setCoords();
                }
                if (data.properties.type === 'frame') {
                    setFrameOptions(object);
                }

                // if select mode is comment or pan, make object unselectable
                if (canvas.selectMode === 'comment' || canvas.selectMode === 'pan') {
                    makeObjectUnselectable(object);
                }

                canvas.renderAll(); // Redraws canvas
                resolve(object);
            });
        }); 
    });
}

export const onShapeModifiedEvent = async (data, canvas) => {
    try {
        let result = canvas.getObjects().filter(e => e.uuid === data.uuid);
        const updateFrameOptions = {
            shouldUpdate: false,
            frameUuid: ''
        };
        if (result.length && result[0].attachedFrameId) {
            updateFrameOptions.shouldUpdate = true;
            updateFrameOptions.frameUuid = result[0].attachedFrameId;
        }
        if (result.length) {
            const canvasObjects = canvas.getObjects();
            const handleLineUpdates = (object) => {
                for (const lineUuid of object.lines) {
                    const line = canvasObjects.find(obj => obj?.uuid === lineUuid);

                    if (!line) {
                        continue
                    }

                    if (line.leftPolygon?.uuid === object.uuid) {
                        line.leftPolygon = object;
                        line.moveTailToLeftPolygon()
                    } else if (line.rightPolygon?.uuid === object.uuid) {
                        line.rightPolygon = object;
                        line.moveHeadToRightPolygon()
                    }
                } 
            }
            // if the object is image, no need to create a new object
            // since images are not in the group
            if (data?.properties?.type === 'optimizedImage') {
                const object = canvas.getObjects().find(e => e.uuid === data.uuid);
                object.onShapeChanged();
                object.set(data.properties).setCoords();
                canvas.renderAll();
                if (object.lines && object.lines.length) {
                    handleLineUpdates(object) 
                }
            } else {
                const o = await modifyObjectWithPromise(data, canvas);
                if (o.type !== 'curvedLine' && o.type !== 'table' && o.type !== 'frame') {
                    if (o.lines && o.lines.length) {
                        handleLineUpdates(o) 
                    } 
                }
            }
            canvas.fire('modified-with-event', { target: result[0] });
            canvas.fire('modified-with-event-socket', { target: result[0] });
            // for frame images
            if (updateFrameOptions.shouldUpdate) {
                canvas.fire('update-frame', updateFrameOptions.frameUuid);
            }
    
        } else {
            // if object is not present in canvas, then add it
            await onShapeDrawnEvent(data, canvas, { isRestored: true });
        }
    } catch (error) {
        console.error('error while modifying object', error);
    }
};

export const onShapeRemovedEvent = (data, canvas) => {
    let result = canvas.getObjects().filter(e => e.uuid === data.uuid);
    if (result.length) {
        result[0].modifiedBy = data.modifiedBy;
        result[0].deletedBy = data.modifiedBy;
        canvas.remove(result[0]);
        eventEmitter.fire(EMITTER_TYPES.UPDATE_LAYERS_SHAPES_COUNT_AND_VISIBILITY, {removedObjects: result, action: 'countUpdate'});
    }
};


export const onAddComment = ({
    data,
    pageReferencesRef,
    setSelectedCommentIcon, 
    userId,
    setAddedComment, 
    shouldHideComments,
    shouldHideResolvedComments
}) => {
    eventEmitter.fire(EMITTER_TYPES.SET_UNREAD_COMMENT_EXISTS);
    if (!data.parentId) {
        if (!pageReferencesRef.current.isPageActive(data.pageId)) return;
        const pageCanvas = pageReferencesRef.current.getPageCanvas(data.pageId);

        renderComment({
            canvas: pageCanvas, 
            comment: {
                ...data,
                unreadCommentCount: 1
            },
            setSelectedCommentIcon, 
            userId, 
            shouldHideComments, 
            shouldHideResolvedComments, 
        });
        setAddedComment({...data, replies: []});
    } else { // if added comment is a reply
        let updatedData = {...data}
        if(!data.colorCode) updatedData = {...updatedData, colorCode: data.updatedThread[0].colorCode}
        
        setAddedComment({...updatedData, isReply: true});
    }
}

/**
 * This function used for when an user invited to the board, memberlist updated on all users screen. 
 * @param {{id: number, name: string, email: string, user_type: string, commentUsername: string, commentEmail: string, socketEmitter: number, permission: 'view'|'comment'|'edit' }} data 
 * @param {Function} onMemberListUpdated 
 * @param userId
 */
export const onUserInvited = (data, onMemberListUpdated, userId) => {
    console.log('[USER-INVITED]', data);

    if (
        userId !== data.socketEmitter ||
        (userId === data.socketEmitter && data.userFromBuilder === true)
    ) {
        // If there is publicAccess but its opened from public link; we need to remove public access.
        const newIsPublicAccess = (userId === data?.id && !isPublicLink()) ? false : null;

        onMemberListUpdated({
            ...data,
            action: 'INVITE',
            userId: data.id,
            userPermission: data.permission,
            newIsPublicAccess
        })
    }
}

/**
 * @param {{userId: number, permission: 'view'|'comment'|'edit', socketEmitter: number}} data 
 * @param {Function} onMemberListUpdated 
 * @param {number} userId 
 * @param setSelectedComment
 * @param setSelectedCommentIcon
 * @param {Function} handleChangeSelectMode 
 * @param {object} canvas 
 * @param {Function} loadCommentsAndUsers 
 * @param {Function} forceUpdate 
 * @param {*} userAccessRef 
 * @returns 
 */
export const onUserPermissionUpdated = (
    data,
    onMemberListUpdated,
    userId,
    forceUpdate,
    setSelectedComment,
    setSelectedCommentIcon,
    handleChangeSelectMode
) => {
    if (!data.permission) {
        return;
    }
    const permission = USER_ROLES[data.permission].id;

    // First update all users
    if (userId !== data.socketEmitter) {
        onMemberListUpdated({
            action: 'UPDATE',
            userId: data.userId,
            userPermission: data.permission,
        });
    }

    // Secondly, if the authenticated user is the the user whose the permission is changed, change the permission and forceUpdate the board
    if (userId === data?.userId) {
        handleChangeSelectMode('pan');

        // Remove comments for view access if exists
        if (permission === USER_ROLES.view.id) {     
            setSelectedComment({});
            setSelectedCommentIcon(null);
        }

        forceUpdate(uuidv4());
    }
};

/**
 * @param {{userId: number, permission: 'view'|'comment'|'edit', socketEmitter: number}} data
 * @param {Function} onMemberListUpdated
 * @param {number} userId
 * @param setSelectedComment
 * @param setSelectedCommentIcon
 * @param {Function} handleChangeSelectMode
 * @param {object} canvas
 * @param {Function} loadCommentsAndUsers
 * @param {Function} forceUpdate
 * @param {*} userAccessRef
 * @param isBuilderAiUser
 * @returns
 */
export const onWBPermissionUpdated = (
    data,
    onMemberListUpdated,
    userId,
    forceUpdate,
    setSelectedComment,
    setSelectedCommentIcon,
    handleChangeSelectMode,
    isBuilderAiUser
) => {
    const permission =
        isBuilderAiUser !== true
            ? USER_ROLES[data.users]?.id
            : USER_ROLES[data.employees]?.id;
    if (!permission) return;

    onMemberListUpdated({
        action: 'UPDATE',
        userId: userId,
        userPermission: permission,
        isReceivedFromWB: true
    });

    // Secondly, if the authenticated user is the the user whose the permission is changed, change the permission and forceUpdate the board
    handleChangeSelectMode('pan');

    // Remove comments for view access if exists
    if (permission === USER_ROLES.view.id) {
        setSelectedComment({});
        setSelectedCommentIcon(null);
    }

    forceUpdate(uuidv4());
}

/**
 * @param {{userId:number, message:string, socketEmitter:number}} data 
 * @param {Function} navigate 
 * @param {string} whiteBoardSlugId 
 * @param {Function} forceUpdate 
 * @param {Function} onMemberListUpdated 
 * @param isPublicId
 * @param {number} userId 
 * @returns Undefined.
 */
export const onUserRemoved = (
    data,
    navigate,
    whiteBoardSlugId,
    forceUpdate,
    onMemberListUpdated,
    userId,
    setShowOverlay
) => {
    if (!data?.userId) {
        return;
    }

    let newIsPublicAccess = null;

    // If the authenticated user is the user whose the permission is changed
    if (data.userId === userId) {
        /**
         * Show a toast notifying the user to have lost private access
         * to the board only when the user is in public link.
         */
        if (!isUserHasAccessToBoardWhileNotMember({})) {
            if (data.socketEmitter === '360_request') {
                showUnauthorizedToastMessage(data.message);
                setShowOverlay(true);
            } else {
                navigate(`/request-access/${whiteBoardSlugId}`);
            }
        } else {
            newIsPublicAccess = true;
        }

        // force the board to be updated.
        forceUpdate(uuidv4());
    }

    if (userId !== data.socketEmitter) {
        onMemberListUpdated({
            action: 'REMOVE',
            userId: data.userId,
            userPermission: USER_ROLES.notAllowed.id,
            newIsPublicAccess
        });
    }
};

/**
 * @param {{oldOwnerId:number, newOwnerId:number, socketEmitter: number}} data 
 * @param {Function} forceUpdate
 * @param {Function} setWbOwnerId 
 * @param {Function} onOwnerIdChanged
 * @param {number} userId
 */
export const onWbOwnershipTransferred = (
    data,
    forceUpdate,
    setWbOwnerId,
    onOwnerIdChanged
) => {
    console.log('[ON-WB-OWNERSHIP-TRANSFFERRED]', data);

    setWbOwnerId(data.newOwnerId);
    onOwnerIdChanged(data.newOwnerId);
    forceUpdate(uuidv4());
}

export class AddedShapeCounter {
    constructor(shapeCount) {
        this.totalShapeCount = shapeCount
        this.addedObjectCount = 0;
    }
    add() {
        this.addedObjectCount;
    }
}
