import PropTypes from 'prop-types';
import { formatTime } from '../../../../helpers/FormatTime';
import transformShapeAndActionStr from '../../../../helpers/activityLog/TransformShapeAndActionStr';

import './ActivityLogItem.scss';
import { centerToObjectWithAnimation, getFabricObject } from '../../../../helpers/FabricMethods';
import { isUserHasAccessToFeature } from '../../../../helpers/CommonFunctions';
import { useRef } from 'react';
import transformItemText from '../../../../helpers/activityLog/TransformItemText';
import { toast } from 'react-toastify';
import getToastIcon from '../../../../helpers/media/GetToastIcon';
import { v4 as uuidv4 } from 'uuid';

let activityShapeHiddenCLicked = 'activity-shape-hidden-clicked';
const ActivityLogItem = ({ logData, canvas, handleRestoreItem, userAccess }) => {
    const spanRef = useRef();
    const [formattedHour, formattedDate] = formatTime(logData?.lastUpdatedAt);

    const handleItemClick = (itemUuid) => {
        if (!itemUuid) {
            return;
        }
        const clickedObj = getFabricObject(canvas, 'uuid', itemUuid);
        if (clickedObj) {
            if(!clickedObj.visible){
                toast.dismiss(activityShapeHiddenCLicked);
                activityShapeHiddenCLicked = toast.info('This element is not visible on board right now', {
                    icon: getToastIcon('info'),
                    autoClose: true,
                    className: 'wb_toast',
                    draggable: false,
                    toastId: `activity-shape-hidden-clicked-${uuidv4()}`,
                });
                return;    
            }
            centerToObjectWithAnimation(canvas, clickedObj, {
                adaptZoomLevel: true,
                selectAfterAnimation: isUserHasAccessToFeature('objectAnimation', userAccess),
                showToolbar: isUserHasAccessToFeature('subToolbar', userAccess)
            });
        }
    }

    // restores deleted object
    const handleRestoreClick = async (e, logItem) => {
        e.stopPropagation();
        try {
            await handleRestoreItem(logItem);
        } catch (error) {
            console.error('error while restoring deleted object', error);
        }
    }

    return (
        <div className='activityLogItem'>
            <div className='activityLogItem__header'>
                <span className='activityLogItem__header--title'>{logData?.user?.name}</span>
                <div className='activityLogItem__header--date'>
                    <span className='hour'>{formattedHour}</span>
                    <span className="circleSeperator" />
                    <span className='date'>{formattedDate}</span>
                </div>
            </div>
            <div className='activityLogItem__content'>
                <ul className='activityLogItem__content--list'>
                    {
                        logData?.logs.map(logItem => (
                            <li 
                                onClick={() => handleItemClick(logItem?.shape?.uuid)}
                                className="activityLogItem__listItem" 
                                key={logItem?.shape?.uuid || logItem?.id}
                                data-testid="activityLogItem__listItem">
                                <div className="activityLogItem__listItem--head">
                                    <span ref={logItem?.actionTaken === 'whiteboardDuplicate' ? spanRef: null} className='activityLogItem__listItem--title'>
                                        {transformShapeAndActionStr(logItem?.itemType, logItem.actionTaken, logItem?.shape?.properties, logItem?.user?.name, logItem?.itemText)}
                                    </span>
                                    <div className="activityLogItem__listItem--headRight">
                                        <div className='activityLogItem__listItem--date'>
                                            <span className='hour'>{formatTime(logItem.createdAt)[0]}</span>
                                        </div>
                                        {
                                            (logItem.actionTaken === 'deleted' && isUserHasAccessToFeature('undo_deleted', userAccess)) ? <button
                                                type="button"
                                                aria-label="Restore deleted"
                                                className="activityLogItem__listItem--restoreBtn"
                                                data-testid="activityLogItem__restoreBtn"
                                                onClick={(e) => handleRestoreClick(e, logItem)}
                                                title="Restore deleted">
                                                <em aria-hidden className="icon-undo" />
                                            </button> : null
                                        }
                                    </div>
                                </div>
                                {
                                    (logItem.itemText && (['table', 'frame'].includes(logItem.itemType) || logItem.actionTaken !== 'deleted')) ? 
                                        <div className="activityLogItem__listItem--itemText" data-testid="activityLogItem__listItem--itemText">
                                            {transformItemText(logItem?.itemType, logItem?.actionTaken, logItem?.itemText, logItem?.user?.name )}
                                        </div> : null
                                }
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

ActivityLogItem.propTypes = {
    logData: PropTypes.shape({
        logs: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            whiteboardId: PropTypes.number,
            userId: PropTypes.number,
            version: PropTypes.number,
            isCurrent: PropTypes.bool,
            actionTaken: PropTypes.oneOf(['created', 'modified', 'deleted', 'duplicated']),
            itemType: PropTypes.string,
            itemId: PropTypes.number,
            itemText: PropTypes.string,
            createdAt: PropTypes.string,
            activityTime: PropTypes.string,
            hideLog: PropTypes.bool,
            shape: PropTypes.object,
            user: PropTypes.shape({
                commentUsername: PropTypes.string,
                id: PropTypes.number,
                name: PropTypes.string
            })
        })),
        lastUpdatedAt: PropTypes.string,
        user: PropTypes.shape({
            commentUsername: PropTypes.string,
            id: PropTypes.number,
            name: PropTypes.string
        })
    }),
    canvas: PropTypes.object,
    handleRestoreItem: PropTypes.func.isRequired,
    userAccess: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
}

export default ActivityLogItem;