import {fabric} from 'fabric';

/**
 * Dispose the canvas.
 * @override
 */
export function dispose() {
    let wrapper = this.wrapperEl;
    this.removeListeners();
    wrapper.removeChild(this.upperCanvasEl);
    wrapper.removeChild(this.lowerCanvasEl);
    this.contextCache = null;
    this.contextTop = null;
    ['upperCanvasEl', 'cacheCanvasEl'].forEach((function(element) {
        fabric.util.cleanUpJsdomNode(this[element]);
        this[element] = undefined;
    }).bind(this));
    if (wrapper.parentNode) {
        wrapper.parentNode.replaceChild(this.lowerCanvasEl, this.wrapperEl);
    }
    delete this.wrapperEl;
    fabric.StaticCanvas.prototype.dispose.call(this);
    this.engine.dispose();
    return this;
}

/**
 * Clears the canvas.
 * @override
 */
export function clear() {
    // this.discardActiveGroup();
    this.fire('canvas:clearing', { action: 'clearing' });
    this.discardActiveObject();
    this.clearContext(this.contextTop);
    // override
    this.dirtyObjects?.clear();
    // override - end
    this.remove.apply(this, this.getObjects());
    this.backgroundImage = null;
    this.overlayImage = null;
    this.backgroundColor = '';
    this.overlayColor = '';
    if (this._hasITextHandlers) {
        this.off('mouse:up', this._mouseUpITextHandler);
        this._iTextInstances = null;
        this._hasITextHandlers = false;
    }
    this.clearContext(this.contextContainer);
    this.fire('canvas:cleared', { action: 'cleared' });
    this.renderOnAddRemove && this.requestRenderAll();
    return this;
}